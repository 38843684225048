import {ApolloClient, from, HttpLink} from "@apollo/client"
import {onError} from "@apollo/client/link/error"
import ApolloLinkTimeout from "apollo-link-timeout"
import {LocalStorageWrapper, persistCache} from "apollo3-cache-persist"

import {Store} from "redux"
import {GRAPHQL_URL} from "src/config"
import {getAuthorizationHeader} from "src/features/auth"
import {ReduxState} from "src/types/redux"
import Bugsnag from "@bugsnag/js"

import {createCache} from "./cache"
import {enqueueSnackbar} from "notistack"

const timeoutLink = new ApolloLinkTimeout(10000) // 10 second timeout

const {cache} = createCache()
persistCache({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
})

const buildApolloClient = () => {
  let store: Store<ReduxState>
  const errorLink = onError(
    ({operation, graphQLErrors, networkError, forward}) => {
      if (graphQLErrors && graphQLErrors.length > 0) {
        Bugsnag.notify(
          `GraphQL error for operation "${operation.operationName}"`,
          (err) => {
            err.severity = "error"
            err.addMetadata("details", {
              error: JSON.stringify(graphQLErrors.map((e) => e.message)),
            })
          },
        )
      }

      if (networkError) {
        // Currently timeouts are common when searching for tickets, but if the user it typing slowly then it may not
        // be the current search term which has timed out, so showing this message could be confusing
        if (networkError.message !== "Timeout exceeded") {
          enqueueSnackbar({
            message:
              "There seems to be an issue with the network, if you notice any issues please check your connection and try again. If this continues please contact Candide support.",
            variant: "warning",
            preventDuplicate: true,
            style: {
              maxWidth: "600px",
            },
          })
        }
        Bugsnag.notify(
          `Network error for operation "${operation.operationName}"`,
          (err) => {
            err.severity = "info"
            err.addMetadata("details", {
              error: networkError.message,
              errorName: networkError.name,
              cause: networkError.cause,
            })
          },
        )
      }

      return forward(operation)
    },
  )

  const client = new ApolloClient({
    cache,
    defaultOptions: {
      watchQuery: {
        nextFetchPolicy: "network-only",
        initialFetchPolicy: "network-only",
        fetchPolicy: "network-only",
      },
      query: {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
      },
      mutate: {
        fetchPolicy: "network-only",
      },
    },
    link: from([
      errorLink,
      timeoutLink,
      new HttpLink({
        uri: GRAPHQL_URL,
        fetch: (uri, options) => {
          const headers: {
            Authorization?: string
            "x-candide-client": string
            "content-type": string
          } = {
            // ...options?.headers, // TODO why this make CORS sad?
            "content-type": "application/json",
            "x-candide-client": "visiting-cms",
          }
          const authState = {
            token:
              store?.getState().auth.token ?? localStorage.getItem("authToken"),
            deviceId:
              store?.getState().auth.deviceId ??
              localStorage.getItem("deviceId"),
          }
          const authHeader = getAuthorizationHeader(
            authState.deviceId,
            authState.token,
          )
          if (authHeader) {
            headers.Authorization = authHeader
          }
          return fetch(uri, {
            ...options,
            headers,
          })
        },
      }),
    ]),
    name: "Candide Admissions",
  })

  return client
}

export {buildApolloClient}
