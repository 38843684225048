import {AUTH_URL} from "../../../../config"

export default async function (email: string): Promise<boolean> {
  const response = await fetch(
    `${AUTH_URL}/auth/reset-password/${encodeURIComponent(email)}`,
    {
      method: "POST",
    },
  ).catch(() => null)

  return response?.status === 200
}
