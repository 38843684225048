import {FormLabel} from "@mui/material"
import Switch from "@mui/material/Switch"
import React from "react"
import styled from "styled-components"

interface Props {
  value: boolean
  onChange: (newValue: boolean) => void
  label?: string
  isTableFilter?: boolean
}

export default class SwitchFilter extends React.PureComponent<Props> {
  public render() {
    const {value, onChange, label, isTableFilter} = this.props

    return (
      <Container>
        {isTableFilter ? null : <FormLabel>{label}</FormLabel>}
        <Switch checked={value} onChange={() => onChange(!value)} />
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
