import CheckboxesFilter from "./CheckboxesFilter"
import DateRangeFilter from "./DateRangeFilter"
import DateTimeFilter from "./DateTimeFilter"
import MultiSelectFilter from "./MultiSelectFilter"
import SelectFilter from "./SelectFilter"
import SwitchFilter from "./SwitchFilter"
import TextFilter from "./TextFilter"

interface SwitchFieldSpec {
  type: "switch"
  label?: string
  hidden?: boolean
}

type SwitchValue = boolean

interface CheckboxesFieldSpec {
  type: "checkboxes"
  label?: string
  options: string[]
  optionLabels?: Record<string, string | undefined>
  hidden?: boolean
}

type CheckboxesValue = string[]

interface MultiSelectFieldSpec {
  type: "multi-select"
  label?: string
  options: string[]
  optionLabels?: Record<string, string | undefined>
  hidden?: boolean
}

type MultiSelectValue = string[]

interface TextFieldSpec {
  type: "text"
  label?: string
  hidden?: boolean
}

type TextValue = string | null

interface DateTimeFieldSpec {
  type: "datetime"
  label?: string
  hidden?: boolean
}

interface DateTimeRangeFieldSpec {
  type: "datetimerange"
  changeKeyFrom: string
  changeKeyTo: string
  label?: string
  hidden?: boolean
}

type DateTimeRangeValue = {
  start: string
  end: string
} | null

type DateTimeValue = string | null

interface SelectFieldSpec<SelectOption extends string> {
  type: "select"
  label?: string
  emptyOptionLabel?: string
  options: SelectOption[]
  optionLabels?: Record<SelectOption, string>
  hidden?: boolean
  hideEmptyOption?: boolean
}

type SelectValue<SelectOption extends string> = SelectOption | null

export type FieldSpec = (
  | SwitchFieldSpec
  | CheckboxesFieldSpec
  | MultiSelectFieldSpec
  | TextFieldSpec
  | DateTimeFieldSpec
  | DateTimeRangeFieldSpec
  | SelectFieldSpec<string>
) & {
  dataCy?: string
}

// prettier-ignore
export type Values<T extends {[key: string]: FieldSpec}> = {
  [K in keyof T]: T[K] extends SwitchFieldSpec ? SwitchValue
    : T[K] extends CheckboxesFieldSpec ? CheckboxesValue
    : T[K] extends TextFieldSpec ? TextValue
    : T[K] extends DateTimeFieldSpec ? DateTimeValue
    : T[K] extends DateTimeRangeFieldSpec ? DateTimeRangeValue
    : T[K] extends SelectFieldSpec<string> ? SelectValue<string>
    : T[K] extends MultiSelectFieldSpec ? MultiSelectValue
    : never
}

export function FilterCell<T extends {[key: string]: FieldSpec}>({
  fieldKey,
  item,
  value,
  hidden,
  onChange,
}: {
  fieldKey: string
  item: FieldSpec
  value: any
  hidden: boolean
  onChange: <K extends keyof T>(fieldKey: K, value: Values<T>[K]) => void
}) {
  if (hidden || item.hidden) {
    // dynamic (`hidden`) or config-based (`item.hidden`)
    return null
  }

  switch (item.type) {
    case "switch":
      return (
        <SwitchFilter
          key={fieldKey}
          value={value}
          onChange={(newValue: any) => onChange(fieldKey, newValue)}
          label={item.label}
          isTableFilter
        />
      )
    case "checkboxes":
      return (
        <CheckboxesFilter
          key={fieldKey}
          value={value}
          onChange={(newValue: any) => onChange(fieldKey, newValue)}
          label={item.label}
          options={item.options}
          optionLabels={item.optionLabels}
          isTableFilter
        />
      )
    case "select":
      return (
        <SelectFilter
          key={fieldKey}
          value={value}
          onChange={(newValue: any) => onChange(fieldKey, newValue)}
          label={item.label}
          options={item.options}
          emptyOptionLabel={item.emptyOptionLabel}
          optionLabels={item.optionLabels}
          hideEmptyOption={item.hideEmptyOption}
          isTableFilter
        />
      )

    case "multi-select":
      return (
        <MultiSelectFilter
          key={fieldKey}
          value={value}
          onChange={(newValue: any) => onChange(fieldKey, newValue)}
          label={item.label}
          options={item.options}
          optionLabels={item.optionLabels}
          isTableFilter
        />
      )

    case "text":
      return (
        <TextFilter
          key={fieldKey}
          value={value}
          onChange={(newValue: any) => onChange(fieldKey, newValue)}
          label={item.label}
          isTableFilter
          dataCy={item.dataCy}
        />
      )
    case "datetime":
      return (
        <DateTimeFilter
          key={fieldKey}
          value={value}
          onChange={(newValue: any) => onChange(fieldKey, newValue)}
          label={item.label}
          isTableFilter
        />
      )
    case "datetimerange":
      if (!item.changeKeyFrom || !item.changeKeyTo) {
        throw new Error(
          "Map the date time range fields to the corresponding state fields that require updating",
        )
      }
      return (
        <DateRangeFilter
          key={fieldKey}
          start={value?.start}
          end={value?.end}
          onChange={(changeKey, newValue: any) => onChange(changeKey, newValue)}
          changeKeyFrom={item.changeKeyFrom}
          changeKeyTo={item.changeKeyTo}
          isTableFilter
        />
      )
    default:
      throw new Error("Should be unreachable")
  }
}
