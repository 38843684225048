import {AUTH_URL} from "../../../../config"
import {LoginFailureData, LoginSuccessData} from "./types"

export default async function ({
  deviceId,
  password,
  token,
}: {
  password: string
  deviceId: string
  token: string
}): Promise<LoginSuccessData | LoginFailureData> {
  try {
    const response = await fetch(`${AUTH_URL}/auth/complete-onboarding`, {
      method: "POST",
      body: JSON.stringify({deviceId, password, token}),
      headers: {
        "Content-Type": "application/json",
      },
    })

    switch (response.status) {
      case 200: {
        const responseBody = await response.json()
        return {
          success: true,
          token: responseBody.accessToken,
          userId: `${responseBody.user.id}`,
        }
      }

      default: {
        return {
          success: false,
          message: ((await response.json()) as {error: true; type: string})
            .type,
        }
      }
    }
  } catch (_e) {
    return {
      success: false,
      message: "An unknown error occurred",
    }
  }
}
