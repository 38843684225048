import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import {useState} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {useTypedDispatch} from "src/hooks/redux"
import BusyButton from "../../../components/BusyButton"
import T from "../../../textResources"
import {logIn, register} from "../actionCreators"
export default function LoginForm() {
  const registering = useLocation().pathname.includes("register")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const dispatch = useTypedDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isRegistering, setIsRegistering] = useState(registering)
  const navigate = useNavigate()

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault()
        try {
          setIsSubmitting(true)
          await dispatch((isRegistering ? register : logIn)({email, password}))
        } finally {
          setIsSubmitting(false)
        }
      }}
    >
      <Grid container direction="column" alignItems="stretch" spacing={3}>
        <Grid item>
          <TextField
            required
            fullWidth
            disabled={isSubmitting}
            type="email"
            autoComplete="email"
            label={T.auth.loginPage.emailField.label}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            data-cy="login-email"
          />
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            disabled={isSubmitting}
            type="password"
            autoComplete={isRegistering ? "new-password" : "current-password"}
            label={T.auth.loginPage.passwordField.label}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            data-cy="login-password"
          />
        </Grid>
        <Grid item container alignItems="center" gap={2} flexDirection="column">
          <Grid item>
            <BusyButton
              variant="contained"
              color="primary"
              type="submit"
              busy={isSubmitting}
              data-cy="login-submit"
            >
              {isRegistering
                ? T.auth.loginPage.submitRegisterButton
                : T.auth.loginPage.submitLoginButton}
            </BusyButton>
          </Grid>
          <hr
            style={{
              width: "100%",
            }}
          />
          <Grid item container justifyContent="center" gap={2}>
            {isRegistering && (
              <Grid item>
                <BusyButton
                  variant="outlined"
                  color="primary"
                  busy={isSubmitting}
                  data-cy="register-toggle"
                  onClick={() => setIsRegistering(false)}
                >
                  {T.auth.loginPage.submitLoginButton}
                </BusyButton>
              </Grid>
            )}
            <Grid item>
              <BusyButton
                variant="outlined"
                color="primary"
                busy={isSubmitting}
                data-cy="reset-password"
                onClick={() => {
                  navigate("/forgot-password")
                }}
              >
                {T.auth.loginPage.forgotPassword}
              </BusyButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
