import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import {useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import BusyButton from "../../../components/BusyButton"
import T from "../../../textResources"
import resetPassword from "../actionCreators/networkRequests/resetPassword"

export default function ForgotPasswordForm() {
  const navigate = useNavigate()
  const {token} = useParams<{token: string}>() as {token: string}
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("")

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<null | string>(null)
  const [success, setSuccess] = useState(false)

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault()

        if (newPassword !== newPasswordConfirm) {
          setError("Passwords do not match!")
          return
        }

        try {
          setIsSubmitting(true)
          const res = await resetPassword(newPassword, token)

          if (!res.success) {
            setError(res.message)
          } else {
            setError(null)
            setSuccess(true)
          }
        } finally {
          setIsSubmitting(false)
        }
      }}
    >
      <Grid container direction="column" alignItems="stretch" spacing={3}>
        <Grid item>
          <BusyButton
            variant="text"
            color="primary"
            onClick={() => {
              navigate("/")
            }}
            disabled={isSubmitting}
          >
            {T.auth.resetPage.backButton}
          </BusyButton>
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            disabled={isSubmitting}
            type="password"
            autoComplete="new-password"
            label={T.auth.resetPage.passwordField.label}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            disabled={isSubmitting}
            type="password"
            autoComplete="new-password"
            label={T.auth.resetPage.passwordField.confirm}
            value={newPasswordConfirm}
            onChange={(e) => setNewPasswordConfirm(e.target.value)}
          />
        </Grid>
        {error && (
          <Grid item>
            <span style={{color: "red"}}>{error}</span>
          </Grid>
        )}
        {success && (
          <Grid item>
            <span style={{color: "green"}}>
              {T.auth.resetPage.resetSuccess}
            </span>
          </Grid>
        )}
        <Grid item>
          <BusyButton
            variant="contained"
            color="primary"
            type="submit"
            busy={isSubmitting}
          >
            {T.auth.resetPage.submitButton}
          </BusyButton>
        </Grid>
      </Grid>
    </form>
  )
}
