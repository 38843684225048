import MenuIcon from "@mui/icons-material/Menu"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Button from "@mui/material/Button"
import Icon from "@mui/material/Icon"
import IconButton from "@mui/material/IconButton"
import Switch from "@mui/material/Switch"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import * as R from "ramda"
import React, {useState} from "react"
import {Link} from "react-router-dom"
import PreviewBannerAvoidingSpacer from "src/features/previewChanges/components/PreviewBannerAvoidingSpacer"
import useShowBanner from "src/features/previewChanges/hooks/useShowBanner"
import {PreviewChangesName} from "src/features/previewChanges/types"
import {v4} from "uuid"
import T from "../textResources"
import BusyButton from "./BusyButton"
import ErrorBox from "./ErrorBox"
import LoadingText from "./LoadingText"
import MobileMenu from "./MobileMenu"
import {ApolloError} from "@apollo/client"

const DRAWER_WIDTH = 190
const CONTENT_PADDING = 24
export const APP_BAR_HEIGHT = 64

interface SwitchProps {
  type: "switch"
  text: string
  onClick: () => void
  value: boolean
  disabled?: boolean
  icon?: string
  important?: boolean
  busy?: boolean
}

interface SelectProps<T extends string = string> {
  type: "select"
  text: string
  onChange: (value?: T) => void
  options: Record<string, string>
  value?: T
  disabled?: boolean
  icon?: string
  important?: boolean
}

interface ButtonProps {
  type: "button"
  text: string
  onClick: () => void
  disabled?: boolean
  icon?: string
  important?: boolean
  busy?: boolean
  dataCy?: string
}

interface LinkProps {
  type: "link"
  disabled?: boolean
  text: string
  to: string
  icon?: string
  important?: boolean
  dataCy?: string
}

export type ActionProps = ButtonProps | SwitchProps | SelectProps | LinkProps

interface CommonProps {
  buttons?: Array<ActionProps>
  notification?: React.ReactNode
  previewBannerKey?: PreviewChangesName
}

interface ContentProps extends CommonProps {
  title: string
  children?: React.ReactNode
}

interface LoadingProps extends CommonProps {
  loading: boolean
}

interface ErrorProps extends CommonProps {
  error: ApolloError | string
}

type Props = LoadingProps | ErrorProps | ContentProps

export default function Page(props: Props) {
  const previewChangesBannerShown = useShowBanner(props.previewBannerKey)
  const theme = useTheme()
  const isCompactLayout = useMediaQuery(theme.breakpoints.down("md"))
  const [menuOpen, setMenuOpen] = useState(false)
  const handleOpenMenu = () => setMenuOpen(true)
  const handleCloseMenu = () => setMenuOpen(false)

  if (isCompactLayout) {
    return (
      <>
        {getAppBar({
          isCompactLayout,
          title:
            "loading" in props
              ? "Loading..."
              : "error" in props
                ? "Error"
                : props.title,
          buttons: props.buttons,
          notification: props.notification,
          handleOpenMenu,
        })}
        <MobileMenu open={menuOpen} handleClose={handleCloseMenu} />
        <Box style={styles.mobileContentWrapper}>
          {"loading" in props ? (
            <LoadingText>{T.generic.pageLoadingText}</LoadingText>
          ) : "error" in props ? (
            <ErrorBox error={props.error} />
          ) : (
            props.children
          )}
        </Box>
      </>
    )
  }

  return (
    <>
      <AppBar style={styles.appBar}>
        <Toolbar>
          <ToolbarContent
            title={
              "loading" in props
                ? "Loading..."
                : "error" in props
                  ? "Error"
                  : props.title
            }
            isCompactLayout={isCompactLayout}
            allPossibleButtons={props.buttons ?? []}
          />
        </Toolbar>
        {props.notification}
      </AppBar>
      {previewChangesBannerShown && <PreviewBannerAvoidingSpacer />}
      <Box style={styles.contentWrapper}>
        {"loading" in props ? (
          <LoadingText>{T.generic.pageLoadingText}</LoadingText>
        ) : "error" in props ? (
          <ErrorBox error={props.error} />
        ) : (
          props.children
        )}
      </Box>
    </>
  )
}

interface PageAppBarProps {
  isCompactLayout: boolean
  title: string
  buttons?: Array<ActionProps>
  notification?: React.ReactNode
  handleOpenMenu: () => void
}

function getAppBar({
  isCompactLayout,
  title,
  buttons,
  notification = null,
  handleOpenMenu,
}: PageAppBarProps) {
  return (
    <AppBar style={styles.mobileAppBar}>
      <Toolbar style={{paddingLeft: "24px"}}>
        <IconButton
          edge="start"
          className={"Menu"}
          color="inherit"
          aria-label="menu"
          onClick={handleOpenMenu}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <ToolbarContent
          title={title}
          isCompactLayout={isCompactLayout}
          allPossibleButtons={buttons ?? []}
        />
      </Toolbar>
      {notification}
    </AppBar>
  )
}

function ToolbarContent({
  title,
  isCompactLayout,
  allPossibleButtons,
  content,
}: {
  title?: string
  allPossibleButtons: Array<ActionProps>
  isCompactLayout: boolean
  content?: React.ReactNode
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isExtraOptionMenuOpen = Boolean(anchorEl)
  const handleOpenExtraOptions = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)
  const handleCloseExtraOptions = () => setAnchorEl(null)

  const INLINE_BUTTONS_LIMIT = 4
  const buttonsInMoreMenu = allPossibleButtons.filter((b) =>
    isCompactLayout
      ? true
      : allPossibleButtons.length > INLINE_BUTTONS_LIMIT && !b.important,
  )
  const buttonsInline = allPossibleButtons.filter((b) =>
    isCompactLayout
      ? false
      : allPossibleButtons.length <= INLINE_BUTTONS_LIMIT || b.important,
  )

  const moreOptionsMenu = buttonsInMoreMenu.length ? (
    <>
      <IconButton
        edge="end"
        color="inherit"
        aria-label="options"
        onClick={handleOpenExtraOptions}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
        id={"app-bar-buttons"}
        keepMounted
        transformOrigin={{vertical: "top", horizontal: "right"}}
        open={isExtraOptionMenuOpen}
        onClose={handleCloseExtraOptions}
      >
        {R.reverse(buttonsInMoreMenu).map((button, index) => (
          <ExtraButtonMenuItem
            closeMenu={handleCloseExtraOptions}
            item={button}
            key={index}
          />
        ))}
      </Menu>
    </>
  ) : null

  const inlineButtons = buttonsInline.length
    ? buttonsInline.map((button, index) => (
        <ExtraButtonToolbarItem key={index} item={button} />
      ))
    : null

  return (
    <Grid container justifyContent="space-between">
      <Grid item alignSelf={"center"}>
        <Typography variant="h6" color="inherit" noWrap>
          {title}
        </Typography>
      </Grid>
      {content && (
        <Grid item alignSelf={"center"}>
          {content}
        </Grid>
      )}
      <Grid item alignSelf={"center"}>
        <>
          {inlineButtons}
          {moreOptionsMenu}
        </>
      </Grid>
    </Grid>
  )
}

function ExtraButtonMenuItem({
  item,
  closeMenu,
}: {
  item: ActionProps
  closeMenu: () => void
}) {
  switch (item.type) {
    case "button":
      return (
        <MenuItem
          disabled={item.disabled}
          onClickCapture={closeMenu}
          onClick={item.onClick}
        >
          {item.icon ? (
            <ListItemIcon>
              <Icon>{item.icon}</Icon>
            </ListItemIcon>
          ) : null}
          <Typography variant="inherit">{item.text}</Typography>
        </MenuItem>
      )

    case "link":
      const isExternal = isExternalLink(item.to)
      const target = isExternal
        ? {href: item.to, target: "_blank", component: "a"}
        : {to: item.to, component: Link}

      return (
        <MenuItem {...target} disabled={item.disabled}>
          {item.icon ? (
            <ListItemIcon>
              <Icon>{item.icon}</Icon>
            </ListItemIcon>
          ) : null}
          <Typography variant="inherit">{item.text}</Typography>
        </MenuItem>
      )
  }
  return (
    <MenuItem>
      <ExtraButtonToolbarItem item={item} />
    </MenuItem>
  )
}

function ExtraButtonToolbarItem({item}: {item: ActionProps}) {
  switch (item.type) {
    case "select":
      const id = v4()
      return (
        <FormControl style={styles.selectWrapper}>
          <InputLabel htmlFor={id}>{item.text}</InputLabel>
          <Select
            value={item.value}
            inputProps={{id}}
            onChange={(event) =>
              item.onChange(
                typeof event.target.value === "string"
                  ? event.target.value
                  : undefined,
              )
            }
          >
            {Object.entries(item.options).map(([value, text]) => (
              <MenuItem key={value} value={value}>
                {text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )

    case "link":
      const isExternal = isExternalLink(item.to)
      const target = isExternal
        ? {href: item.to, target: "_blank", component: "a"}
        : {to: item.to, component: Link}

      return (
        <Button
          {...target}
          disabled={item.disabled}
          color="inherit"
          startIcon={item.icon ? <Icon>{item.icon}</Icon> : null}
          data-cy={item.dataCy}
        >
          {item.text}
        </Button>
      )

    case "button":
      return (
        <BusyButton
          onClick={item.onClick}
          disabled={item.disabled}
          color="inherit"
          startIcon={item.icon ? <Icon>{item.icon}</Icon> : null}
          busy={item.busy}
          data-cy={item.dataCy}
        >
          {item.text}
        </BusyButton>
      )

    case "switch":
      return (
        <BusyButton
          onClick={item.onClick}
          disabled={item.disabled}
          color="inherit"
          startIcon={item.icon ? <Icon>{item.icon}</Icon> : null}
          busy={item.busy}
        >
          {item.text}
          {"value" in item ? <Switch checked={item.value} /> : null}
        </BusyButton>
      )
  }
}

function isExternalLink(url: string) {
  return /^https?:\/\//.test(url)
}

const styles = {
  appBar: {
    position: "fixed" as const,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
  },
  contentWrapper: {
    paddingLeft: DRAWER_WIDTH + CONTENT_PADDING,
    paddingRight: CONTENT_PADDING,
    paddingTop: APP_BAR_HEIGHT + CONTENT_PADDING,
    paddingBottom: CONTENT_PADDING,
    display: "flex",
    flexDirection: "column" as const,
    boxSizing: "border-box" as const,
    minHeight: "100vh",
  },
  mobileAppBar: {
    position: "fixed" as const,
    marginLeft: 0,
  },
  mobileContentWrapper: {
    paddingTop: APP_BAR_HEIGHT + CONTENT_PADDING,
    paddingHorizontal: 5,
    display: "flex",
    flexDirection: "column" as const,
    boxSizing: "border-box" as const,
    width: "100%",
    minHeight: "100vh",
    paddingLeft: CONTENT_PADDING,
    paddingRight: CONTENT_PADDING,
    paddingBottom: CONTENT_PADDING,
  },
  selectWrapper: {
    width: "10em",
  },
}
