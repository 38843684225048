import React, {useCallback, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useLocation} from "react-router-dom"

type OnChangeOption<Options> = <K extends keyof Options>(
  key: K,
  value: Options[K],
) => void

interface PersistentOptionsProps<Options> {
  options: Options
  onChangeOption: OnChangeOption<Options>
}

interface PersistentOptionsAction<Options, K extends keyof Options> {
  type: "UPDATE_PERSISTENT_OPTIONS"
  storageKey: string
  optionKey: K
  optionValue: Options[K]
  initialOptions: Options
}

export interface PersistentOptionsState {
  [storageKey: string]: any | undefined
}

export default function withSemiPersistentOptionsHoc<
  Opt extends {[key: string]: any},
>(initialOptions: Opt) {
  return function <T extends object>(
    Component: React.ComponentType<T & PersistentOptionsProps<Opt>>,
  ) {
    const storageKey = `${Math.floor(Math.random() * 1000000000)}`

    return (props: T) => {
      const location = useLocation()
      const dispatch = useDispatch()
      const withPersistentOptions = useSelector(
        (state: {withPersistentOptions: PersistentOptionsState}) =>
          state.withPersistentOptions,
      )

      const onChangeOption = useCallback(
        <K extends keyof Opt>(optionKey: K, optionValue: Opt[K]) => {
          return dispatch({
            type: "UPDATE_PERSISTENT_OPTIONS",
            storageKey,
            optionKey,
            optionValue,
            initialOptions,
          } as PersistentOptionsAction<Opt, K>)
        },
        [dispatch],
      )

      const options =
        withPersistentOptions[storageKey] || (initialOptions as Opt)

      useEffect(() => {
        const overrides = new URLSearchParams(location.search)
        Object.keys(options).forEach((optionKey) => {
          const optionValue = overrides.get(optionKey)
          if (optionValue != null) {
            dispatch({
              type: "UPDATE_PERSISTENT_OPTIONS",
              storageKey,
              optionKey,
              optionValue,
              initialOptions,
            })
          }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      return (
        <Component
          {...(props as T)}
          options={options}
          onChangeOption={onChangeOption}
        />
      )
    }
  }
}

export const reducer = (
  state: PersistentOptionsState = {},
  action: PersistentOptionsAction<any, any> | {type: "__other__"},
): PersistentOptionsState => {
  if (action.type === "UPDATE_PERSISTENT_OPTIONS") {
    const currentOptions = state[action.storageKey] || action.initialOptions
    const newOptions = {
      ...currentOptions,
      [action.optionKey]: action.optionValue,
    }

    return {
      ...state,
      [action.storageKey]: newOptions,
    }
  }

  return state
}
