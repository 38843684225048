import {Container} from "@mui/material"
import {useTranslation} from "react-i18next"
import {Navigate, useNavigate} from "react-router-dom"
import Page from "src/components/Page"
import {Permission} from "src/types"
import {PermissionError} from "../../auth/components/PermissionError"
import useCurrentUser from "../../auth/hooks/useCurrentUser"
import {
  useAdmissionsAdminPermission,
  useUserPermission,
} from "../../auth/hooks/useUserPermissions"
import BusinessSelector from "../components/BusinessSelector"

export function Root() {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const user = useCurrentUser()
  const canViewTickets = useUserPermission(Permission.CmsViewTickets)
  const canCheckVisitorsIn = useUserPermission(
    Permission.CmsCanViewCheckinTokens,
  )
  const canManageUsers = useUserPermission(Permission.CmsCanEditUsers)
  const isAdmissionsAdmin = useAdmissionsAdminPermission()

  if (isAdmissionsAdmin) {
    return (
      <Page
        title="Choose Business"
        buttons={[
          {
            type: "button",
            text: t("features.setup.business.onboard"),
            onClick: () => navigate("/new"),
          },
        ]}
      >
        <Container>
          <BusinessSelector />
        </Container>
      </Page>
    )
  }

  if (user?.business?.id && canViewTickets) {
    return <Navigate to={"/tickets"} replace />
  }

  if (user?.business?.id && canCheckVisitorsIn) {
    return <Navigate to={"/check-in"} replace />
  }

  if (user?.business?.id && canManageUsers) {
    return <Navigate to={"/setup-users"} replace />
  }

  return <PermissionError />
}
