import StarIcon from "@mui/icons-material/Star"
import StarOutlineIcon from "@mui/icons-material/StarOutline"
import {
  Box,
  CardActionArea,
  Grid,
  IconButton,
  Paper,
  Typography,
  styled,
} from "@mui/material"
import {Stack} from "@mui/material"
import * as R from "ramda"
import {useEffect, useState} from "react"
import useCurrentUser from "src/features/auth/hooks/useCurrentUser"
import withAuth from "src/features/withAuth"
import {
  Permission,
  useAssociateBusinessMutation,
  useTicketableBusinessesQuery,
} from "src/types/apollo"
import getFavouriteBusinesses, {
  FAVOURITE_BUSINESSES_STORAGE_KEY,
} from "src/utils/getFavouriteBusinesses"
import {SelectedBusiness} from "../../../components/types"
import {useTypedDispatch} from "src/hooks/redux"
import {getPermissionsAndCurrentUser} from "src/features/auth"

function BusinessSelector() {
  const user = useCurrentUser()
  const dispatch = useTypedDispatch()

  const [favouriteBusinesses, setFavouriteBusinesses] = useState<
    Array<SelectedBusiness>
  >(getFavouriteBusinesses())

  const [associate] = useAssociateBusinessMutation({
    refetchQueries: "active",
  })
  const handleAssociationSwitch = async (business: SelectedBusiness) => {
    if (!user) {
      return null
    }

    const {data: d} = await associate({
      variables: {
        userId: user.id,
        businessId: business.id,
      },
    })

    if (d?.setBusinessAssociation) {
      await getPermissionsAndCurrentUser(dispatch)
      return
    }

    return null
  }

  const {data} = useTicketableBusinessesQuery({
    variables: {},
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  })

  const ticketableBusinesses = R.sortBy(
    R.prop("companyName"),
    data?.businesses || [],
  ).map((business) => ({
    ...business,
    selected: business.id === user?.business?.id,
  }))

  const userFavouritedBusinesses = favouriteBusinesses.map((fave) => ({
    ...fave,
    selected: fave.id === user?.business?.id,
  }))

  useEffect(() => {
    localStorage.setItem(
      FAVOURITE_BUSINESSES_STORAGE_KEY,
      JSON.stringify(favouriteBusinesses),
    )
  }, [favouriteBusinesses])

  return (
    <Stack spacing={4}>
      {userFavouritedBusinesses.length > 0 ? (
        <Stack spacing={2}>
          <Typography variant="h5">Favourites</Typography>
          <GridOfBusinesses
            businessToShowInGrid={userFavouritedBusinesses}
            favouriteBusinesses={userFavouritedBusinesses}
            onBusinessSelected={handleAssociationSwitch}
            onBusinessFavourited={(newFavourite) =>
              setFavouriteBusinesses([...favouriteBusinesses, newFavourite])
            }
            onBusinessUnfavourited={(favouriteToRemove) =>
              setFavouriteBusinesses(
                favouriteBusinesses.filter(
                  (fb) => fb.id !== favouriteToRemove.id,
                ),
              )
            }
          />
        </Stack>
      ) : null}

      <Stack spacing={2}>
        <Typography variant="h5">All Businesses</Typography>
        <GridOfBusinesses
          businessToShowInGrid={ticketableBusinesses}
          favouriteBusinesses={userFavouritedBusinesses}
          onBusinessSelected={handleAssociationSwitch}
          onBusinessFavourited={(newFavourite) =>
            setFavouriteBusinesses([...favouriteBusinesses, newFavourite])
          }
          onBusinessUnfavourited={(favouriteToRemove) =>
            setFavouriteBusinesses(
              favouriteBusinesses.filter(
                (fb) => fb.id !== favouriteToRemove.id,
              ),
            )
          }
        />
      </Stack>
    </Stack>
  )
}

export default withAuth([Permission.CmsAdministerTicketing])(BusinessSelector)

interface Props {
  businessToShowInGrid: Array<SelectedBusiness & {selected: boolean}>
  favouriteBusinesses: Array<SelectedBusiness & {selected: boolean}>
  onBusinessSelected: (business: SelectedBusiness) => void
  onBusinessFavourited: (business: SelectedBusiness) => void
  onBusinessUnfavourited: (business: SelectedBusiness) => void
}

function GridOfBusinesses({
  businessToShowInGrid: allBusinesses,
  favouriteBusinesses,
  onBusinessSelected,
  onBusinessFavourited,
  onBusinessUnfavourited,
}: Props) {
  return (
    <Box
      sx={{
        display: "grid",
        gap: 2,
      }}
    >
      <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}}>
        {allBusinesses.map(({id, companyName, selected}) => (
          <Grid item xs={2} sm={3} md={3} key={id}>
            <CardActionArea>
              <Item
                variant={"outlined"}
                square={false}
                selected={selected}
                onClick={() => onBusinessSelected({id, companyName})}
              >
                <Stack
                  direction={"row"}
                  alignItems="center"
                  height="100%"
                  pr={1}
                >
                  {favouriteBusinesses.map((fb) => fb.id).includes(id) ? (
                    <IconButton
                      onClick={() => onBusinessUnfavourited({id, companyName})}
                      color="secondary"
                      aria-label="favourite"
                    >
                      <StarIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => onBusinessFavourited({id, companyName})}
                      aria-label="add a favourite"
                    >
                      <StarOutlineIcon />
                    </IconButton>
                  )}

                  <Typography
                    sx={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                    }}
                  >
                    {companyName}
                  </Typography>
                </Stack>
              </Item>
            </CardActionArea>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

const Item = styled(Paper)<{selected: boolean}>(({theme, selected}) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: "60px",
  borderColor: selected ? theme.palette.primary.main : undefined,
  borderWidth: selected ? 2 : undefined,
  ":hover": {
    borderColor: theme.palette.primary.main,
  },
}))
