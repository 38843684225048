import {Card, CardContent, CardHeader, Container} from "@mui/material"
import {useState} from "react"
import {useTranslation} from "react-i18next"
import {useParams} from "react-router-dom"
import {AuditLogTable} from "src/features/auditLog/controllers/AuditLogTable"
import {useUserPermission} from "src/features/auth/hooks/useUserPermissions"
import withAuth, {authedOrFallback} from "src/features/withAuth"
import withBusiness from "src/features/withBusiness"
import {Permission, useAssociatedBusinessUsersQuery} from "src/types/apollo"
import {removeNothings} from "src/utils/nothingRemoval"
import Page from "../../../components/Page"
import UserPermissionsModal from "../components/UserPermissionsModal"

interface State {
  permissionsModalIsOpen: boolean
}

function UserPage() {
  const {t} = useTranslation()
  const {userId} = useParams<{
    userId: string
  }>()
  const canAdministerPermissions = useUserPermission(
    Permission.CmsAdministerPermissions,
  )
  const [state, setState] = useState<State>({permissionsModalIsOpen: false})

  const buttons = canAdministerPermissions
    ? [
        {
          type: "button" as const,
          text: t("features.users.permissions.edit"),
          onClick: () => setState({permissionsModalIsOpen: true}),
        },
      ]
    : []

  const AuditLogCard = authedOrFallback([Permission.CanAccessAuditLog])(() => (
    <Card>
      <CardHeader title={t("features.auditLog.label")} />
      <CardContent>
        <AuditLogTable
          showFilters
          filterFieldsToHide={["userId"]}
          columnsToHide={["userId"]}
          options={{
            entityType: null,
            entityId: null,
            afterDate: null,
            beforeDate: null,
            userId: userId,
            type: null,
          }}
        />
      </CardContent>
    </Card>
  ))

  const {data, loading, error, refetch} = useAssociatedBusinessUsersQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  })

  const users = data?.me.associatedBusiness
    ? removeNothings(data.me.associatedBusiness.associatedUsers ?? []).filter(
        (u) => `${u.id}` === userId,
      )
    : undefined

  if (loading && !data) {
    return <Page loading />
  }

  if (error || !data || !users?.length || !userId) {
    return <Page error={error || t("features.users.noUserFound")} />
  }

  return (
    <Page title={users[0].email} buttons={buttons}>
      <Container>
        <AuditLogCard />
      </Container>
      <UserPermissionsModal
        open={state.permissionsModalIsOpen}
        onClose={() => {
          setState({permissionsModalIsOpen: false})
          refetch()
        }}
        userId={userId}
        email={users[0].email}
        permissions={users[0].permissions}
      />
    </Page>
  )
}

export default withAuth([Permission.CmsCanEditUsers])(withBusiness(UserPage))
