import moment from "moment"
import {useTypedSelector} from "src/hooks/redux"
import {PreviewChangesName} from "../types"

export default function (key: PreviewChangesName | undefined) {
  const hasNextShowDateTime = useTypedSelector((state) =>
    key ? state.previewChanges.dismissed[key] : null,
  )
  if (hasNextShowDateTime != null) {
    // Check if we should show despite having previously been dismissed.
    return moment().isAfter(hasNextShowDateTime)
  }

  if (key == null) {
    return false
  }

  // If no 'next show' date-time then never been dismissed - show.
  return true
}
