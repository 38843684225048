import Snackbar from "@mui/material/Snackbar"
import {Alert, AlertTitle} from "@mui/material"
import Slide from "@mui/material/Slide"

interface Props {
  message: string | null
  onSnackbarClose: () => void
  dataCy?: string
}

function Transition(props: any) {
  return <Slide {...props} direction="up" />
}

export const ActionSuccessSnackbar = (props: Props) => {
  const cypressTag = props.dataCy ? props.dataCy : "ticket-action-success"

  return (
    <div>
      <Snackbar
        open={props.message !== null}
        autoHideDuration={2500}
        onClose={props.onSnackbarClose}
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
        TransitionComponent={Transition}
      >
        <Alert
          onClose={props.onSnackbarClose}
          severity="success"
          variant="filled"
        >
          <AlertTitle data-cy={cypressTag}>{props.message}</AlertTitle>
        </Alert>
      </Snackbar>
    </div>
  )
}
