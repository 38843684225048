import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import {useTheme} from "@mui/material"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"

interface Props {
  min: number
  max: number
  onChange: (num: number) => void
  value: number
  width?: string
}

function getBorderColour(
  condition: boolean,
  primaryTextColour: string,
  disabledTextColour: string,
) {
  return condition
    ? `2px solid ${disabledTextColour}`
    : `2px solid ${primaryTextColour}`
}

function getColour(
  condition: boolean,
  primaryTextColour: string,
  disabledTextColour: string,
) {
  return condition ? disabledTextColour : primaryTextColour
}

export default function Counter({
  min,
  max,
  value,
  width = "200px",
  onChange,
}: Props) {
  const primaryTextColour = useTheme().palette.text.primary
  const disabledTextColour = useTheme().palette.text.disabled
  return (
    <div
      style={{
        display: "flex",
        width,
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <IconButton
        disabled={value === min ? true : false}
        onClick={(e) => {
          onChange(value - 1)
          e.preventDefault()
        }}
        style={{
          border: getBorderColour(
            value === min,
            primaryTextColour,
            disabledTextColour,
          ),
        }}
        data-cy="counter-remove"
        size="large"
      >
        <ExpandMore color={value === min ? "disabled" : "primary"} />
      </IconButton>
      <Typography variant="h5">
        <div
          style={{
            userSelect: "none",
            color: getColour(
              value > max,
              primaryTextColour,
              disabledTextColour,
            ),
          }}
        >
          {value}
        </div>
      </Typography>
      <IconButton
        disabled={value >= max ? true : false}
        onClick={(_e) => {
          onChange(value + 1)
        }}
        style={{
          border: getBorderColour(
            value >= max,
            primaryTextColour,
            disabledTextColour,
          ),
        }}
        data-cy="counter-add"
        size="large"
      >
        <ExpandLess color={value >= max ? "disabled" : "primary"} />
      </IconButton>
    </div>
  )
}
