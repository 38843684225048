import i18next from "i18next"
import {initReactI18next} from "react-i18next"
import translation from "./en/translations.json"

i18next.use(initReactI18next).init({
  lng: "en", // if you're using a language detector, do not define the lng option
  debug: true,
  resources: {
    en: {
      translation,
    },
  },
})

i18next.services?.formatter?.add("uppercase", (value, _, __) => {
  return value.toUpperCase()
})
