import {PropsWithChildren} from "react"
import {useDispatch, useSelector} from "react-redux"
import Modal from "src/components/Modal"
import {Permission} from "src/types/apollo"
import {logOut} from "../actionCreators"
import {PermissionError} from "../components/PermissionError"
import {AuthState} from "../types"
import AuthRouter from "./AuthRouter"

function AuthWrapperComponent(props: PropsWithChildren): JSX.Element {
  const dispatch = useDispatch()
  const authState = useSelector((state: {auth: AuthState}) => state.auth)

  if (!authState.token) {
    return <AuthRouter />
  }

  if (!authState.permissions.includes(Permission.CmsLogin)) {
    return (
      <Modal
        open={true}
        onClose={() => dispatch(logOut())}
        closeButtonText={"Back to login"}
        title="Permission Error"
      >
        <PermissionError />
      </Modal>
    )
  }

  return <>{props.children}</>
}

export default AuthWrapperComponent
