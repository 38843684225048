import React from "react"
import Typography from "@mui/material/Typography"
import styled, {keyframes} from "styled-components"
import {PropsWithChildren} from "react"

interface Props extends PropsWithChildren {
  noAnimation?: boolean
}

export default class LoadingText extends React.PureComponent<Props> {
  public render() {
    const {noAnimation, children} = this.props
    const text = children || "Loading..."

    return (
      <Container noAnimation={noAnimation}>
        <Typography>
          <LightweightEm>{text}</LightweightEm>
        </Typography>
      </Container>
    )
  }
}

const delayedFadeIn = keyframes`
  from { opacity: 0; }
  15% { opacity: 0; }
  to { opacity: 1; }
`

const Container = styled.div<{noAnimation?: boolean}>`
  animation: ${({noAnimation}) => (noAnimation ? "none" : delayedFadeIn)} 6s;
`

const LightweightEm = styled.em`
  opacity: 0.5;
`
