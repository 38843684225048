const TRANSLATIONS = {
  Adult: "Adults",
  Child: "Children",
  Senior: "Seniors",
  Infant: "Infants",
}

export function getVisitorTypePluralGrammar(visitorTypeName: string): string {
  const found = Object.keys(TRANSLATIONS).find(
    (t) => visitorTypeName.indexOf(t) > -1,
  ) as keyof typeof TRANSLATIONS | undefined

  if (!found) {
    return visitorTypeName
  }

  return visitorTypeName.replace(found, TRANSLATIONS[found])
}
