import React from "react"
import Page from "src/components/Page"
import useCurrentUser from "../auth/hooks/useCurrentUser"

interface Props extends Object {}

const withBusiness = (
  Component: React.ComponentType<
    Props & {businessId: string; businessName: string | undefined}
  >,
) => {
  return function (props: Props) {
    const user = useCurrentUser()
    const businessId = user?.business?.id

    if (businessId == null) {
      return (
        <Page
          error={"You must be associated with a business to view this page"}
        />
      )
    }

    return (
      <Component
        {...props}
        businessId={businessId}
        businessName={user?.business?.name ?? undefined}
      />
    )
  }
}

export default withBusiness
