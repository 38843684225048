import {Typography} from "@mui/material"

interface Props {
  children: string
}

export default function ErrorDisplay(props: Props) {
  return (
    <div style={{color: "#ff6e6e"}}>
      <Typography variant="subtitle2">{props.children}</Typography>
    </div>
  )
}
