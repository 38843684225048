import React from "react"
import Typography from "@mui/material/Typography"
import T from "../textResources"
import styled from "styled-components"

interface Props {
  error?: any
}

export default class ErrorBox extends React.PureComponent<Props> {
  public render() {
    return (
      <div>
        <Typography gutterBottom>{T.generic.pageErrorText}</Typography>
        <PreBox data-cy="access-permissions-error">
          {stringifyError(this.props.error)}
        </PreBox>
      </div>
    )
  }
}

const PreBox = styled.pre`
  background: #eee;
  padding: 16px;
  white-space: pre-wrap;
  color: black;
`

export type UnknownError =
  | string
  | object
  | Error
  | undefined
  | {networkError?: {result?: object}}

export function stringifyError(error: UnknownError): string {
  if (
    typeof error === "object" &&
    "networkError" in error &&
    error.networkError &&
    error.networkError.result
  ) {
    return JSON.stringify(error.networkError.result)
  }

  if (typeof error === "object" && "message" in error) {
    return error.message
  }

  if (typeof error === "string") {
    return error
  }

  if (typeof error === "undefined") {
    return "Unknown error"
  }

  return JSON.stringify(error, null, 2)
}
