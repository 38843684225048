import {Box, FormLabel, Stack, Typography} from "@mui/material"
import {DateTimePicker} from "@mui/x-date-pickers"
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import moment from "moment"
import styled from "styled-components"

interface Props {
  changeKeyFrom: string
  changeKeyTo: string
  start: string | null
  end: string | null
  isTableFilter?: boolean
  onChange: (changeKey: string, newValue: string | null) => void
}

export default function ({
  start,
  end,
  changeKeyFrom,
  changeKeyTo,
  isTableFilter,
  onChange,
}: Props) {
  return (
    <Container>
      {isTableFilter ? null : <FormLabel>Created</FormLabel>}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Stack flexDirection="row" alignItems={"center"}>
          <DateTimePicker
            value={start ? moment(start) : null}
            sx={isTableFilter ? {maxWidth: "100px"} : undefined}
            ampm={false}
            slotProps={{
              textField: {
                placeholder: "from",
                variant: isTableFilter ? "standard" : undefined,
                style: isTableFilter
                  ? {fontVariant: "all-small-caps"}
                  : undefined,
              },
              actionBar: {
                actions: ["clear", "cancel", "accept"],
              },
            }}
            onAccept={(m: moment.Moment | null) => {
              onChange(changeKeyFrom, m?.format() ?? null)
            }}
          />
          <Box display={"flex"}>
            <Typography paddingLeft={1} paddingRight={1}>
              —
            </Typography>
          </Box>
          <DateTimePicker
            value={end ? moment(end) : null}
            sx={isTableFilter ? {maxWidth: "100px"} : undefined}
            ampm={false}
            slotProps={{
              textField: {
                placeholder: "to",
                variant: isTableFilter ? "standard" : undefined,
                style: isTableFilter
                  ? {fontVariant: "all-small-caps"}
                  : undefined,
              },
              actionBar: {
                actions: ["clear", "cancel", "accept"],
              },
            }}
            onAccept={(m: moment.Moment | null) => {
              onChange(changeKeyTo, m?.format() ?? null)
            }}
          />
        </Stack>
      </LocalizationProvider>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
