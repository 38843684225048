import {useState} from "react"

import CloseIcon from "@mui/icons-material/Close"
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  IconButton,
  Typography,
} from "@mui/material"
import querystring from "query-string"
import {Link} from "react-router-dom"
import ErrorDisplay from "src/components/ErrorDisplay"
import LoadingText from "src/components/LoadingText"
import {Ticket, useTicketAvailableAddonProductsQuery} from "src/types/apollo"
import {ProductSelect} from "./ProductSelect"

interface Props {
  modalOpen: boolean
  toggleModal: () => void
  ticket: Ticket
}

export default function AddAddonModal(props: Props) {
  const {data, loading, error} = useTicketAvailableAddonProductsQuery({
    variables: {
      ticketId: props.ticket.id,
    },
  })

  const [addonProductId, setAddonProductId] = useState<string | null>(null)

  const addons = data?.tickets?.[0]?.product?.addons ?? []

  return (
    <Dialog onClose={props.toggleModal} open={props.modalOpen} fullWidth>
      <DialogTitle>
        <Typography variant="h6">Add Addon Product to Ticket</Typography>
        <div style={{position: "absolute", top: "8px", right: "8px"}}>
          <IconButton onClick={props.toggleModal} size="large">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      {loading ? (
        <LoadingText />
      ) : error || (!loading && addons.length === 0) ? (
        <ErrorDisplay>
          Could not display available addons for this ticket.
        </ErrorDisplay>
      ) : (
        <div style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 16}}>
          <FormControl style={{minWidth: 400, marginBottom: 24}}>
            <ProductSelect
              onChange={setAddonProductId}
              selected={addonProductId ?? ""}
              products={addons.map((a) => a.addonProduct) ?? []}
              label="Addon Product"
            />
          </FormControl>

          <div>
            <Button
              variant="contained"
              disabled={!addonProductId}
              component={Link}
              to={`/tickets/new?${querystring.stringify(
                {
                  addonProductId,
                  email: props.ticket.email,
                  addonParentTicketId: props.ticket.id,
                },
                {
                  encode: true,
                  skipNull: true,
                },
              )}`}
            >
              Create addon ticket
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  )
}
