import {Box, Link} from "@mui/material"
import useCurrentUser from "src/features/auth/hooks/useCurrentUser"
import {useAdmissionsAdminPermission} from "src/features/auth/hooks/useUserPermissions"
import styled, {keyframes} from "styled-components"

export default function CurrentUser() {
  const currentUser = useCurrentUser()
  const isAdmissionsAdmin = useAdmissionsAdminPermission()

  if (!currentUser || !currentUser.email) {
    return null
  }

  const SIDEBAR_WIDTH = 26
  const truncatedEmail = ((t: number) => (e: string) =>
    e.length < t ? e : e.slice(0, t) + "...")(SIDEBAR_WIDTH)(currentUser.email)

  return (
    <>
      <FadeIn data-cy="current-user">
        {isAdmissionsAdmin ? (
          <Box flexDirection="row" width="100%">
            <div style={{marginBottom: 8}}>
              <Link
                href={`/super/users/${currentUser.id}`}
                style={{color: "inherit", cursor: "pointer"}}
              >
                {truncatedEmail}
              </Link>
              {currentUser.business?.name != null && (
                <div>({currentUser.business.name})</div>
              )}
            </div>
            <div>
              <Link
                href={"/audit-log"}
                style={{
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                {"Audit Logs"}
              </Link>
            </div>
          </Box>
        ) : (
          <>{truncatedEmail}</>
        )}
      </FadeIn>
    </>
  )
}

const fadeInAnimation = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`

const FadeIn = styled.span`
  animation: ${fadeInAnimation} 0.4s;
  white-space: pre-line;
`
