import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import "./MobileMenu.css"
import SidebarNavList from "./SidebarNavList"

interface Props {
  open: boolean
  handleClose: () => void
}

export default function MobileMenu({open, handleClose}: Props) {
  return (
    <Drawer
      variant="persistent"
      anchor={"left"}
      open={open}
      classes={{paper: "menu__drawer-paper"}}
      transitionDuration={300}
    >
      <Toolbar
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginLeft: -16,
        }}
        className="sidebar__drawer-toolbar"
      >
        <IconButton
          onClick={handleClose}
          style={{alignSelf: "left"}}
          size="large"
        >
          <ChevronLeftIcon />
        </IconButton>
        <Divider absolute />
      </Toolbar>

      <SidebarNavList />
    </Drawer>
  )
}
