import SvgIcon from "@mui/material/SvgIcon"
import styled, {keyframes} from "styled-components"

const rotate = keyframes`
  100% {
    transform:rotate(360deg);
  }
`

type Props = {
  animated: boolean
}

const AnimatedWrapper = styled.div<Props>`
  animation-name: ${rotate};
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: ${(props) => (props.animated ? "running" : "paused")};
  width: 24px;
  height: 24px;
`

export const SpinnerSvg = ({animated}: Props) => (
  <AnimatedWrapper animated={animated}>
    <SvgIcon height="24px" viewBox="0 0 32 32" width="24px">
      <circle
        cx="16"
        cy="16"
        fill="none"
        r="14"
        strokeWidth="4"
        style={{stroke: "currentColor", opacity: 0.2}}
      ></circle>
      <circle
        cx="16"
        cy="16"
        fill="none"
        r="14"
        strokeWidth="4"
        style={{
          stroke: "currentColor",
          strokeDasharray: 80,
          strokeDashoffset: 60,
        }}
      ></circle>
    </SvgIcon>
  </AnimatedWrapper>
)
