import moment from "moment"
import {useState} from "react"
import styled from "styled-components"

import CloseIcon from "@mui/icons-material/Close"
import "@mui/lab"
import {Checkbox, FormControlLabel, Typography} from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import {DatePicker} from "@mui/x-date-pickers"

import {useTranslation} from "react-i18next"
import ErrorDisplay from "src/components/ErrorDisplay"
import {useUserPermission} from "src/features/auth/hooks/useUserPermissions"
import {
  Permission,
  Ticket,
  TicketPaymentDataSubscription,
  useUpdateTicketExpiryMutation,
} from "src/types/apollo"
import BusyButton from "../../../components/BusyButton"

interface Props {
  modalOpen: boolean
  toggleModal: () => void
  ticket: Ticket
  onSuccess: () => void
}

export default function UpdateTicketExpiryModal(props: Props) {
  const {t} = useTranslation()
  const canExtendSubscriptionTickets = useUserPermission(
    Permission.CmsCanExtendSubscriptionTickets,
  )
  const [expiresAt, setExpiresAt] = useState(
    moment(props.ticket.expiresAt).format("YYYY-MM-DD"),
  )
  const [expireNow, setExpireNow] = useState(false)
  const [shouldSendExpiryExtendedEmail, setShouldSendExpiryExtendedEmail] =
    useState(false)

  const [updateTicketExpiry, {error, loading}] = useUpdateTicketExpiryMutation({
    refetchQueries: ["GetTicket"],
  })

  const errorMessageHash = {
    DateInPast: t("features.tickets.update.errors.dateInPast"),
    DateNotChanged: t("features.tickets.update.errors.dateNotChanged"),
    ExpiredTicketTooOldToExtend: t(
      "features.tickets.update.errors.expiredTicketTooOldToExtend",
    ),
    ExpiryTooFarIntoTheFuture: t(
      "features.tickets.update.errors.expiryTooFarIntoTheFuture",
    ),
    CannotExtendExpiredSubscriptionTickets: t(
      "features.tickets.update.errors.cannotExtendExpiredSubscriptionTickets",
    ),
    CannotExpireSubscriptionInPast: t(
      "features.tickets.update.errors.cannotExpireSubscriptionInPast",
    ),
  }

  let errorMessage = null as string | null
  if (error && error.graphQLErrors && error.graphQLErrors.length) {
    const errorKey = error.graphQLErrors[0].message

    if (errorMessageHash[errorKey as keyof typeof errorMessageHash]) {
      errorMessage = errorMessageHash[errorKey as keyof typeof errorMessageHash]
    } else {
      errorMessage = errorKey
    }
  }

  const isExpiryBeforePreviousExpiry = moment(expiresAt).isBefore(
    props.ticket.expiresAt,
  )

  const isSubscription =
    props.ticket.paymentData?.__typename === "TicketPaymentDataSubscription" &&
    props.ticket.paymentData.subscription.subscriptionId != null
  const subscriptionData =
    isSubscription &&
    (props.ticket.paymentData as TicketPaymentDataSubscription)

  function getContent() {
    if (isSubscription && !canExtendSubscriptionTickets) {
      return (
        <Typography>
          {t("features.tickets.update.cannotChangeExpiryOfSubscription")}
        </Typography>
      )
    }

    if (subscriptionData && subscriptionData.renewalIsCancelled) {
      return (
        <Typography>
          {t(
            "features.tickets.update.cannotChangeExpiryOfCancelledSubscription",
          )}
        </Typography>
      )
    }

    return (
      <>
        <DatePicker
          value={moment(expiresAt)}
          onChange={(m) => {
            if (m) {
              setExpiresAt(m.format())
            }
          }}
          format="DD/MM/YYYY"
          sx={{minWidth: 400}}
          disablePast={true}
          disabled={expireNow}
        />
        {props.ticket.status !== "EXPIRED" && !isSubscription && (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={expireNow}
                  onChange={() => setExpireNow((e) => !e)}
                  name="expireNow"
                />
              }
              label={t("features.tickets.update.expireNow")}
            />
          </div>
        )}

        <div>
          <FormControlLabel
            control={
              <Checkbox
                disabled={isExpiryBeforePreviousExpiry || expireNow}
                checked={
                  isExpiryBeforePreviousExpiry || expireNow
                    ? false
                    : shouldSendExpiryExtendedEmail
                }
                onChange={() => setShouldSendExpiryExtendedEmail((e) => !e)}
                name="sendExpiryExtendedEmail"
              />
            }
            label={t("features.tickets.update.sendExpiryExtendedEmail")}
          />
        </div>
        {errorMessage && (
          <ModalMessage>
            <ErrorDisplay>{errorMessage}</ErrorDisplay>
          </ModalMessage>
        )}
        <div style={{marginTop: 16}}>
          <BusyButton
            color="primary"
            variant="contained"
            busy={loading}
            onClick={async () => {
              if (expiresAt === null) {
                return
              }

              const updatedExpiry = expireNow
                ? moment().subtract(1, "day").endOf("day").format()
                : expiresAt

              try {
                const result = await updateTicketExpiry({
                  variables: {
                    id: props.ticket.id,
                    expiresAt: updatedExpiry,
                    shouldSendExpiryExtendedEmail:
                      isExpiryBeforePreviousExpiry || expireNow
                        ? false
                        : shouldSendExpiryExtendedEmail,
                  },
                })

                if (result && result.data) {
                  setExpiresAt(updatedExpiry)
                  setExpireNow(false)

                  props.onSuccess()
                }
              } catch (_e) {}
            }}
          >
            {t("actions.update")}
          </BusyButton>
        </div>
      </>
    )
  }

  return (
    <Dialog onClose={props.toggleModal} open={props.modalOpen} fullWidth>
      <DialogTitle>
        <Typography variant="h6">Update expiry</Typography>
        <div style={{position: "absolute", top: "8px", right: "8px"}}>
          <IconButton onClick={props.toggleModal} size="large">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <div style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 16}}>
        {getContent()}
      </div>
    </Dialog>
  )
}

const ModalMessage = styled.div`
  padding-bottom: 16px;
`
