import {useDispatch, useSelector} from "react-redux"
import type {Action} from "redux"
import type {ThunkDispatch} from "redux-thunk"
import type {ReduxState} from "../types/redux"

export function useTypedSelector<TSelected>(
  selector: (state: ReduxState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
): TSelected {
  return useSelector(selector, equalityFn)
}

export function useTypedDispatch(): ThunkDispatch<ReduxState, {}, Action> {
  return useDispatch()
}
