import {AuthAction, AuthState} from "../types"
import Bugsnag from "@bugsnag/js"

const PERMISSIONS_STORAGE_KEY = "currentUserPermissions"
const CURRENT_USER_STORAGE_KEY = "currentUser"

function generateDeviceId() {
  const newDeviceId = `cms-${Math.floor(Math.random() * 10000000000000)}`
  localStorage.setItem("deviceId", newDeviceId)
  return newDeviceId
}

const initialState: AuthState = {
  token: localStorage.getItem("authToken") || null,
  deviceId: localStorage.getItem("deviceId") || generateDeviceId(),
  userId: localStorage.getItem("userId") || null,
  permissions: JSON.parse(
    localStorage.getItem(PERMISSIONS_STORAGE_KEY) || "[]",
  ),
  // biome-ignore lint/complexity/noExtraBooleanCast: legacy
  currentUser: !!localStorage.getItem(CURRENT_USER_STORAGE_KEY)
    ? JSON.parse(localStorage.getItem(CURRENT_USER_STORAGE_KEY)!)
    : null,
  permissionsErrorReason: null,
}

export default function (
  state: AuthState = initialState,
  action: AuthAction,
): AuthState {
  switch (action.type) {
    case "LOG_IN_SUCCESS":
      Bugsnag.setUser(action.userId)
      return {
        ...state,
        token: action.token,
        userId: action.userId,
      }

    case "LOAD_PERMISSIONS_AND_CURRENT_USER_SUCCESS":
      localStorage.setItem(
        PERMISSIONS_STORAGE_KEY,
        JSON.stringify(action.permissions),
      )
      localStorage.setItem(
        CURRENT_USER_STORAGE_KEY,
        JSON.stringify(action.currentUser),
      )
      Bugsnag.setUser(
        action.currentUser.id.toString(),
        action.currentUser.email,
      )
      return {
        ...state,
        permissions: action.permissions,
        currentUser: action.currentUser,
        permissionsErrorReason: null,
      }

    case "USER_AND_PERMISSIONS_CHECK_FAILED":
      Bugsnag.setUser(undefined, undefined)
      return {
        ...state,
        permissions: [],
        currentUser: null,
        permissionsErrorReason: action.reason,
      }

    default:
      return state
  }
}
