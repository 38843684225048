import moment from "moment"
import {useState} from "react"
import {FieldSpec, Values} from "src/components/filters/TableFilters"
import ResponsiveTable, {Column} from "src/components/ResponsiveTable"
import useDebounce from "src/features/checkIns/utils/useDebounce"
import {
  AuditLogItemType,
  AuditLogQueryVariables,
  AuditLogQuery,
  useAuditLogQuery,
} from "src/types/apollo"
import {removeNothings} from "../../../utils/nothingRemoval"
import {PropOptions} from "../types"
import {AuditLogModal} from "./AuditLogModal"

const PAGE_SIZE = 20
type AuditRow = AuditLogQuery["auditLog"]["results"][number]
const columns: Array<Column<AuditRow>> = [
  {
    key: "occurred",
    title: "Occurred",
    getValue: ({createdAt}) => moment(createdAt).format("Do MMM YYYY HH:mm:ss"),
  },
  {
    title: "Actor Email",
    getValue: ({user}) => user?.email || "",
  },
  {
    key: "userId",
    title: "Actor User ID",
    getValue: ({user}) => user?.id,
  },
  {
    key: "entityId",
    title: "Entity Id",
    getValue: ({entityId}) => entityId,
  },
  {
    key: "entityType",
    title: "Entity Type",
    getValue: ({entityType}) => entityType,
  },
  {
    title: "Entity Subsection",
    getValue: ({entitySubsection}) => entitySubsection,
  },
  {
    key: "type",
    title: "Type",
    getValue: ({type}) => type,
  },
]

const filterSpecification: {[key: string]: FieldSpec} = {
  userId: {
    type: "text",
    label: "Actor ID",
    dataCy: "audit-log-user-search",
  },
  occurred: {
    type: "datetimerange",
    // Map between the fields in state and the filter labels
    changeKeyFrom: "afterDate",
    changeKeyTo: "beforeDate",
  },
  type: {
    type: "select",
    label: "Audit log type",
    options: Object.values(AuditLogItemType),
    optionLabels: {
      [AuditLogItemType.Create]: "Creations",
      [AuditLogItemType.Update]: "Updates",
      [AuditLogItemType.Delete]: "Deletions",
    },
  },
  entityType: {
    type: "select",
    label: "Entity type",
    options: [
      "addon",
      "business-config",
      "entry-scheme",
      "api-key",
      "product",
      "product-ticket-types",
      "refund",
      "review-requirement",
      "schedule",
      "share",
      "ticket",
      "ticket-token",
      "ticket-type",
      "user",
      "visitor-type",
    ],
    optionLabels: {
      addon: "Addon",
      "business-config": "Configuration",
      "entry-scheme": "Entry Scheme",
      "api-key": "API Key",
      product: "Product",
      "product-ticket-types": "Product Ticket Types",
      refund: "Refund",
      "review-requirement": "Review Requirement",
      schedule: "Schedule",
      share: "Share",
      ticket: "Ticket",
      "ticket-token": "Ticket Token",
      "ticket-type": "Ticket Type",
      user: "User",
      "visitor-type": "Visitor Type",
    },
  },
  entityId: {
    type: "text",
    label: "Entity ID",
  },
}

interface TableProps {
  options: PropOptions
  showFilters?: boolean
  filterFieldsToHide?: [keyof PropOptions]
  columnsToHide?: [keyof PropOptions]
}

const FILTERS_DELAY = 500

export const AuditLogTable = ({
  options,
  showFilters = false,
  filterFieldsToHide,
  columnsToHide,
}: TableProps) => {
  const [selectedId, setSelectedId] = useState<string | null>(null)
  const [filters, setFilters] = useState<AuditLogQueryVariables>({
    ...options,
    afterDate: moment(options.afterDate).isValid() ? options.afterDate : null,
    beforeDate: moment(options.beforeDate).isValid()
      ? options.beforeDate
      : null,
    pagination: {
      limit: PAGE_SIZE,
    },
  })

  const debouncedEntityId = useDebounce(filters.entityId, FILTERS_DELAY)
  const debouncedUserId = useDebounce(filters.userId, FILTERS_DELAY)

  const {data, networkStatus, fetchMore} = useAuditLogQuery({
    variables: {
      ...filters,
      entityId: debouncedEntityId,
      userId: debouncedUserId,
    },
    fetchPolicy: "cache-and-network",
  })

  const loading = networkStatus === 1 || networkStatus === 2
  const loadingMore = networkStatus === 3
  const hasMore = (data && data.auditLog && data.auditLog.hasMore) || false
  const rows = data?.auditLog.results
    ? removeNothings(data?.auditLog.results)
    : undefined

  const selectedRow = rows?.find((row) => row.id === selectedId)

  return (
    <>
      <ResponsiveTable<AuditRow>
        dataCy="audit-log-table"
        data={rows}
        emptyMessage={"No results for the search criteria"}
        columns={columns.filter(
          (c) => !columnsToHide?.includes(c.key as keyof PropOptions),
        )}
        getKey={(row: AuditRow) => row.id}
        onClick={(row: AuditRow) => {
          setSelectedId(row.id)
        }}
        loading={loading}
        error={!loading && (!data || !data.auditLog)}
        hasMore={hasMore}
        loadingMore={loadingMore}
        onRequestLoadMore={() => {
          if (!data || !data.auditLog.forwardEdge || !data.auditLog.hasMore) {
            return
          }

          fetchMore({
            variables: {
              ...filters,
              pagination: {
                limit: PAGE_SIZE,
                after: data.auditLog.forwardEdge,
              },
            },
            updateQuery: (prev, {fetchMoreResult}) => {
              if (!fetchMoreResult || !fetchMoreResult.auditLog) {
                return prev
              }

              return {
                auditLog: {
                  ...fetchMoreResult.auditLog,
                  results: [
                    ...prev.auditLog.results,
                    ...fetchMoreResult.auditLog.results,
                  ],
                },
              }
            },
          })
        }}
        filters={
          showFilters
            ? {
                spec: filterSpecification,
                initialValues: {
                  ...filters,
                  occurred: {
                    start: filters.afterDate,
                    end: filters.beforeDate,
                  },
                } as unknown as Values<{
                  [key: string]: FieldSpec
                }>,
                filterFieldsToHide,
                onChange: (k, v) => setFilters({...filters, [k]: v}),
              }
            : undefined
        }
      />
      {selectedRow && (
        <AuditLogModal log={selectedRow} onClose={() => setSelectedId(null)} />
      )}
    </>
  )
}
