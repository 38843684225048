import {
  makeCompleteOnboardingRequest,
  makeLogInRequest,
  makeLogOutRequest,
  makeRegisterRequest,
} from "./networkRequests"
import {AuthAction, AuthState} from "../types"

import {ApolloError} from "@apollo/client"
import Bugsnag from "@bugsnag/js"
import {
  PermissionsAndCurrentUserDocument,
  PermissionsAndCurrentUserQuery,
  PermissionsAndCurrentUserQueryVariables,
} from "src/types/apollo"
import {buildApolloClient} from "src/apollo/client"

export function logIn({email, password}: {email: string; password: string}) {
  return async (
    dispatch: (action: AuthAction) => void,
    getState: () => {auth: AuthState},
  ) => {
    const {deviceId} = getState().auth
    const authResponse = await makeLogInRequest(email, password, deviceId)

    if (authResponse.success) {
      const {token, userId} = authResponse

      localStorage.setItem("authToken", token)
      localStorage.setItem("userId", userId)
      await getPermissionsAndCurrentUser(dispatch)

      dispatch({type: "LOG_IN_SUCCESS", token, userId})
    } else {
      alert(`An error occurred: ${authResponse.message}`)
    }
  }
}

export function logOut() {
  return async (_: unknown, getState: () => {auth: AuthState}) => {
    const {token, deviceId} = getState().auth
    await makeLogOutRequest(token as string, deviceId)

    Bugsnag.setUser(undefined, undefined)
    localStorage.clear()
    window.location.reload()
  }
}

export async function getPermissionsAndCurrentUser(
  dispatch: (action: AuthAction) => void,
) {
  const apolloClient = buildApolloClient()
  const response = await apolloClient
    .query<
      PermissionsAndCurrentUserQuery,
      PermissionsAndCurrentUserQueryVariables
    >({
      query: PermissionsAndCurrentUserDocument,
    })
    .catch((e: ApolloError) => {
      if (
        e.networkError != null &&
        // `statusCode` should be on the network error, but it's not typed
        "statusCode" in e.networkError &&
        e.networkError.statusCode === 403
      ) {
        dispatch({
          type: "USER_AND_PERMISSIONS_CHECK_FAILED",
          reason:
            "result" in e.networkError
              ? ((e.networkError.result as {error: true; type: string})?.type ??
                null)
              : null,
        })
        return
      }
      throw e
    })

  if (response == null) {
    return {}
  }

  const {id, email, associatedBusiness, permissions} = response.data.me
  const currentUser = {
    id,
    email,
    business: {
      id: associatedBusiness?.id ?? null,
      name: associatedBusiness?.companyName ?? null,
    },
  }

  dispatch({
    type: "LOAD_PERMISSIONS_AND_CURRENT_USER_SUCCESS",
    permissions,
    currentUser,
  })

  return {currentUser}
}

export function register({email, password}: {email: string; password: string}) {
  return async (
    dispatch: (action: AuthAction) => void,
    getState: () => {auth: AuthState},
  ) => {
    const {deviceId} = getState().auth
    const authResponse = await makeRegisterRequest(email, password, deviceId)

    if (authResponse.success) {
      const {token, userId} = authResponse

      localStorage.setItem("authToken", token)
      localStorage.setItem("userId", userId)
      await getPermissionsAndCurrentUser(dispatch)

      dispatch({type: "LOG_IN_SUCCESS", token, userId})
    } else {
      alert(`An error occurred: ${authResponse.message}`)
    }
  }
}

export function completeOnboarding({
  password,
  token,
}: {
  password: string
  token: string
}) {
  return async (
    dispatch: (action: AuthAction) => void,
    getState: () => {auth: AuthState},
  ) => {
    const {deviceId} = getState().auth
    const authResponse = await makeCompleteOnboardingRequest({
      password,
      deviceId,
      token,
    })

    if (authResponse.success) {
      const {token, userId} = authResponse

      localStorage.setItem("authToken", token)
      localStorage.setItem("userId", userId)
      await getPermissionsAndCurrentUser(dispatch)

      dispatch({type: "LOG_IN_SUCCESS", token, userId})
    } else {
      alert(`An error occurred: ${authResponse.message}`)
    }
  }
}
