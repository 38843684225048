import React from "react"

import {Link} from "react-router-dom"
import VisibilitySensor from "react-visibility-sensor"
import styled from "styled-components"

import BusyButton from "src/components/BusyButton"
import ErrorBox from "src/components/ErrorBox"
import {useWindowWidth} from "../hooks/index"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableFooter from "@mui/material/TableFooter"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import {Fade, LinearProgress, Typography} from "@mui/material"
import * as R from "ramda"
import {FieldSpec, FilterCell, Values} from "./filters/TableFilters"
import {TFunction} from "i18next"
import {useTranslation} from "react-i18next"

type ColumnValue = string | React.ReactNode

export interface Column<T> {
  key?: string
  title: string
  getValue: (
    item: T,
    t: TFunction<"translation", undefined>,
  ) => ColumnValue | Array<ColumnValue>
}

interface Props<T, U extends {[key: string]: FieldSpec}> {
  columns: Array<Column<T>>
  filters?: {
    spec: U
    initialValues: Values<U>
    filterFieldsToHide?: [keyof U]
    onChange: <K extends keyof U>(key: K, value: Values<U>[K]) => void
  }
  data?: T[]
  hasMore: boolean

  loading: boolean
  error: boolean
  errorMessage?: React.ReactNode
  dataCy?: string

  loadingMore: boolean
  onRequestLoadMore: () => void
  getKey: (item: T) => string
  getLink?: (item: T) => string | undefined
  onClick?: (item: T) => void
  getRowStyle?: (item: T) => React.CSSProperties | undefined
  emptyMessage?: React.ReactNode
}

export default function ResponsiveTable<T>(
  props: Props<T, {[key: string]: FieldSpec}>,
) {
  const {t} = useTranslation()
  const {
    data,
    hasMore,
    loading,
    error,
    errorMessage,
    loadingMore,
    onRequestLoadMore,
    emptyMessage,
    columns,
    getKey,
    getLink,
    onClick,
    getRowStyle,
    dataCy,
    filters,
  } = props

  const isSmallerScreen = useWindowWidth() <= 960

  const renderCell = (value: ColumnValue | Array<ColumnValue>) => {
    if (Array.isArray(value)) {
      return value.map((v, i) => (
        <p key={i} style={{margin: 0}}>
          {v}
        </p>
      ))
    }

    return value
  }

  const getDesktopTable = () => {
    return (
      <Table data-cy={dataCy}>
        {getTableHeader()}
        <TableBody>
          {(data || []).map((row, _i) => {
            const link = getLink && getLink(row)

            const rowStyle = getRowStyle && getRowStyle(row)

            return (
              <TableRow key={getKey(row)} style={rowStyle}>
                {R.pluck("getValue", columns).map((getValue, j) => {
                  const cell = renderCell(getValue(row, t))
                  return (
                    <TableCell key={j}>
                      {link ? (
                        <Link
                          to={link}
                          style={{textDecoration: "none", color: "inherit"}}
                        >
                          {cell}
                        </Link>
                      ) : onClick !== undefined ? (
                        <div
                          onClick={() => onClick(row)}
                          style={{cursor: "pointer"}}
                        >
                          {cell}
                        </div>
                      ) : (
                        cell
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
        <TableFooter>
          {hasMore ? (
            <VisibilitySensor
              key={(data || []).length}
              onChange={(isVisible: boolean) => {
                if (isVisible && !loadingMore) {
                  onRequestLoadMore()
                }
              }}
            >
              {() => (
                <TableRow>
                  <TableCell>
                    <BusyButton
                      busy={loadingMore}
                      variant="text"
                      onClick={() => {
                        if (!loadingMore) {
                          onRequestLoadMore()
                        }
                      }}
                    >
                      Load more
                    </BusyButton>
                  </TableCell>
                </TableRow>
              )}
            </VisibilitySensor>
          ) : null}
        </TableFooter>
      </Table>
    )
  }

  const getResponsiveTable = () => {
    return (
      <Table>
        <TableBody>
          {(data || []).map((row, _i) => {
            const link = getLink && getLink(row)

            return (
              <React.Fragment key={getKey(row)}>
                {columns.map((col, j) => {
                  const cell = renderCell(col.getValue(row, t))
                  return (
                    <TableRow key={j}>
                      <TableCell component="th">{col.title}</TableCell>
                      <TableCell>
                        {link ? (
                          <Link
                            to={link}
                            style={{textDecoration: "none", color: "inherit"}}
                          >
                            {cell}
                          </Link>
                        ) : onClick !== undefined ? (
                          <div
                            onClick={() => onClick(row)}
                            style={{cursor: "pointer"}}
                          >
                            {cell}
                          </div>
                        ) : (
                          cell
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
                <SpacerRow />
              </React.Fragment>
            )
          })}
        </TableBody>
        <TableFooter>
          {hasMore ? (
            <VisibilitySensor
              key={(data || []).length}
              onChange={(isVisible: boolean) => {
                if (isVisible && !loadingMore) {
                  onRequestLoadMore()
                }
              }}
            >
              {() => (
                <BusyButton
                  busy={loadingMore}
                  style={{margin: "24px auto", display: "block"}}
                  onClick={() => {
                    if (!loadingMore) {
                      onRequestLoadMore()
                    }
                  }}
                >
                  Load more
                </BusyButton>
              )}
            </VisibilitySensor>
          ) : null}
        </TableFooter>
      </Table>
    )
  }

  const getTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          {R.pluck("title", columns).map((title, i) => (
            <TableCell key={i}>{title}</TableCell>
          ))}
        </TableRow>
        {filters && (
          <TableRow>
            {R.pluck("key", columns).map((key, i) => {
              return key != null && filters.spec[key] ? (
                <TableCell key={i}>
                  <FilterCell
                    fieldKey={key}
                    item={filters.spec[key]}
                    value={filters.initialValues[key]}
                    hidden={!!filters.filterFieldsToHide?.includes(key)}
                    onChange={(changeKey, newValue) =>
                      filters.onChange(changeKey ?? key, newValue)
                    }
                  />
                </TableCell>
              ) : (
                <TableCell key={i}></TableCell>
              )
            })}
          </TableRow>
        )}
        <TableRow data-cy={loading ? "table-loading" : undefined}>
          <TableCell
            sx={{padding: 0, borderBottom: "none"}}
            colSpan={columns.length}
          >
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? "800ms" : "0ms",
              }}
            >
              <LinearProgress />
            </Fade>
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  return (
    <Container isLoading={loading}>
      {error ? <ErrorBox error={errorMessage} /> : null}
      {data && data.length === 0 && filters ? (
        <Table>
          {getTableHeader()}
          <TableRow>
            <TableCell
              sx={{paddingTop: 8, paddingBottom: 8}}
              align="center"
              colSpan={columns.length}
            >
              <Typography>{emptyMessage}</Typography>
            </TableCell>
          </TableRow>
        </Table>
      ) : data && data.length === 0 ? (
        <Row>{emptyMessage}</Row>
      ) : isSmallerScreen ? (
        getResponsiveTable()
      ) : (
        getDesktopTable()
      )}
    </Container>
  )
}

const Container = styled.div<{isLoading: boolean}>`
  opacity: ${({isLoading}) => (isLoading ? 0.5 : 1)};
  transition: opacity 0.1s;
  overflow-x: auto;
  margin-bottom: 28px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: 48px;
  justify-content: space-evenly;
`
const SpacerRow = styled(TableRow)`
  margin-bottom: 50px;
  display: block;
`
