import {lazy} from "react"
import {RouteInfo} from "src/types"
import TicketPage from "./pages/TicketPage"
import TicketSearchPage from "./pages/TicketSearchPage"

export const routeInfo: RouteInfo[] = [
  {
    path: "/tickets",
    component: TicketSearchPage,
  },
  {
    path: "/tickets/:id",
    component: TicketPage,
  },
  {
    path: "/tickets/new",
    component: lazy(() => import("./pages/TicketCreatePage")),
  },
  {
    path: "/tickets/:id/edit",
    component: lazy(() => import("./pages/TicketEditPage")),
  },
  {
    path: "/ticket-stats",
    component: lazy(() => import("./pages/TicketStatsPage")),
  },
  {
    path: "/ticket-insights",
    component: lazy(() => import("./pages/TicketInsightsPage")),
  },
  {
    path: "/api-keys",
    component: lazy(() => import("./pages/ApiKeysPage")),
  },
  {
    path: "/ticket-codes",
    component: lazy(() => import("./pages/TicketTokensPage")),
  },
  {
    path: "/ticket-codes/:id",
    component: lazy(() => import("./pages/TicketTokenDetailPage")),
  },
  {
    path: "/ticket-codes/batch",
    component: lazy(() => import("./pages/TicketTokenBatchesPage")),
  },
  {
    path: "/ticket-codes/gatehouse",
    component: lazy(() => import("./pages/BabylonstorenTicketOfficeKioskPage")),
  },
  {
    path: "/timeslots",
    component: lazy(() => import("./pages/TimeslotsOverviewPage")),
  },
]
