import FilterListIcon from "@mui/icons-material/FilterList"
import {FormControl, FormLabel, InputAdornment} from "@mui/material"
import TextField from "@mui/material/TextField"
import React from "react"
import styled from "styled-components"

interface Props {
  value: string | null
  onChange: (newValue: string | null) => void
  label?: string
  isTableFilter?: boolean
  dataCy?: string
}

export default class TextFilter extends React.PureComponent<Props> {
  public render() {
    const {value, onChange, label, isTableFilter, dataCy} = this.props

    if (isTableFilter) {
      return (
        <FormControl variant="standard">
          <TextField
            variant="standard"
            value={value || ""}
            data-cy={dataCy}
            placeholder={"Contains"}
            sx={{
              minWidth: "100px",
              ".MuiInputBase-input": {fontSize: "0.75rem"},
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FilterListIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => onChange(e.target.value || null)}
          />
        </FormControl>
      )
    }

    return (
      <Container>
        <FormLabel>{label}</FormLabel>
        <TextField
          value={value || ""}
          onChange={(e) => onChange(e.target.value || null)}
          data-cy={dataCy}
        />
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
