import {useState} from "react"

import {Typography} from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

import {
  GetTicketQuery,
  Ticket,
  useUpdateTicketTimeslotMutation,
} from "src/types/apollo"
import BusyButton from "src/components/BusyButton"
import {Alert} from "@mui/material"
import TicketTimeslotSelector from "./TicketTimeslotSelector"
import moment from "moment"
import {useTranslation} from "react-i18next"

interface Props {
  modalOpen: boolean
  toggleModal: () => void
  ticket: Ticket
  schedules: NonNullable<
    NonNullable<GetTicketQuery["tickets"][number]>["product"]["schedules"]
  >
  onSuccess: () => void
}

export default function UpdateTicketTimeslotModal({
  modalOpen,
  ticket,
  schedules,
  toggleModal,
  onSuccess,
}: Props) {
  const {t} = useTranslation()
  const [timeslotStartsAt, setTimeslotStartsAt] = useState<string | null>(
    moment(ticket.startsAt).format("l LTS"),
  )

  const [updateTicketExpiry, {loading}] = useUpdateTicketTimeslotMutation({
    refetchQueries: ["GetTicket"],
  })

  return (
    <Dialog onClose={toggleModal} open={modalOpen} fullWidth>
      <DialogTitle>
        <Typography variant="h6">
          {t("features.tickets.timeslot.update")}
        </Typography>
        <div style={{position: "absolute", top: "8px", right: "8px"}}>
          <IconButton onClick={toggleModal} size="large">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <div style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 16}}>
        {ticket.checkins.results.length > 0 && (
          <Alert severity="warning" style={{marginBottom: 24}}>
            {t("features.tickets.timeslot.ticketAlreadyUsed")}
          </Alert>
        )}

        <div style={{overflowY: "scroll", maxHeight: 500}}>
          <TicketTimeslotSelector
            schedules={schedules}
            timeslotStartsAt={timeslotStartsAt}
            setTimeslotStartsAt={setTimeslotStartsAt}
          />
        </div>

        <div style={{marginTop: 16}}>
          <BusyButton
            color="primary"
            variant="contained"
            busy={loading}
            disabled={timeslotStartsAt == null}
            onClick={async () => {
              if (timeslotStartsAt == null) {
                return
              }

              const res = await updateTicketExpiry({
                variables: {
                  id: ticket.id,
                  timeslotStartsAt,
                },
              })

              if (res?.data) {
                onSuccess()
              }
            }}
          >
            {t("actions.update")}
          </BusyButton>
        </div>
      </div>
    </Dialog>
  )
}
