import {PaletteMode} from "@mui/material"
import {createTheme} from "@mui/material/styles"
import {useMemo} from "react"

export default (mode: PaletteMode) =>
  useMemo(
    () =>
      createTheme(
        mode === "light"
          ? {
              palette: {
                mode,
                primary: {
                  main: "#1F3554",
                },
                secondary: {
                  main: "#f50056",
                },
                warning: {
                  main: "#D03100",
                },
                error: {
                  main: "#D0011B",
                },
              },
            }
          : {
              palette: {
                mode,
                primary: {
                  main: "#FFFFFF",
                },
                secondary: {
                  main: "#f50056",
                },
                warning: {
                  main: "#D03100",
                },
                error: {
                  main: "#D0011B",
                },
              },
            },
      ),
    [mode],
  )
