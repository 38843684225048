import {CheckinAttendanceInput} from "src/types"

export type VisitorTypesAttendance = {[vId: string]: number}
export type TicketsAttendance = {[tId: string]: VisitorTypesAttendance}

export const visitorTypeAttendanceToGqlInput = (
  state: VisitorTypesAttendance,
): Array<CheckinAttendanceInput> =>
  Object.entries(state).map(([visitorTypeId, quantity]) => ({
    visitorTypeId,
    quantity,
  }))
