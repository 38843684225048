import {Route, Routes} from "react-router-dom"
import {useHideFreshchat} from "src/hooks/useHideFreshchat"
import T from "../../../textResources"
import ForgotPasswordForm from "../components/ForgotPasswordForm"
import FullscreenPaperContainer from "../components/FullscreenPaperContainer"
import LoginForm from "../components/LoginForm"
import ResetPasswordForm from "../components/ResetPasswordForm"
import {CompleteOnboardingForm} from "../components/CompleteOnboardingForm"

export default function AuthRouter() {
  useHideFreshchat()

  return (
    <FullscreenPaperContainer title={T.auth.loginPage.title}>
      <Routes>
        <Route path="*" Component={LoginForm} />
        <Route path="/register" Component={LoginForm} />
        <Route path="/onboard/:token" Component={CompleteOnboardingForm} />
        <Route path="/reset-password/:token" Component={ResetPasswordForm} />
        <Route path="/forgot-password" Component={ForgotPasswordForm} />
      </Routes>
    </FullscreenPaperContainer>
  )
}
