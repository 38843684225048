import moment from "moment"
import * as R from "ramda"
import {getVisitorTypePluralGrammar} from "../../checkIns/utils/getVisitorTypePluralGrammar"

import ResponsiveTable, {Column} from "src/components/ResponsiveTable"

import {useTranslation} from "react-i18next"
import {GetTicketQuery} from "src/types/apollo"

interface Props {
  ticket: NonNullable<GetTicketQuery["tickets"][number]>
}
type GetTicket_tickets_checkins_results = NonNullable<
  GetTicketQuery["tickets"][number]
>["checkins"]["results"][number]
export default function CheckinHistory(props: Props) {
  const {t} = useTranslation()
  const columns: Array<Column<GetTicket_tickets_checkins_results>> = [
    {
      title: t("features.tickets.checkin.history.time"),
      getValue: (c) => {
        return moment(c.checkinTime).format("lll")
      },
    },
    {
      title: t("features.tickets.checkin.history.attendance"),
      getValue: (c) => {
        const attendance = R.sortBy(R.prop("quantity"), c.attendance).map(
          (a) => {
            const grammar =
              a.quantity === 1
                ? a.visitorType.name
                : getVisitorTypePluralGrammar(a.visitorType.name)
            return `${a.quantity} ${grammar}`
          },
        )

        return attendance.join(", ")
      },
    },
  ]

  return (
    <ResponsiveTable
      columns={columns}
      data={props.ticket.checkins.results}
      loading={false}
      error={false}
      emptyMessage={t("features.tickets.checkin.history.none")}
      hasMore={false}
      loadingMore={false}
      getKey={(row: GetTicket_tickets_checkins_results) => row.id}
      onRequestLoadMore={() => {}}
    />
  )
}
