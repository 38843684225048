import {AUTH_URL} from "../../../../config"
import {LoginFailureData, LoginSuccessData} from "./types"

export default async function (
  email: string,
  password: string,
  deviceId: string,
): Promise<LoginSuccessData | LoginFailureData> {
  try {
    const response = await fetch(`${AUTH_URL}/auth/login`, {
      method: "POST",
      body: JSON.stringify({deviceId, email, password}),
      headers: {
        "Content-Type": "application/json",
      },
    })

    switch (response.status) {
      case 200: {
        const responseBody = await response.json()
        return {
          success: true,
          token: responseBody.accessToken,
          userId: `${responseBody.user.id}`,
        }
      }

      case 401: {
        return {
          success: false,
          message:
            "Login invalid, please check your email/password and try again",
        }
      }

      default: {
        return {
          success: false,
          message: "An unknown error occurred!",
        }
      }
    }
  } catch (_e) {
    return {
      success: false,
      message: "An unknown error occurred",
    }
  }
}
