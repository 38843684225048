import {
  Box,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import {useState} from "react"
import BusyButton from "src/components/BusyButton"
import {GetTicketQuery, useRenewTicketMutation} from "src/types/apollo"
import CloseIcon from "@mui/icons-material/Close"
import ErrorDisplay from "src/components/ErrorDisplay"
import moment from "moment"

import styled from "styled-components"
import {UpdateTicketSelector} from "./UpdateTicketSelection"
import {TFunction} from "i18next"
import {useTranslation} from "react-i18next"

type Ticket = NonNullable<GetTicketQuery["tickets"][number]>
interface Props {
  modalOpen: boolean
  toggleModal: () => void
  ticket: Ticket
  onSuccess: (newTicketId: string) => void
}

function errorMessageToCode(
  errorCode: string,
  t: TFunction<"translation", undefined>,
): string {
  switch (errorCode) {
    case "TicketAlreadyRenewed":
      return t("features.tickets.renew.error.alreadyRenewed")
    case "AlreadyHasMembership":
      return t("features.tickets.renew.error.alreadyHasMembership")
    case "TicketNotInRenewalWindow":
      return t("features.tickets.renew.error.beforeRenewalWindow")
    default:
      return errorCode
  }
}

export default function RenewTicketModal({
  modalOpen,
  toggleModal,
  ticket,
  onSuccess,
}: Props) {
  const {t} = useTranslation()
  const theme = useTheme()

  const [ticketTypes, setTicketTypes] = useState<
    Array<Omit<Ticket["ticketTypes"][number], "id">>
  >(ticket.ticketTypes)
  const [productId, setProductId] = useState<string>(ticket.product.id)
  const [sendEmail, setSendEmail] = useState(true)

  const [renewTicket, {error, loading}] = useRenewTicketMutation()

  const renewMutationError = error?.graphQLErrors?.length
    ? errorMessageToCode(error.graphQLErrors[0].message, t)
    : null

  function getContent() {
    const renewThreshold = moment(ticket.expiresAt).add(18, "months").format()
    const now = new Date().toISOString()

    if (now > renewThreshold) {
      return <Typography>{t("features.tickets.renew.error.tooOld")}</Typography>
    }

    const expires = moment(ticket.expiresAt)
    const renewalStart = moment().isAfter(expires)
      ? moment()
      : expires.add(1, "day")
    return (
      <Stack direction="column" spacing={2}>
        <ModalMessage>
          <Typography
            sx={{
              display: "inline",
              fontWeight: 800,
              color: theme.palette.warning.main,
            }}
          >
            {t("features.tickets.renew.explain", {
              datetime: renewalStart.startOf("day").format("LLLL"),
            })}
            .
          </Typography>
        </ModalMessage>

        {renewMutationError && (
          <ModalMessage>
            <ErrorDisplay>{renewMutationError}</ErrorDisplay>
          </ModalMessage>
        )}

        <UpdateTicketSelector
          setTicketTypes={setTicketTypes}
          productId={productId}
          setProductId={setProductId}
          initialTicketTypes={ticket.ticketTypes}
          subscriptionData={{
            stripeSubscriptionId:
              (ticket.paymentData?.__typename ===
                "TicketPaymentDataSubscription" &&
                ticket.paymentData.subscription.subscriptionId) ||
              null,
            renewalIsCancelled:
              ticket.paymentData?.__typename ===
                "TicketPaymentDataSubscription" &&
              ticket.paymentData.renewalIsCancelled,
          }}
        />

        {ticket.email != null && (
          <Box>
            <Tooltip
              title={t("features.tickets.renew.sendEmailTooltip", {
                email: ticket.email,
              })}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name={t("features.tickets.renew.sendEmailLabel")}
                    checked={sendEmail}
                    onChange={(e) => setSendEmail(e.target.checked)}
                  />
                }
                label={t("features.tickets.renew.sendEmailLabel")}
              />
            </Tooltip>
          </Box>
        )}

        <BusyButton
          variant="contained"
          onClick={async () => {
            if (ticket === null) {
              return
            }

            try {
              const result = await renewTicket({
                variables: {
                  ticketId: ticket.id,
                  productId,
                  ticketTypes: ticketTypes.map(({ticketType, visitors}) => ({
                    ticketTypeId: ticketType.id,
                    visitors: visitors.map(
                      ({visitorType, firstName, lastName, externalUserId}) => ({
                        visitorTypeId: visitorType.id,
                        firstName,
                        lastName,
                        externalUserId,
                      }),
                    ),
                  })),
                  sendEmail,
                },
              })

              if (result && result.data) {
                onSuccess(result.data.renewTicket.id)
              }
            } catch (_e) {}
          }}
          color="primary"
          busy={loading}
          disabled={ticketTypes.length === 0}
        >
          {t(
            `features.tickets.renew.action.${
              sendEmail ? "sendEmail" : "dontSendEmail"
            }`,
          )}
        </BusyButton>
      </Stack>
    )
  }

  return (
    <Dialog open={modalOpen} onClose={toggleModal} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography variant="h6">
          {t("features.tickets.renew.ticket")}
        </Typography>
        <div style={{position: "absolute", top: "8px", right: "8px"}}>
          <IconButton onClick={toggleModal} size="large">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <div style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 16}}>
        {getContent()}
      </div>
    </Dialog>
  )
}

const ModalMessage = styled.div`
  padding-bottom: 16px;
`
