import {lazy} from "react"
import {RouteInfo} from "src/types"

export const routeInfo: RouteInfo[] = [
  {
    path: "/setup-config",
    component: lazy(() => import("./pages/BusinessConfigPage")),
  },
  {
    path: "/setup-config/edit",
    component: lazy(() => import("./pages/BusinessConfigEditPage")),
  },
  {
    path: "/setup-products",
    component: lazy(() => import("./pages/ProductListPage")),
  },
  {
    path: "/setup-products/new",
    component: lazy(() => import("./pages/ProductCreatePage")),
  },
  {
    path: "/setup-products/:id",
    component: lazy(() => import("./pages/ProductPage")),
  },
  {
    path: "/setup-products/:id/edit",
    component: lazy(() => import("./pages/ProductEditPage")),
  },
  {
    path: "/setup-users",
    component: lazy(() => import("../users/pages/UserAssociationPage")),
  },
  {
    path: "/setup-users/onboard",
    component: lazy(() => import("./pages/OnboardPage")),
  },
]
