import {FormLabel, ListItemText, Typography} from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import React from "react"
import styled from "styled-components"

const EMPTY_VALUE = "EMPTY_VALUE"

interface Props {
  value: string | null
  onChange: (newValue: string | null) => void
  label?: string
  emptyOptionLabel?: string
  options: string[]
  optionLabels?: Record<string, string | undefined>
  hideEmptyOption?: boolean
  isTableFilter?: boolean
}

export default class SelectFilter extends React.PureComponent<Props> {
  public render() {
    const {
      value,
      onChange,
      label,
      options,
      optionLabels,
      emptyOptionLabel,
      hideEmptyOption,
      isTableFilter,
    } = this.props

    const emptyText =
      emptyOptionLabel === undefined ? "No value" : emptyOptionLabel
    return (
      <Container>
        {isTableFilter ? null : <FormLabel>{label}</FormLabel>}
        <Select
          value={value || EMPTY_VALUE}
          variant={isTableFilter ? "standard" : undefined}
          displayEmpty={hideEmptyOption}
          onChange={(e) =>
            onChange(
              e.target.value === EMPTY_VALUE
                ? null
                : (e.target.value as string),
            )
          }
        >
          {hideEmptyOption === true ? null : (
            <MenuItem value={EMPTY_VALUE}>
              {isTableFilter ? (
                <Typography variant="caption">{emptyText}</Typography>
              ) : (
                emptyText
              )}
            </MenuItem>
          )}
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {isTableFilter ? (
                <Typography variant="caption">
                  {(optionLabels && optionLabels[option]) || option}
                </Typography>
              ) : (
                <ListItemText>
                  {(optionLabels && optionLabels[option]) || option}
                </ListItemText>
              )}
            </MenuItem>
          ))}
        </Select>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
