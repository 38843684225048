import React from "react"

import {DiffPatcher, formatters} from "jsondiffpatch"
import "jsondiffpatch/dist/formatters-styles/html.css"
import "jsondiffpatch/dist/formatters-styles/annotated.css"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"

const diffContainerStyle = {
  minHeight: 20,
  whiteSpace: "pre-wrap",
  overflowWrap: "break-word",
  overflowY: "auto",
  backgroundColor: "#eee",
  color: "black",
  padding: 16,
  margin: 0,
} as React.CSSProperties

interface Props {
  oldData: any
  newData: any
  maxHeight?: string
}

class DiffDisplay extends React.PureComponent<{oldData: any; newData: any}> {
  public render() {
    const patcher = new DiffPatcher()
    const delta = patcher.diff(this.props.oldData, this.props.newData)
    const html = delta ? formatters.html.format(delta, undefined) : undefined

    return html ? (
      <div
        dangerouslySetInnerHTML={{__html: html}}
        style={{marginLeft: -16, marginRight: -16}}
      />
    ) : (
      <div>{"There are no changes..."}</div>
    )
  }
}

interface State {
  tab: "diff" | "old" | "new"
}

export default class JsonDiff extends React.PureComponent<Props, State> {
  public state: State = {
    tab: "diff",
  }

  public render() {
    const {oldData, newData, maxHeight} = this.props
    const {tab} = this.state

    const od = JSON.parse(oldData)
    const nd = JSON.parse(newData)
    return (
      <div>
        <Tabs
          value={tab}
          onChange={(_: any, newTab: any) => this.setState({tab: newTab})}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Diff" value="diff" />
          <Tab label="Old data" value="old" disabled={!oldData} />
          <Tab label="New data" value="new" disabled={!newData} />
        </Tabs>
        <pre style={{...diffContainerStyle, maxHeight}}>
          {tab === "diff" ? <DiffDisplay oldData={od} newData={nd} /> : null}
          {tab === "old" ? JSON.stringify(od, null, 2) : null}
          {tab === "new" ? JSON.stringify(nd, null, 2) : null}
        </pre>
      </div>
    )
  }
}
