import {AUTH_URL} from "../../../../config"

export default async function (newPassword: string, token: string) {
  const response = await fetch(`${AUTH_URL}/auth/reset-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({newPassword, token}),
  }).catch(() => null)

  if (response == null) {
    return {
      success: false,
      message: "An unknown error occurred!",
    }
  }

  if (response.status === 400) {
    const body: {
      type:
        | "RESET_TOKEN_EXPIRED"
        | "RESET_TOKEN_INVALID"
        | "PASSWORD_VALIDATION_ERROR"
      description: string
    } = await response.json()

    switch (body.type) {
      case "RESET_TOKEN_EXPIRED":
        return {
          success: false,
          message: "This reset link has expired!",
        }
      case "RESET_TOKEN_INVALID":
        return {
          success: false,
          message: "This reset link is invalid!",
        }
      case "PASSWORD_VALIDATION_ERROR":
        return {
          success: false,
          message: body.description,
        }
    }
  } else {
    return {
      success: true,
      message: "Password reset successfully!",
    }
  }
}
