import {Person, Shop} from "@mui/icons-material"
import {
  Card,
  CardContent,
  Container,
  Link as MuiLink,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material"
import * as R from "ramda"
import {useTranslation} from "react-i18next"
import {useParams} from "react-router-dom"
import ResponsiveTable from "src/components/ResponsiveTable"
import {getTicketColumns} from "src/features/tickets/pages/TicketSearchPage"
import withBusiness from "src/features/withBusiness"
import {
  GetTicketsForCustomerQuery,
  useGetTicketsForCustomerQuery,
} from "src/types/apollo"
import {removeNothings} from "src/utils/nothingRemoval"
import Page from "../../../components/Page"

function CustomerPage({businessId}: {businessId: string}) {
  const {t} = useTranslation()
  const {customerId} = useParams<{
    customerId?: string
  }>()
  const {data, loading, error} = useGetTicketsForCustomerQuery({
    variables: {
      ...(customerId != null ? {customerId: [customerId]} : {}),
    },
  })

  if (loading) {
    return <Page loading />
  }

  if (error || !data) {
    return <Page error={error || "No data"} />
  }

  const matchingVisitors = data.tickets
    .flatMap((t) =>
      t?.ticketTypes.flatMap((tt) =>
        tt.visitors.filter((v) => v.externalUserId === customerId),
      ),
    )
    .filter((x) => !!x)

  const names = R.uniq(
    matchingVisitors.map((v) => `${v?.firstName} ${v?.lastName}`),
  )
    .filter((x) => !!x)
    .join(", ")

  return (
    <Page
      title={customerId ? `${t("features.users.customer")} ${customerId}` : ""}
    >
      <Container maxWidth="lg">
        <Card style={{marginBottom: "12px"}}>
          <CardContent>
            <Typography variant="h5">{t("features.users.customer")}</Typography>
            <List>
              {customerId && (
                <ListItem>
                  <ListItemIcon>
                    <Shop />
                  </ListItemIcon>
                  {businessId &&
                  data.tickets.length > 0 &&
                  data.tickets[0]?.business.externalUserSystemBaseUrl ? (
                    <ListItemText>
                      {" "}
                      {customerId} (
                      <MuiLink
                        underline="always"
                        href={`${data.tickets[0]?.business.externalUserSystemBaseUrl}/${customerId}`}
                      >
                        {t("features.users.ecommerce").toLocaleLowerCase()}
                      </MuiLink>
                      )
                    </ListItemText>
                  ) : (
                    <ListItemText>{customerId}</ListItemText>
                  )}
                </ListItem>
              )}

              {names.length > 0 && (
                <ListItem>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText>{names}</ListItemText>
                </ListItem>
              )}
            </List>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography variant="h5">{t("features.users.tickets")}</Typography>
            {data.tickets.length && (
              <ResponsiveTable
                data={removeNothings(data.tickets)}
                emptyMessage={t("features.users.noTicketsForUser")}
                columns={getTicketColumns()}
                loading={loading}
                error={!loading && !data}
                hasMore={false}
                loadingMore={false}
                getLink={getLink}
                getKey={getKey}
                onRequestLoadMore={() => null}
              />
            )}
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

type TicketSearchResults_ticketSearch_tickets = NonNullable<
  GetTicketsForCustomerQuery["tickets"][number]
>
const getLink = (row: TicketSearchResults_ticketSearch_tickets) =>
  `/tickets/${row?.id}`
const getKey = (row: TicketSearchResults_ticketSearch_tickets) => row?.id

export default withBusiness(CustomerPage)
