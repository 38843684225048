import {lazy} from "react"
import {RouteInfo} from "src/types"

export const routeInfo: RouteInfo[] = [
  {
    path: "/check-in",
    component: lazy(() => import("./pages/CheckinVisitorPage")),
  },
  {
    path: "/check-in/self",
    component: lazy(() => import("./controllers/SelfCheckin")),
  },
  {
    path: "/checkin-stats",
    component: lazy(() => import("./pages/CheckinStatsPage")),
  },
  {
    path: "/checkin-insights",
    component: lazy(() => import("./pages/CheckinInsightsPage")),
  },
]
