import {encode} from "base-64"

function encodeTwoWords(string1: string, string2: string): string {
  return encode(`${string1}:${string2}`)
}

export function getAuthorizationHeader(
  string1?: string | null,
  string2?: string | null,
) {
  if (string1 && string2) {
    return `Basic ${encodeTwoWords(string1, string2)}`
  }
  return null
}

export default function generateHeader(authObj?: {
  string1: string
  string2: string
}) {
  const headers: any = {
    Accept: "application/json",
    "Content-Type": "application/json",
  }
  if (!authObj) {
    return headers
  }
  headers.Authorization = getAuthorizationHeader(
    authObj.string1,
    authObj.string2,
  )
  return headers
}
