import {useState} from "react"
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import {DeleteTicketWhen, useDeleteTicketMutation} from "src/types/apollo"
import styled from "styled-components"

interface Props {
  ticketId: string
  expiresAt: string
  deletedAt: string | null
  onMarkedForDeletionFailed: (error: string) => void
  onMarkedForDeletionSucceeded: (successMessage: string) => void
}

const ConfirmModal = ({
  open,
  handleClose,
  handleConfirm,
}: {
  open: boolean
  handleClose: () => void
  handleConfirm: (now?: boolean) => void
}) => {
  const [expireNow, setExpireNow] = useState(false)
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        <Typography variant="h6">Are you sure?</Typography>
        <div style={{position: "absolute", top: "8px", right: "8px"}}>
          <IconButton onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <div style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 16}}>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={expireNow}
                onChange={() => setExpireNow((e) => !e)}
                name="expireNow"
              />
            }
            label="Expire now?"
          />
        </div>

        <Spacer />
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => handleConfirm(expireNow)}
        >
          Delete {expireNow ? " now" : " on expiry"}
        </Button>
      </div>
    </Dialog>
  )
}

export const DeleteTicketButton = ({
  ticketId,
  deletedAt,
  onMarkedForDeletionFailed,
  onMarkedForDeletionSucceeded,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const [markedForDeletion, {error, loading}] = useDeleteTicketMutation({
    refetchQueries: ["GetTicket"],
  })

  if (error && error.graphQLErrors && error.graphQLErrors.length) {
    onMarkedForDeletionFailed(error.graphQLErrors[0].message)
  }

  const ModalOrUndoButton = deletedAt ? (
    <Button
      size="small"
      color="secondary"
      disabled={loading}
      onClick={async () => {
        try {
          const result = await markedForDeletion({
            variables: {
              id: ticketId,
              when: null,
            },
          })

          if (result && result.data) {
            onMarkedForDeletionSucceeded("Ticket deletion cancelled")
          }
        } catch (_e) {
          onMarkedForDeletionFailed("An unknown error occurred")
        }
      }}
    >
      Undo Delete
    </Button>
  ) : (
    <Button
      size="small"
      color="secondary"
      disabled={loading}
      onClick={() => setOpen(true)}
    >
      Delete
    </Button>
  )

  return (
    <>
      {ModalOrUndoButton}
      <ConfirmModal
        open={open}
        handleClose={() => setOpen(false)}
        handleConfirm={async (now?: boolean) => {
          try {
            const result = await markedForDeletion({
              variables: {
                id: ticketId,
                when: now
                  ? DeleteTicketWhen.Immediately
                  : DeleteTicketWhen.OnExpiry,
              },
            })

            if (result && result.data) {
              onMarkedForDeletionSucceeded(
                now ? "Ticket deleted" : "Ticket will be deleted on expiry",
              )
              setOpen(false)
            }
          } catch (_e) {
            onMarkedForDeletionFailed("An unknown error occurred")
          }
        }}
      />
    </>
  )
}

const Spacer = styled.div`
  height: 16px;
`
