import {FormLabel} from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import React from "react"

interface Props {
  value: string[]
  onChange: (newValue: string[]) => void
  label?: string
  options: string[]
  optionLabels?: Record<string, string | undefined>
  isTableFilter?: boolean
}

export default class CheckboxesFilter extends React.PureComponent<Props> {
  public render() {
    const {value, onChange, options, optionLabels, isTableFilter} = this.props

    return (
      <div>
        {options.map((option) => (
          <div>
            {isTableFilter ? null : (
              <FormLabel>{(optionLabels || {})[option] || option}</FormLabel>
            )}
            <Checkbox
              checked={value.includes(option)}
              onChange={() => {
                if (value.includes(option)) {
                  return onChange(value.filter((x) => x !== option))
                }

                return onChange(value.concat([option]))
              }}
            />
          </div>
        ))}
      </div>
    )
  }
}
