import React from "react"
import BusyButton from "./BusyButton"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import RenderBlocker from "./RenderBlocker"
import styled from "styled-components"

interface Props {
  title: string
  open: boolean
  onClose: () => void
  closeButtonText: string
  children: any
  actionButtonHandler?: () => void
  actionButtonText?: string
  actionButtonDisabled?: boolean
  busy?: boolean
  otherActions?: React.ReactNode
  maxWidth?: "xs" | "sm" | "md" | false
}

export default function Modal(props: Props) {
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={props.maxWidth}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent
        style={{minWidth: 250, display: "flex", flexDirection: "column"}}
      >
        <RenderBlocker shouldUpdate={props.open}>
          {props.open ? props.children : null}
        </RenderBlocker>
      </DialogContent>
      <DialogActions>
        {props.otherActions}
        <Spacer />
        {props.actionButtonHandler && props.actionButtonText ? (
          <BusyButton
            color="primary"
            autoFocus
            variant="contained"
            busy={props.busy}
            disabled={props.actionButtonDisabled}
            onClick={props.actionButtonHandler}
          >
            {props.actionButtonText}
          </BusyButton>
        ) : null}
        <Button onClick={props.onClose}>{props.closeButtonText}</Button>
      </DialogActions>
    </Dialog>
  )
}

const Spacer = styled.div`
  flex-grow: 1;
`
