import {
  Stack,
  Box,
  Button,
  Typography,
  CardHeader,
  Checkbox,
  Alert,
  AlertTitle,
} from "@mui/material"
import * as R from "ramda"
import BusyButton from "src/components/BusyButton"
import ErrorDisplay from "src/components/ErrorDisplay"
import ResponsiveTable from "src/components/ResponsiveTable"
import {
  STATUS,
  visitorsColumn,
} from "src/features/tickets/pages/TicketSearchPage"
import {TicketStatus} from "src/types"
import {Ticket, VisitorType} from "./CheckinWizard/types"
import {VisitorInfoCard} from "./VisitorInfoCard"
import {useCallback, useMemo, useState} from "react"
import produce from "immer"
import moment from "moment"
import {sortBy} from "lodash"
import {AttendanceCounterRow} from "./AttendanceCounterRow"
import {TicketsAttendance} from "../utils/attendance"

export type CandideCheckinInput = {
  ticketsAttendance: TicketsAttendance
  reviewRequirementsVerified: boolean
}

export const CandideAttendanceInput = ({
  onGoBack,
  ticket,
  visitorTypes,
  error,
  submitting,
  onConfirm,
  hideVisitorInfoCard,
}: {
  onGoBack?: () => void
  ticket: Ticket
  visitorTypes: Array<VisitorType>
  error: string | undefined
  submitting: boolean
  onConfirm: (input: CandideCheckinInput) => void
  hideVisitorInfoCard?: boolean
}) => {
  const [attendance, setAttendance] = useState<TicketsAttendance>({
    [ticket.id]: {[ticket.ticketTypes[0].visitors[0].visitorType.id]: 1},
  })
  const [reviewRequirementsVerified, setReviewRequirementsVerified] =
    useState(false)
  const hasCheckedInToday = useMemo(
    () =>
      ticket.checkins.results.some((c) =>
        moment(c.checkinTime).isSame(moment(), "day"),
      ),
    [ticket.checkins.results],
  )

  const onAttendanceChange = useCallback(
    (visitorTypeId: string, count: number, ticketId: string) => {
      setAttendance(
        produce((draft) => {
          draft[ticketId] ??= {}
          draft[ticketId][visitorTypeId] = count
        }),
      )
    },
    [],
  )

  return (
    <Stack spacing={3}>
      {onGoBack && (
        <Box>
          <Button onClick={onGoBack} variant="outlined">
            ← Go Back
          </Button>
        </Box>
      )}

      {!hideVisitorInfoCard && <VisitorInfoCard ticket={ticket} />}

      <ReviewRequirementsInfo
        reviewRequirements={ticket.product.reviewRequirements}
        setReviewRequirementsVerified={(verified) =>
          setReviewRequirementsVerified(verified)
        }
        reviewRequirementsVerified={reviewRequirementsVerified}
        initialReviewRequirementsVerified={ticket.reviewRequirementsVerified}
      />

      {hasCheckedInToday && (
        <Alert severity="error">
          This ticket has already been checked in today
        </Alert>
      )}

      <Stack spacing={2}>
        <Typography variant="h6" textAlign={"center"}>
          How many people are visiting today?
        </Typography>
        <Stack>
          <AttendanceInput
            ticket={ticket}
            visitorTypes={visitorTypes}
            attendance={attendance}
            onAttendanceChange={onAttendanceChange}
          />
        </Stack>
      </Stack>

      {R.filter(
        (a) => a.status === TicketStatus.Active,
        ticket.addonTickets,
      ).map((at) => (
        <>
          <Typography variant="h6" color="primary">
            {at.product.name}
          </Typography>
          <AttendanceInput
            ticket={at}
            visitorTypes={visitorTypes}
            attendance={attendance}
            onAttendanceChange={onAttendanceChange}
            isAddonTicket
          />
        </>
      ))}

      {error && <ErrorDisplay>{error}</ErrorDisplay>}

      {!error && (
        <BusyButton
          variant="contained"
          onClick={() =>
            onConfirm({
              ticketsAttendance: attendance,
              reviewRequirementsVerified,
            })
          }
          color="primary"
          fullWidth
          busy={submitting}
          data-cy="checkin-confirm-visitors"
        >
          {(ticket.product.reviewRequirements ?? []).length > 0 &&
          !ticket.reviewRequirementsVerified
            ? "Approve and check in"
            : "Check in"}
        </BusyButton>
      )}

      <CardHeader title="Addon Tickets" />
      <ResponsiveTable
        emptyMessage="No addons associated with this ticket"
        columns={[
          {
            title: "Product Name",
            getValue: ({product}) => product.name,
          },
          STATUS,
          visitorsColumn(),
        ]}
        data={ticket.addonTickets}
        hasMore={false}
        loading={false}
        error={false}
        loadingMore={false}
        getLink={(row) => `/tickets/${row.id}`}
        getKey={(row) => row.id}
        onRequestLoadMore={() => {}}
      />
    </Stack>
  )
}

const ReviewRequirementsInfo = ({
  reviewRequirements,
  setReviewRequirementsVerified,
  initialReviewRequirementsVerified,
  reviewRequirementsVerified,
}: {
  reviewRequirements: Ticket["product"]["reviewRequirements"]
  setReviewRequirementsVerified: (checked: boolean) => void
  reviewRequirementsVerified?: boolean | null
  initialReviewRequirementsVerified?: boolean | null
}) => {
  if (!reviewRequirements || reviewRequirements.length === 0) {
    return null
  }

  if (initialReviewRequirementsVerified) {
    return (
      <Alert severity="info">
        One or more visitors have shown valid proof of address
      </Alert>
    )
  }

  return (
    <Stack>
      <Alert severity="warning">
        <AlertTitle>
          <Typography style={{fontWeight: 600}}>
            Approve one or more visitors
          </Typography>
        </AlertTitle>
        <Typography>{reviewRequirements[0].adminDescription}</Typography>
      </Alert>
      <Alert severity="warning" icon={false} sx={{mt: 0}}>
        <Stack direction="row">
          <Checkbox
            style={{padding: 0, paddingRight: "8px"}}
            checked={reviewRequirementsVerified ?? undefined}
            onChange={(event) =>
              setReviewRequirementsVerified(event.target.checked)
            }
            id="review-requirements-checkbox"
          />
          <Box style={{fontSize: "14px", lineHeight: "24px"}}>
            <Typography
              component="label"
              htmlFor="review-requirements-checkbox"
              sx={{cursor: "pointer"}}
            >
              <Typography style={{fontWeight: 600, textTransform: "uppercase"}}>
                Approve visitor
              </Typography>
              <Typography>
                Approving visitors will prevent this notice from showing again.
              </Typography>
            </Typography>
          </Box>
        </Stack>
      </Alert>
    </Stack>
  )
}

const AttendanceInput = ({
  attendance,
  visitorTypes,
  isAddonTicket,
  onAttendanceChange,
  ticket,
}: {
  attendance: TicketsAttendance
  visitorTypes: Array<VisitorType>
  isAddonTicket?: boolean
  onAttendanceChange: (
    visitorTypeId: string,
    count: number,
    ticketId: string,
  ) => void
  ticket: Pick<Ticket, "id" | "checkins" | "ticketTypes">
}) => {
  const visitorTypeLimits = R.pipe(
    () => ticket.ticketTypes,
    R.chain((tt) => tt.visitors.map((v) => v.visitorType)),
    (tvts) => tvts.filter(({id}) => visitorTypes.some((vt) => vt.id === id)),
    R.countBy((vt) => vt.id),
  )()

  if (Object.keys(visitorTypeLimits).length === 0) {
    return (
      <Typography textAlign="center">
        No visitor types present for this business
      </Typography>
    )
  }

  const orderedVisitorTypes = sortBy(visitorTypes, [
    (x) => !Object.keys(visitorTypeLimits).includes(x.id),
    "isChild",
    "name",
  ])

  return (
    <>
      {orderedVisitorTypes.map((v, i) => {
        // set the first one in the list to have 1 as default but only for the candide checkin where limits are present
        // non candide checkins should default to 0 for all visitor types
        const min = !isAddonTicket && i === 0 ? 1 : 0

        return (
          <AttendanceCounterRow
            onChangeValue={(value) =>
              onAttendanceChange(v.id, value, ticket.id)
            }
            value={attendance[ticket.id]?.[v.id] ?? min}
            visitorTypeName={v.name}
            min={min}
            max={visitorTypeLimits[v.id]}
          />
        )
      })}
    </>
  )
}
