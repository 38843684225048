import {useCallback} from "react"

import CloseIcon from "@mui/icons-material/Close"
import {DialogContent, Typography} from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import {
  CheckinByTokenInputMethod,
  useCheckInVisitorByTicketIdMutation,
  useGetCheckedInTicketQuery,
  useSetReviewRequirementsVerificationMutation,
  useVisitorTypesQuery,
} from "src/types/apollo"
import {useTranslation} from "react-i18next"
import LoadingText from "src/components/LoadingText"
import {
  CandideAttendanceInput,
  CandideCheckinInput,
} from "src/features/checkIns/components/CandideAttendanceInput"
import {visitorTypeAttendanceToGqlInput} from "src/features/checkIns/utils/attendance"

interface Props {
  ticketId: string
  modalOpen: boolean
  toggleModal: () => void
  onSuccess: () => void
}

export default function CheckInVisitorModal({
  modalOpen,
  onSuccess,
  ticketId,
  toggleModal,
}: Props) {
  const {t} = useTranslation()

  const {data: ticketData} = useGetCheckedInTicketQuery({
    notifyOnNetworkStatusChange: true,
    skip: !modalOpen,
    variables: {
      ticketIds: [ticketId],
    },
  })
  const {data: visitorTypesData} = useVisitorTypesQuery({
    notifyOnNetworkStatusChange: true,
    skip: !modalOpen,
    variables: {
      activeOnly: true,
    },
  })
  const ticket = ticketData?.tickets[0]

  const [checkin, {error, loading}] = useCheckInVisitorByTicketIdMutation({
    refetchQueries: ["GetTicket"],
  })
  const [
    setReviewRequirementsVerification,
    {error: reviewError, loading: reviewLoading},
  ] = useSetReviewRequirementsVerificationMutation()

  const onConfirm = useCallback(
    async ({
      reviewRequirementsVerified,
      ticketsAttendance,
    }: CandideCheckinInput) => {
      if (ticket == null) {
        return
      }

      const [checkinRes] = await Promise.all(
        Object.entries(ticketsAttendance).flatMap(([tId, attendance]) => [
          checkin({
            variables: {
              attendance: visitorTypeAttendanceToGqlInput(attendance),
              input: {
                inputMethod: CheckinByTokenInputMethod.Manual,
                ticketId: tId,
              },
            },
          }),
          ...(reviewRequirementsVerified
            ? [
                setReviewRequirementsVerification({
                  variables: {
                    ticketId: tId,
                    verified: reviewRequirementsVerified,
                  },
                }),
              ]
            : []),
        ]),
      )

      if (!checkinRes?.data) {
        throw new Error("Failed to create checkin")
      }

      onSuccess()
    },
    [checkin, onSuccess, setReviewRequirementsVerification, ticket],
  )

  const visitorTypes =
    visitorTypesData?.me.associatedBusiness?.visitorTypes ?? []

  return (
    <Dialog onClose={toggleModal} open={modalOpen} fullWidth>
      <DialogTitle>
        <Typography variant="h6">
          {t("features.tickets.checkin.action")}
        </Typography>
        <div style={{position: "absolute", top: "8px", right: "8px"}}>
          <IconButton onClick={toggleModal} size="large">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {visitorTypes == null || ticket == null ? (
          <LoadingText />
        ) : (
          <CandideAttendanceInput
            error={
              error?.graphQLErrors[0].message ??
              reviewError?.graphQLErrors[0].message
            }
            onConfirm={onConfirm}
            submitting={loading || reviewLoading}
            ticket={ticket}
            visitorTypes={visitorTypes}
            hideVisitorInfoCard
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
