import {Alert} from "@mui/material"
import {useSelector} from "react-redux"
import {AuthState} from "../types"
import {useMemo} from "react"

export const PermissionError = () => {
  const userId = useSelector((state: {auth: AuthState}) => state.auth.userId)
  const reason = useSelector(
    (state: {auth: AuthState}) => state.auth.permissionsErrorReason,
  )

  const text = useMemo(() => {
    switch (reason) {
      case "InsufficientPermissions":
        return `You don't have permission to access Candide Admissions. Please contact Candide support to request permission, and include this user ID: ${userId}`

      case "UserNotAssociatedWithBusiness":
        return `You aren't associated with a business. Please contact Candide support or ask an admin to associate you, include your email and this user ID: ${userId}`

      default:
        return `You either don't have permission to access Candide Admissions, or aren't associated with a business. Please contact Candide support and include this user ID: ${userId}`
    }
  }, [userId, reason])

  return <Alert severity="error">{text}</Alert>
}
