import React from "react"
import {yellow} from "@mui/material/colors"
import escapeStringRegexp from "escape-string-regexp"
import reactStringReplace from "react-string-replace"

const DEFAULT_MIN_TERM_LENGTH = 3

interface Props {
  children?: string
  searchTerm?: string
  color?: string
  minimumTermLength?: number
  truncateTextAfter?: number
}

function getSearchRegex(mixedCaseSearchTerm: string) {
  const escapedSearchTerm = escapeStringRegexp(mixedCaseSearchTerm)
  const capturedSearchTerm = `(${escapedSearchTerm})` // Capturing group required by react-string-replace
  return new RegExp(capturedSearchTerm, "i")
}

export default class HighlightedText extends React.PureComponent<Props> {
  public render() {
    const minimumTermLength =
      this.props.minimumTermLength != null
        ? this.props.minimumTermLength
        : DEFAULT_MIN_TERM_LENGTH
    const searchTerm = this.props.searchTerm || ""
    const backgroundColor = this.props.color || yellow.A200
    let text = this.props.children || ""

    if (
      this.props.truncateTextAfter &&
      text.length > this.props.truncateTextAfter
    ) {
      text =
        text
          .slice(0, this.props.truncateTextAfter)
          .replace(/[.,?!]? +\w*$/, "") + "..."
    }

    if (searchTerm.length < minimumTermLength) {
      return <span>{text}</span>
    }

    const searchRegex = getSearchRegex(searchTerm)

    return (
      <span>
        {reactStringReplace(text, searchRegex, (match: string, i: any) => (
          <span key={match + i} style={{backgroundColor}}>
            {match}
          </span>
        ))}
      </span>
    )
  }
}
