import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import {useState} from "react"
import {useNavigate} from "react-router-dom"
import BusyButton from "../../../components/BusyButton"
import T from "../../../textResources"
import forgotPassword from "../actionCreators/networkRequests/forgotPassword"

export default function ForgotPasswordForm() {
  const navigate = useNavigate()
  const [email, setEmail] = useState("")

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<null | string>(null)
  const [success, setSuccess] = useState(false)

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault()
        try {
          setIsSubmitting(true)
          const success = await forgotPassword(email)

          if (!success) {
            setError("Something went wrong! Please try again.")
          } else {
            setError(null)
            setSuccess(true)
          }
        } finally {
          setIsSubmitting(false)
        }
      }}
    >
      <Grid container direction="column" alignItems="stretch" spacing={3}>
        <Grid item>
          <BusyButton
            variant="text"
            color="primary"
            onClick={() => {
              navigate("/")
            }}
            disabled={isSubmitting}
          >
            {T.auth.resetPage.backButton}
          </BusyButton>
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            disabled={isSubmitting}
            type="email"
            autoComplete="email"
            label={T.auth.resetPage.emailField.label}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        {error && (
          <Grid item>
            <span style={{color: "red"}}>{error}</span>
          </Grid>
        )}
        {success && (
          <Grid item>
            <span style={{color: "green"}}>{T.auth.resetPage.sendSuccess}</span>
          </Grid>
        )}
        <Grid item>
          <BusyButton
            variant="contained"
            color="primary"
            type="submit"
            busy={isSubmitting}
          >
            {T.auth.resetPage.submitButton}
          </BusyButton>
        </Grid>
      </Grid>
    </form>
  )
}
