import {FormLabel} from "@mui/material"
import {DateTimePicker} from "@mui/x-date-pickers"
import moment from "moment"
import React from "react"
import styled from "styled-components"

interface Props {
  value: string | null
  onChange: (newValue: string | null) => void
  label?: string
  isTableFilter?: boolean
}

export default class DateTimeFilter extends React.PureComponent<Props> {
  public render() {
    const {value, onChange, label, isTableFilter} = this.props

    return (
      <Container>
        {isTableFilter ? null : <FormLabel>{label}</FormLabel>}
        <DateTimePicker
          slotProps={{
            textField: {
              variant: isTableFilter ? "standard" : undefined,
              style: isTableFilter
                ? {fontVariant: "all-small-caps"}
                : undefined,
            },
            actionBar: {
              actions: ["clear", "cancel", "accept"],
            },
          }}
          value={value ? moment(value) : null}
          onChange={(m) => {
            onChange(m?.format() ?? null)
          }}
        />
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
