import Typography from "@mui/material/Typography"

interface Props {
  text: string
  backgroundColor: string
}

export default ({text, backgroundColor}: Props) => (
  <Typography
    variant="button"
    style={{
      minWidth: 50,
      color: "white",
      textTransform: "uppercase",
      textAlign: "center",
      paddingLeft: 4,
      paddingRight: 4,
      borderRadius: 4,
      backgroundColor,
    }}
  >
    {text}
  </Typography>
)
