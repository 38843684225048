import {CrossBusinessTicket, Ticket} from "./CheckinWizard/types"
import {Alert, Box, Paper, Stack, Typography} from "@mui/material"
import {statusColorMap} from "src/features/tickets/components/TicketStatusBadge"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import {deepPurple} from "@mui/material/colors"
import Badge from "src/components/Badge"
import getTicketTypeTicketVisitorsDisplay, {
  VisitorForDisplay,
} from "src/features/tickets/helpers/getTicketTypeTicketVisitorsDisplay"
import moment from "moment"
import * as R from "ramda"
import {capitalize} from "lodash"
import {useMemo} from "react"
import {assertNever} from "src/utils/assertNever"

export const VisitorInfoCard = ({
  ticket,
}: {
  ticket: Ticket | CrossBusinessTicket
}) => {
  const visitorTypes = useMemo(
    () =>
      ticket.__typename === "Ticket"
        ? Object.entries(
            R.countBy(
              (v) => v.visitorType.name,
              ticket.ticketTypes.flatMap((tt) => tt.visitors),
            ),
          ).map(([visitorTypeName, quantity]) => ({
            visitorTypeName,
            quantity,
          }))
        : ticket.__typename === "CrossBusinessTicket"
          ? ticket.attendanceLimits.map(({limit, visitorType}) => ({
              visitorTypeName: visitorType.name,
              quantity: limit,
            }))
          : assertNever(ticket),
    [ticket],
  )

  const visitors = useMemo(
    () =>
      ticket.__typename === "Ticket"
        ? ticket.ticketTypes.flatMap((tt) => tt.visitors)
        : undefined,
    [ticket],
  )

  return (
    <Stack spacing={2}>
      {ticket.__typename === "CrossBusinessTicket" && (
        <Alert severity="info">
          <Typography>
            This is a {ticket.business.name} {ticket.product.name}, the customer
            is entitled to use this at this estate as part of their{" "}
            {ticket.product.name}'s benefits.
          </Typography>
        </Alert>
      )}
      <Paper
        sx={{
          width: "100%",
          padding: 2,
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          color={statusColorMap[ticket.status]}
        >
          <CheckCircleOutlineIcon style={{marginRight: 4}} fontSize="large" />
          <Typography variant="h4">
            {capitalize(ticket.status)} Ticket
          </Typography>
        </Box>
        <Badge text={ticket.product.name} backgroundColor={deepPurple[200]} />
        <Stack spacing={2}>
          <VisitorNames visitorTypes={visitorTypes} visitors={visitors} />

          <VisitorInfo checkinInfo={ticket.checkins} />
        </Stack>
      </Paper>
    </Stack>
  )
}

const VisitorNames = ({
  visitorTypes,
  visitors,
}: {
  visitorTypes: Array<{visitorTypeName: string; quantity: number}>
  visitors?: Array<VisitorForDisplay>
}) => (
  <Stack>
    <Stack>
      {visitorTypes.map(({quantity, visitorTypeName}) => (
        <Typography>{`${quantity}x ${visitorTypeName}`}</Typography>
      ))}
    </Stack>
    {visitors != null &&
      getTicketTypeTicketVisitorsDisplay(visitors).map((v, i) => (
        <Typography variant="h5" key={i} fontWeight="bold" color="primary">
          {v}
        </Typography>
      ))}
  </Stack>
)

const VisitorInfo = ({checkinInfo}: {checkinInfo: Ticket["checkins"]}) => {
  const todayDate = moment().format("YYYY-MM-DD")
  const lastFiveCheckinDates = checkinInfo.results.map((c) => {
    return moment(c.checkinTime).format("YYYY-MM-DD")
  })
  const lastVisited = R.last(
    R.sortBy(R.identity, lastFiveCheckinDates).filter((d) => d !== todayDate),
  )

  return (
    <Stack spacing={2}>
      <Stack>
        <Box display={"flex"} justifyContent="space-between">
          <Typography>No. of checkins</Typography>
          <Typography>{checkinInfo.totalCount}</Typography>
        </Box>
        <Box display={"flex"} justifyContent="space-between">
          <Typography>Most recent visit</Typography>
          <Typography>
            {lastVisited ? moment(lastVisited).format("DD/MM/YYYY") : "—"}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  )
}
