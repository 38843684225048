import {
  Checkbox,
  Chip as MaterialChip,
  FormLabel,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import React from "react"
import styled from "styled-components"

interface Props {
  value: string | null
  onChange: (newValue: string | null) => void
  label?: string
  options: string[]
  optionLabels?: Record<string, string | undefined>
  isTableFilter?: boolean
}

export default class MultiSelectFilter extends React.PureComponent<Props> {
  public render() {
    const {value, onChange, label, options, optionLabels, isTableFilter} =
      this.props

    const getLabel = (selected: string | number) =>
      optionLabels && selected in optionLabels
        ? optionLabels[selected]
        : selected

    return (
      <Container>
        {isTableFilter ? null : <FormLabel>{label}</FormLabel>}
        <Select
          multiple
          variant={isTableFilter ? "standard" : undefined}
          value={value || undefined}
          onChange={(event) => onChange(event.target.value as string)}
          style={{
            height: "100%",
          }}
          SelectDisplayProps={{
            style: {
              paddingTop: "2px",
              paddingBottom: "2px",
            },
          }}
          renderValue={(selected) => (
            <Chips>
              {Array.isArray(selected) ? (
                <Stack direction="row" spacing={1}>
                  {isTableFilter ? (
                    <Typography variant="caption">
                      {selected
                        .map((s) => getLabel(s))
                        .toString()
                        .replaceAll(",", ", ")}
                    </Typography>
                  ) : (
                    selected.map((s) => (
                      <Chip key={s} label={getLabel(s)} size="small" />
                    ))
                  )}
                </Stack>
              ) : selected && typeof selected === "string" ? (
                <Chip key={selected} label={getLabel(selected)} size="small" />
              ) : null}
            </Chips>
          )}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={value?.includes(option)} />
              {isTableFilter ? (
                <Typography variant="caption">{option}</Typography>
              ) : (
                <ListItemText>{getLabel(option)}</ListItemText>
              )}
            </MenuItem>
          ))}
        </Select>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Chips = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
`

const Chip = styled(MaterialChip)`
  margin: 2px;
`
