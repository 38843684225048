import moment from "moment"
import {PreviewAction, PreviewChangesState} from "../types"

const PREVIEW_CHANGES_STORAGE_KEY = "previewChanges"
const NAG_BREAK_IN_DAYS = 2
const local = localStorage.getItem(PREVIEW_CHANGES_STORAGE_KEY)

const initialState: PreviewChangesState =
  local != null
    ? JSON.parse(local)
    : {
        previewing: {},
        dismissed: {},
      }

export default function (
  state: PreviewChangesState = initialState,
  action: PreviewAction,
): PreviewChangesState {
  let updatedState: PreviewChangesState
  switch (action.type) {
    case "PREVIEWING_CHANGES":
      updatedState = {
        ...state,
        previewing: {
          ...state.previewing,
          [action.key]: true,
        },
      }
      break

    case "STOP_PREVIEWING_CHANGES":
      updatedState = {
        ...state,
        previewing: {
          ...state.previewing,
          [action.key]: false,
        },
      }
      break

    case "DISMISSED":
      updatedState = {
        ...state,
        dismissed: {
          ...state.dismissed,
          [action.key]: moment().add(NAG_BREAK_IN_DAYS, "day").toDate(),
        },
      }
      break

    default:
      updatedState = state
      break
  }

  localStorage.setItem(
    PREVIEW_CHANGES_STORAGE_KEY,
    JSON.stringify(updatedState),
  )

  return updatedState
}
