import {RouteInfo} from "../../types"
import CustomerPage from "./pages/CustomerPage"
import UserPage from "./pages/UserPage"

export const routeInfo: RouteInfo[] = [
  {
    path: "/users/:userId",
    component: UserPage,
  },
  {
    path: "/customers/:customerId",
    component: CustomerPage,
  },
]
