import {Box, Typography} from "@mui/material"
import Counter from "src/components/Counter"

export const AttendanceCounterRow = ({
  onChangeValue,
  value,
  visitorTypeName,
  max = Number.POSITIVE_INFINITY,
  min = 0,
}: {
  visitorTypeName: string
  value: number
  onChangeValue: (value: number) => void
  min?: number
  max?: number
}) => (
  <Box
    display={"flex"}
    alignItems={"center"}
    justifyContent={"space-between"}
    marginBottom={2}
    data-cy={`attendance-input-row-${visitorTypeName}`}
  >
    <Typography variant="h5">{visitorTypeName}</Typography>
    <Counter
      min={min}
      max={max}
      value={value}
      onChange={(value) => onChangeValue(value)}
    />
  </Box>
)
