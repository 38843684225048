import {ApolloProvider} from "@apollo/client"
import {CssBaseline, PaletteMode} from "@mui/material"
import {
  responsiveFontSizes,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles"
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import React from "react"
import {Provider} from "react-redux"
import {BrowserRouter} from "react-router-dom"
import {Store} from "redux"
import {buildApolloClient} from "./apollo/client"
import AppFrame from "./components/AppFrame"
import Sidebar from "./components/Sidebar"
import {AuthWrapper} from "./features/auth"
import "./i18n/config"
import {RouteInfo} from "./types"
import {ReduxState} from "./types/redux"
import ErrorCatcher from "./components/ErrorCatcher"
import Bugsnag from "@bugsnag/js"
import useColorModeTheme from "./hooks/useColorModeTheme"
import {SnackbarProvider} from "notistack"

interface Props {
  store: Store<ReduxState>
  routes: RouteInfo[]
}

const THEME_MODE = "themeMode"
export const ColorModeContext = React.createContext({toggleColorMode: () => {}})

export default function App({store, routes}: Props) {
  const [mode, setMode] = React.useState<PaletteMode>(
    (localStorage.getItem(THEME_MODE) as PaletteMode) ?? "light",
  )
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === "light" ? "dark" : "light"
          localStorage.setItem(THEME_MODE, newMode)
          return newMode
        })
      },
    }),
    [],
  )
  let theme = useColorModeTheme(mode)
  theme = responsiveFontSizes(theme)
  // WARNING! Bugsnag.start MUST have been called ahead of this line
  const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React)

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        {/* To enable themed error fallback we need to wrap the error boundry in the ThemeProvider */}
        <ErrorCatcher ErrorBoundary={ErrorBoundary}>
          <Provider store={store}>
            <ApolloProvider client={buildApolloClient()}>
              <StyledEngineProvider injectFirst>
                <CssBaseline />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <SnackbarProvider
                    anchorOrigin={{horizontal: "center", vertical: "bottom"}}
                  >
                    <BrowserRouter>
                      <AuthWrapper>
                        <Sidebar />
                        <AppFrame routes={routes} />
                      </AuthWrapper>
                    </BrowserRouter>
                  </SnackbarProvider>
                </LocalizationProvider>
              </StyledEngineProvider>
            </ApolloProvider>
          </Provider>
        </ErrorCatcher>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}
