import BugsnagPerformance from "@bugsnag/browser-performance"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./index.css"

import {BUGSNAG_API_KEY, GRAPHQL_URL, VERSION} from "./config"
import {getPermissionsAndCurrentUser} from "./features/auth"

import {applyMiddleware, combineReducers, compose, createStore} from "redux"

import {reducer as authReducer} from "./features/auth"
import explainChangesReducer from "./features/explainChanges/reducers"
import previewChangesReducer from "./features/previewChanges/reducers"
import {reducer as withPersistentOptionsReducer} from "./features/withSemiPersistentOptionsHoc"

import {routeInfo as auditLogRouteInfo} from "./features/auditLog"
import {routeInfo as checkInsRouteInfo} from "./features/checkIns"
import {routeInfo as integrationsRouteInfo} from "./features/integrations"
import {routeInfo as overviewRouteInfo} from "./features/overview"
import {routeInfo as setupRouteInfo} from "./features/setup"
import {routeInfo as superAdminUsersRouteInfo} from "./features/superAdmin"
import {routeInfo as ticketsRouteInfo} from "./features/tickets"
import {routeInfo as usersRouteInfo} from "./features/users"

import thunk from "redux-thunk"
import type {RouteInfo} from "./types"

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  appVersion: VERSION,
  releaseStage: GRAPHQL_URL.includes("dev") ? "development" : "production",
  plugins: [new BugsnagPluginReact()],
})
BugsnagPerformance.start({apiKey: BUGSNAG_API_KEY})

const routes: Array<RouteInfo> = [
  ...ticketsRouteInfo,
  ...checkInsRouteInfo,
  ...usersRouteInfo,
  ...superAdminUsersRouteInfo,
  ...setupRouteInfo,
  ...overviewRouteInfo,
  ...auditLogRouteInfo,
  ...integrationsRouteInfo,
]

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  combineReducers({
    auth: authReducer,
    previewChanges: previewChangesReducer,
    explainChanges: explainChangesReducer,
    withPersistentOptions: withPersistentOptionsReducer,
  }),
  composeEnhancers(applyMiddleware(thunk)),
)

if (localStorage.getItem("userId")) {
  getPermissionsAndCurrentUser(store.dispatch).then(({currentUser}) => {
    if (currentUser != null && "fcWidget" in window) {
      ;(window as any).fcWidget.user.setProperties({
        firstName: `${
          currentUser.business?.id ?? currentUser.business.name ?? "Logged out"
        } (${currentUser.email.split("@")[0]})`,
        email: currentUser.email,
        externalId: currentUser.id,
      })
    }
  })
}

const rootElement = document.getElementById("root")
const root = ReactDOM.createRoot(rootElement!)
// WARNING! Bugsnag.start MUST have been called ahead of this line
const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React)

root.render(
  <ErrorBoundary>
    <App store={store} routes={routes} />
  </ErrorBoundary>,
)
