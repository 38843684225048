import {
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
  Collapse,
  Box,
  Snackbar,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material"
import {green, grey, orange, pink, red} from "@mui/material/colors"
import {Delete, Refresh, Send} from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import {Alert} from "@mui/material"
import moment from "moment"
import {useCallback, useMemo, useState} from "react"
import Badge from "src/components/Badge"
import BusyButton, {BusyIconButton} from "src/components/BusyButton"
import {
  GetTicketQuery,
  Ticket,
  useCreateVisitorShareMutation,
  useDeleteVisitorShareMutation,
} from "src/types/apollo"
import validateEmail from "src/utils/validateEmail"
import {useTranslation} from "react-i18next"
import {TFunction} from "i18next"

interface Props {
  modalOpen: boolean
  toggleModal: () => void
  ticket: NonNullable<GetTicketQuery["tickets"][number]>
}

type StatusOptions = "LINKED" | "UNSHARED" | "UNSENT" | "SHARED"

type Ticket_ticketTypes_visitors = NonNullable<
  GetTicketQuery["tickets"][number]
>["ticketTypes"][number]["visitors"][number]
const getShareStatus = (
  visitor: Ticket_ticketTypes_visitors,
): {status: StatusOptions; color: string} => {
  if (visitor.externalUserId != null) {
    return {status: "LINKED", color: green[300]}
  }

  if (
    visitor.shares.length === 0 ||
    visitor.shares.every((s) => s.invalidatedAt != null)
  ) {
    return {status: "UNSHARED", color: grey[400]}
  }

  if (visitor.shares[0].emailSentAt == null) {
    return {status: "UNSENT", color: pink[400]}
  }

  return {status: "SHARED", color: orange[400]}
}

const getShareStatusTranslation = (
  status: StatusOptions,
  t: TFunction<"translation", undefined>,
) => {
  switch (status) {
    case "SHARED":
      return t("features.tickets.sharing.status.shared")
    case "UNSHARED":
      return t("features.tickets.sharing.status.unshared")
    case "LINKED":
      return t("features.tickets.sharing.status.linked")
    case "UNSENT":
      return t("features.tickets.sharing.status.unsent")
  }
}

export default function ManageSharingModal({
  modalOpen,
  ticket,
  toggleModal,
}: Props) {
  const {t} = useTranslation()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const visitors = useMemo(
    () => ticket.ticketTypes.flatMap((tt) => tt.visitors),
    [ticket.ticketTypes],
  )

  return (
    <>
      <Dialog onClose={toggleModal} open={modalOpen} fullWidth maxWidth="md">
        <DialogTitle>
          <Typography variant="h6">
            {t("features.tickets.sharing.manage")}
          </Typography>
          <div style={{position: "absolute", top: "8px", right: "8px"}}>
            <IconButton onClick={toggleModal} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <Table>
          <TableHead>
            <TableCell /> {/* Expand */}
            <TableCell>{t("features.tickets.sharing.status.label")}</TableCell>
            <TableCell>{t("features.tickets.sharing.visitorName")}</TableCell>
            <TableCell>{t("features.tickets.sharing.email")}</TableCell>
            <TableCell /> {/* Resend */}
            <TableCell /> {/* Invalidate */}
          </TableHead>
          <TableBody>
            {visitors.map((v) => (
              <Row
                key={v.id}
                visitor={v}
                ticket={ticket}
                onError={(message) => setErrorMessage(message)}
              />
            ))}
          </TableBody>
        </Table>
      </Dialog>
      <Snackbar
        open={errorMessage != null}
        autoHideDuration={10000}
        onClose={() => setErrorMessage(undefined)}
      >
        <Alert severity="error" onClose={() => setErrorMessage(undefined)}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

const Row = ({
  visitor,
  ticket,
  onError,
}: {
  visitor: Ticket_ticketTypes_visitors
  ticket: Pick<Ticket, "id">
  onError: (message: string) => void
}) => {
  const {t} = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const {status, color} = getShareStatus(visitor)
  const currentEmail =
    visitor.shares[0]?.invalidatedAt == null
      ? (visitor.shares[0]?.email ?? "")
      : ""
  const [email, setEmail] = useState(currentEmail)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const [createTicketShare, {loading}] = useCreateVisitorShareMutation({
    variables: {
      email,
      ticketId: ticket.id,
      ticketVisitorId: visitor.id,
    },
    refetchQueries: ["GetTicket"],
  })

  const [deleteTicketShare, {loading: deleteLoading}] =
    useDeleteVisitorShareMutation({
      refetchQueries: ["GetTicket"],
    })

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  )

  const onCreate = useCallback(
    async () => createTicketShare().catch(onError),
    [createTicketShare, onError],
  )

  const onDelete = useCallback(
    async () =>
      deleteTicketShare({
        variables: {
          ticketId: ticket.id,
          shareId: visitor.shares[0]?.id,
        },
      }).catch(onError),
    [deleteTicketShare, onError, ticket.id, visitor.shares],
  )

  const isCurrentShare = status === "SHARED" && email === currentEmail
  return (
    <>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell>
          {visitor.shares.length > 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setIsOpen((open) => !open)}
            >
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          <Badge
            text={getShareStatusTranslation(status, t)}
            backgroundColor={color}
          />
        </TableCell>
        <TableCell>
          {visitor.firstName} {visitor.lastName}
        </TableCell>
        <TableCell>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            disabled={status === "LINKED"}
            placeholder={
              status === "LINKED"
                ? t("features.tickets.sharing.alreadyLinked")
                : t("features.tickets.sharing.actions.email")
            }
          />
        </TableCell>
        <TableCell width="50px">
          <BusyIconButton
            title={
              isCurrentShare
                ? t("features.tickets.sharing.actions.resend")
                : t("features.tickets.sharing.actions.send")
            }
            onClick={() => {
              if (!validateEmail(email)) {
                setErrorMessage(
                  t("features.tickets.sharing.error.emailInvalid"),
                )
                return
              }

              status === "SHARED" && email !== currentEmail
                ? setConfirmationModalOpen(true)
                : onCreate()
            }}
            busy={loading}
            disabled={email.length === 0 || status === "LINKED"}
          >
            {isCurrentShare ? <Refresh /> : <Send />}
          </BusyIconButton>
        </TableCell>
        <TableCell width="50px">
          <BusyButton
            disabled={
              visitor.shares.length === 0 ||
              visitor.shares[0].claimedAt != null ||
              visitor.shares[0].invalidatedAt != null
            }
            onClick={onDelete}
            busy={deleteLoading}
          >
            <Delete />
          </BusyButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} style={{paddingTop: 0, paddingBottom: 0}}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom>
                {t("features.tickets.sharing.history")}
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableCell>
                    {t("features.tickets.sharing.status.label")}
                  </TableCell>
                  <TableCell>{t("features.tickets.sharing.email")}</TableCell>
                  <TableCell>
                    {t("features.tickets.sharing.createdAt")}
                  </TableCell>
                  <TableCell>
                    {t("features.tickets.sharing.emailSentAt")}
                  </TableCell>
                  <TableCell>
                    {t("features.tickets.sharing.claimedAt")}
                  </TableCell>
                  <TableCell>
                    {t("features.tickets.sharing.invalidatedAt")}
                  </TableCell>
                </TableHead>
                <TableBody>
                  {visitor.shares.map((share) => {
                    const [status, color] =
                      share.claimedAt != null
                        ? ["CLAIMED", green[300]]
                        : share.invalidatedAt != null
                          ? ["INVALIDATED", red[400]]
                          : ["PENDING", orange[400]]

                    return (
                      <TableRow>
                        <TableCell>
                          <Badge text={status} backgroundColor={color} />
                        </TableCell>
                        <TableCell>{share.email}</TableCell>
                        <TableCell>
                          {moment(share.createdAt).format("lll")}
                        </TableCell>
                        <TableCell>
                          {share.emailSentAt != null &&
                            moment(share.emailSentAt).format("lll")}
                        </TableCell>
                        <TableCell>
                          {share.claimedAt != null &&
                            moment(share.claimedAt).format("lll")}
                        </TableCell>
                        <TableCell>
                          {share.invalidatedAt != null &&
                            moment(share.invalidatedAt).format("lll")}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <Snackbar
        open={errorMessage != null}
        autoHideDuration={10000}
        onClose={() => setErrorMessage(undefined)}
      >
        <Alert severity="error" onClose={() => setErrorMessage(undefined)}>
          {errorMessage}
        </Alert>
      </Snackbar>

      {confirmationModalOpen && (
        <Dialog open onClose={() => setConfirmationModalOpen(false)}>
          <DialogTitle>{t("actions.confirm")}</DialogTitle>
          <DialogContent>
            {t("features.tickets.sharing.emailChangeWarning")}
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => setConfirmationModalOpen(false)}
            >
              {t("actions.cancel")}
            </Button>
            <Button
              color="primary"
              autoFocus
              onClick={() => {
                onCreate()
                setConfirmationModalOpen(false)
              }}
            >
              {t("features.tickets.sharing.changeEmail")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
