import {Event} from "@bugsnag/js"
import {Box, Button, Stack, Typography} from "@mui/material"
import {PropsWithChildren, useCallback, useMemo} from "react"
import FullscreenPaperContainer from "src/features/auth/components/FullscreenPaperContainer"
import ErrorBox, {UnknownError, stringifyError} from "./ErrorBox"
import {Trans} from "react-i18next"
import {useTranslation} from "react-i18next"
import {BugsnagErrorBoundary} from "@bugsnag/plugin-react"

const ChunkError = () => {
  const {t} = useTranslation()
  const onClick = useCallback(() => window.location.reload(), [])
  return (
    <FullscreenPaperContainer title="Candide Admissions">
      <Stack spacing={4}>
        <Typography textAlign={"center"}>
          <Trans>{t("features.updateAvailable.explanation")}</Trans>
        </Typography>
        <Box display={"flex"} justifyContent={"center"}>
          <Button onClick={onClick} variant="contained">
            {t("features.updateAvailable.reload")}
          </Button>
        </Box>
      </Stack>
    </FullscreenPaperContainer>
  )
}

type PropsErrorScreen = {
  error: UnknownError
}

const DefaultError = ({error}: PropsErrorScreen) => {
  const {t} = useTranslation()
  const onClick = useCallback(() => {
    if ("fcWidget" in window) {
      ;(window as any).fcWidget.open()
    }
  }, [])
  return (
    <FullscreenPaperContainer title="Candide Admissions">
      <Stack spacing={4}>
        <ErrorBox error={error} />
        <Box display={"flex"} justifyContent={"center"}>
          <Button onClick={onClick} variant="contained">
            {t("features.unexpectedError.getSupport")}
          </Button>
        </Box>
      </Stack>
    </FullscreenPaperContainer>
  )
}

const chunkErrorNames = [
  "Loading chunk",
  "Failed to fetch dynamically imported module",
]

const containsChunkError = (error: UnknownError): boolean => {
  const message = stringifyError(error)
  return chunkErrorNames.some((errName) => message.includes(errName))
}

const ShowErrorByType = ({error}: PropsErrorScreen) => {
  const isChunkError = useMemo(() => containsChunkError(error), [error])
  const Component = isChunkError ? ChunkError : DefaultError
  return <Component error={error} />
}

type Props = {
  ErrorBoundary: BugsnagErrorBoundary
}

export default ({children, ErrorBoundary}: PropsWithChildren<Props>) => {
  const onError = useCallback((event: Event) => {
    const isChunkError = containsChunkError(event)
    event.severity = "info"
    event.unhandled = false
    // Use pesistent className to categorise handled error types
    event.errors[0].errorClass = "PageErrorCaught"
    // by returning false chunk loading errors won't be sent to BugSnag
    return !isChunkError
  }, [])

  return (
    <ErrorBoundary onError={onError} FallbackComponent={ShowErrorByType}>
      {children}
    </ErrorBoundary>
  )
}
