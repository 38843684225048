import {useEffect, useMemo} from "react"
import moment from "moment"

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material"
import groupBy from "lodash/groupBy"
import {GetProductQuery} from "src/types"

interface Props {
  schedules: NonNullable<GetProductQuery["products"][number]["schedules"]>
  timeslotStartsAt: string | null
  setTimeslotStartsAt: (startsAt: string | null) => void
}

export default function TicketTimeslotSelector({
  schedules,
  timeslotStartsAt,
  setTimeslotStartsAt,
}: Props) {
  const allTimeslots = useMemo(
    () =>
      schedules.flatMap((schedule) =>
        schedule.timeslots.map((t) => ({schedule, ...t})),
      ),
    [schedules],
  )

  const groupedTimeslots = useMemo(
    () =>
      Object.entries(
        groupBy(allTimeslots, ({from}) => moment(from).startOf("day")),
      ),
    [allTimeslots],
  )

  useEffect(() => {
    if (allTimeslots[0] == null) {
      return
    }

    if (!allTimeslots.some(({from}) => from === timeslotStartsAt)) {
      setTimeslotStartsAt(allTimeslots[0].from)
    }
  }, [allTimeslots, setTimeslotStartsAt, timeslotStartsAt])

  return (
    <FormControl style={{maxWidth: "100%"}}>
      <RadioGroup
        value={timeslotStartsAt}
        onChange={(e) => setTimeslotStartsAt(e.target.value)}
      >
        {groupedTimeslots.map(([date, timeslots]) => (
          <>
            <FormLabel>{moment(date).format("Do MMM yyyy")}</FormLabel>
            <div style={{display: "flex", flexWrap: "wrap", marginBottom: 12}}>
              {timeslots.map(
                ({from, to, visitorCount, schedule: {slotCapacity}}) => (
                  <FormControlLabel
                    label={`${moment(from).format("HH:mm")}-${moment(to).format(
                      "HH:mm",
                    )} (${visitorCount}/${slotCapacity ?? "∞"})`}
                    value={from}
                    control={<Radio />}
                  />
                ),
              )}
            </div>
          </>
        ))}
      </RadioGroup>
    </FormControl>
  )
}
