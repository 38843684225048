import moment from "moment"
import type {ExplainAction, ExplainChangesState} from "../types"

const EXPLAIN_CHANGES_STORAGE_KEY = "explainChanges"
const NAG_BREAK_IN_DAYS = 2
const local = localStorage.getItem(EXPLAIN_CHANGES_STORAGE_KEY)

const initialState: ExplainChangesState =
  local != null
    ? JSON.parse(local)
    : {
        explaining: {},
        dismissed: {},
      }

export default function (
  state: ExplainChangesState = initialState,
  action: ExplainAction,
): ExplainChangesState {
  let updatedState: ExplainChangesState
  switch (action.type) {
    case "EXPLAIN_CHANGES":
      updatedState = {
        ...state,
        explaining: {
          ...state.explaining,
          [action.key]: true,
        },
      }
      break

    case "DISMISS_EXPLANATION":
      updatedState = {
        ...state,
        dismissed: {
          ...state.dismissed,
          [action.key]: moment().add(NAG_BREAK_IN_DAYS, "day").toDate(),
        },
      }
      break

    default:
      updatedState = state
      break
  }

  localStorage.setItem(
    EXPLAIN_CHANGES_STORAGE_KEY,
    JSON.stringify(updatedState),
  )

  return updatedState
}
