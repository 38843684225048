import {Refund_Ticket_Reason as RefundReason} from "src/types/apollo"

export default {
  generic: {
    pageLoadingText: "Still loading...",
    pageErrorText: "Sorry, an error occurred",
  },
  auth: {
    loginPage: {
      title: "Candide Admissions",
      submitLoginButton: "Log in",
      submitRegisterButton: "Register",
      emailField: {
        label: "Email",
      },
      passwordField: {
        label: "Password",
      },
      forgotPassword: "Forgot password?",
    },
    resetPage: {
      submitButton: "Reset password",
      backButton: "← Back to login",
      sendSuccess:
        "If an account with that email exists, we've sent you a link to reset your password.",
      resetSuccess: "Password reset successfully, you can now log in.",
      emailField: {
        label: "Email",
      },
      passwordField: {
        label: "Password",
        confirm: "Confirm password",
      },
    },
    logOutButtonText: "Log out",
  },
  notFoundPage: {
    title: "404",
    text: "Page not found",
  },
  errors: {
    accessDenied: "You don't have permission to access this page",
  },
  emailSuggestion: {
    prefix: "Did you mean ",
    suffix: "?",
  },
  documents: {
    fields: {
      noValue: "No value",
      required: "Required",
    },
  },
}

export const refundReasonTextMap = {
  [RefundReason.Duplicate]: "Duplicate",
  [RefundReason.Fraudulent]: "Fraudulent",
  [RefundReason.RequestedByCustomer]: "Requested by customer",
}
