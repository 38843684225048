import {Permission} from "src/types/apollo"

export default function (permissionEnum: Permission) {
  return permissionEnum
    .toLocaleLowerCase()
    .replaceAll("_", " ")
    .replaceAll("cms", "")
    .replaceAll("can", "")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
    .trim()
}
