import {useTranslation} from "react-i18next"
import {Permission} from "src/types/apollo"
import Modal from "../../../components/Modal"
import UserPermissionsWidget from "./UserPermissionsWidget"

interface Props {
  userId: string
  email: string | null
  open: boolean
  onClose: () => void
  permissions: Permission[]
}

export default function UserPermissionsModal(props: Props) {
  const {userId, open, permissions, onClose} = props
  const {t} = useTranslation()

  return (
    <Modal
      title={t("features.users.permissions.label")}
      open={open}
      onClose={onClose}
      closeButtonText={t("actions.close")}
    >
      <UserPermissionsWidget userId={userId} permissions={permissions} />
    </Modal>
  )
}
