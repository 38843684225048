import React from "react"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import {Box} from "@mui/material"
import {useTheme} from "@mui/material/styles"

interface Props {
  title: string
  children: React.ReactNode
}

export default function FullscreenPaperContainer(props: Props) {
  const theme = useTheme()
  const {title, children} = props
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.primary.main
      : theme.palette.primary.dark

  const containerBackgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.common.white
      : theme.palette.common.black

  return (
    <Box
      position={"fixed"}
      alignItems={"center"}
      justifyContent={"center"}
      display={"flex"}
      top={0}
      bottom={0}
      right={0}
      left={0}
      zIndex={9999}
      mx={0}
      sx={{backgroundColor: containerBackgroundColor}}
    >
      <Paper sx={{width: 400, maxWidth: "100%"}} elevation={4}>
        <Box padding={3} sx={{backgroundColor}}>
          <Typography
            color={theme.palette.getContrastText(theme.palette.primary.main)}
            variant="h4"
            textAlign={"center"}
          >
            {title}
          </Typography>
        </Box>
        <Box p={3}>{children}</Box>
      </Paper>
    </Box>
  )
}
