import React, {Suspense} from "react"
import {Route, Routes} from "react-router-dom"
import {RouteInfo} from "../types"
import NotFoundPage from "./NotFoundPage"
import Page from "./Page"

interface Props {
  routes: RouteInfo[]
}

// Not a pure component to allow the routes to update correctly
export default class AppFrame extends React.Component<Props> {
  public render() {
    const {routes} = this.props
    return (
      <Suspense fallback={<Page loading />}>
        <Routes>
          {routes.map((routeInfo, index) => (
            <Route
              key={index}
              path={routeInfo.path}
              Component={routeInfo.component}
            />
          ))}
          <Route path="*" Component={NotFoundPage} />
        </Routes>
      </Suspense>
    )
  }
}
