import {ApolloError} from "@apollo/client"
import {SelectChangeEvent} from "@mui/material"
import * as R from "ramda"
import React, {useState} from "react"
import {useTranslation} from "react-i18next"
import {
  GetTicketQuery,
  Refund_Ticket_Reason as RefundReason,
  useRefundTicketMutation,
} from "src/types/apollo"
import {removeNothings} from "src/utils/nothingRemoval"
import RefundModal from "./RefundModal"

interface Props {
  modalOpen: boolean
  ticket: NonNullable<GetTicketQuery["tickets"][0]>
  toggleModal: () => void
  onSuccess: () => void
}

export default function RefundPaymentModal({
  modalOpen,
  ticket,
  toggleModal,
  onSuccess,
}: Props) {
  const {t} = useTranslation()
  const [notes, setNotes] = useState<string | undefined>()
  const [refundReason, setRefundReason] = useState<RefundReason>(
    RefundReason.RequestedByCustomer,
  )
  const [refundAmount, setRefundAmount] = useState<string | undefined>()
  const [inputError, setInputError] = useState<string | undefined>()
  const [refundError, setRefundError] = useState<ApolloError | undefined>()

  const [refundTicket, {loading: refundLoading}] = useRefundTicketMutation({
    refetchQueries: ["GetTicket"],
    onError: (apolloError) => {
      setRefundError(apolloError)
    },
    onCompleted: (result) => {
      if (result.refundTicket.id) {
        onSuccess()
      }
    },
  })

  const paymentIntent =
    ticket.paymentData?.__typename === "TicketPaymentDataPaymentIntent" &&
    ticket.paymentData?.paymentIntent

  if (!paymentIntent) {
    return null
  }

  const amountPaid = paymentIntent.amount
  const refunds = removeNothings(paymentIntent.refunds ?? [])
  const amountRefunded = R.sum(refunds.map(({amount}) => amount ?? 0))

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRefundAmount(event.target.value)
    setInputError(undefined)
  }

  const handleReasonChange = (event: SelectChangeEvent) => {
    setRefundReason(event.target.value as RefundReason)
  }

  const handleNotesChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setNotes(event.target.value as string)
  }

  const handleSubmit = async () => {
    if (refundAmount === undefined || Number.parseFloat(refundAmount) <= 0) {
      setInputError(t("features.tickets.refund.errors.mustBeGreaterThanZero"))
      return
    }

    const refundAmountInCents = Number.parseFloat(refundAmount) * 100

    if (refundAmountInCents + amountRefunded > amountPaid) {
      setInputError(
        t("features.tickets.refund.errors.cannotRefundMoreThanPaid"),
      )
      return
    }

    await refundTicket({
      variables: {
        ticketId: ticket.id,
        amount: refundAmountInCents,
        reason: refundReason,
        notes,
      },
    })
  }

  return (
    <RefundModal
      modalOpen={modalOpen}
      entity={ticket}
      toggleModal={toggleModal}
      handleSubmit={handleSubmit}
      handleAmountChange={handleAmountChange}
      handleReasonChange={handleReasonChange}
      handleNotesChange={handleNotesChange}
      setRefundAmount={setRefundAmount}
      amountPaid={amountPaid}
      amountRefunded={amountRefunded}
      inputError={inputError}
      refundError={refundError}
      refundAmount={refundAmount}
      refundReason={refundReason}
      notes={notes}
      refundLoading={refundLoading}
    />
  )
}
