import React from "react"

// This component can help prevent an ugly flash when a modal is fading out but
// its content gets removed.

interface Props {
  shouldUpdate: boolean
  children?: React.ReactNode
}

export default class RenderBlocker extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: {shouldUpdate: boolean}) {
    return nextProps.shouldUpdate
  }

  public render() {
    return this.props.children
  }
}
