import {
  FormGroup,
  Stack,
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
  Grid,
  Tooltip,
  GridProps,
  Switch,
} from "@mui/material"
import groupBy from "lodash/groupBy"
import {useMemo} from "react"
import {useTranslation} from "react-i18next"
import {OptionalTooltip} from "src/components/OptionalTooltip"
import {AllPermissionsQuery, Permission} from "src/types"
import InfoIcon from "@mui/icons-material/Info"
import autoLabel from "./autoLabel"

export const PermissionsGrid = ({
  allPermissions,
  selectedPermissions,
  onChangeSelectedPermissions,
  editablePermissions = allPermissions.map((p) => p.name),
  gridItemProps,
  variant = "checkbox",
  loading,
}: {
  allPermissions: AllPermissionsQuery["allPermissions"]
  selectedPermissions: Partial<Record<Permission, boolean>>
  onChangeSelectedPermissions: (
    changedPermissions: Record<Permission, boolean>,
  ) => void
  editablePermissions?: Array<Permission>
  gridItemProps?: Omit<GridProps, "item" | "container">
  variant?: "checkbox" | "switch"
  loading?: boolean
}) => {
  const {t} = useTranslation()

  const groupedPermissions = useMemo(
    () =>
      Object.entries(groupBy(allPermissions, (p) => p.group)).sort(
        ([g1], [g2]) => g1.localeCompare(g2),
      ),
    [allPermissions],
  )

  return (
    <FormGroup>
      <Stack spacing={2}>
        {groupedPermissions.map(([group, groupPermissions]) => {
          const groupEditablePermissions = editablePermissions.filter((p) =>
            groupPermissions.some((gp) => gp.name === p),
          )

          return (
            <Card variant="outlined">
              <CardContent>
                <FormControlLabel
                  control={
                    variant === "checkbox" ? (
                      <Checkbox
                        checked={groupEditablePermissions.every(
                          (p) => !!selectedPermissions[p],
                        )}
                        indeterminate={
                          groupEditablePermissions.some(
                            (p) => !!selectedPermissions[p],
                          ) &&
                          !groupEditablePermissions.every(
                            (p) => !!selectedPermissions[p],
                          )
                        }
                        onChange={(e) =>
                          onChangeSelectedPermissions(
                            Object.fromEntries(
                              groupEditablePermissions.map((p) => [
                                p,
                                e.target.checked,
                              ]),
                            ) as Record<Permission, boolean>,
                          )
                        }
                      />
                    ) : (
                      <Switch
                        checked={groupEditablePermissions.every(
                          (p) => !!selectedPermissions[p],
                        )}
                        onChange={(e) =>
                          onChangeSelectedPermissions(
                            Object.fromEntries(
                              groupEditablePermissions.map((p) => [
                                p,
                                e.target.checked,
                              ]),
                            ) as Record<Permission, boolean>,
                          )
                        }
                      />
                    )
                  }
                  label={group}
                  value={group}
                  disabled={groupEditablePermissions.length === 0 || loading}
                  componentsProps={{
                    typography: {
                      variant: "h6",
                    },
                  }}
                />

                <Grid container spacing={0} px={2}>
                  {groupPermissions.map(({description, name}) => {
                    const isEditable = groupEditablePermissions.some(
                      (p) => p === name,
                    )

                    return (
                      <Grid
                        xs={12}
                        md={6}
                        alignItems="center"
                        display="flex"
                        {...gridItemProps}
                        item
                      >
                        <OptionalTooltip
                          title={t(
                            "features.setup.onboarding.creation.insufficientPermissionsTooltip",
                          )}
                          isHidden={isEditable}
                          arrow
                        >
                          <FormControlLabel
                            control={
                              variant === "checkbox" ? (
                                <Checkbox
                                  checked={!!selectedPermissions[name]}
                                  onChange={(e) =>
                                    onChangeSelectedPermissions({
                                      [name]: e.target.checked,
                                    } as Record<Permission, boolean>)
                                  }
                                />
                              ) : (
                                <Switch
                                  checked={!!selectedPermissions[name]}
                                  onChange={(e) =>
                                    onChangeSelectedPermissions({
                                      [name]: e.target.checked,
                                    } as Record<Permission, boolean>)
                                  }
                                />
                              )
                            }
                            label={autoLabel(name)}
                            value={name}
                            disabled={!isEditable || loading}
                            style={{marginRight: 4}}
                          />
                        </OptionalTooltip>
                        {isEditable && (
                          <Tooltip title={description}>
                            <InfoIcon color="disabled" fontSize="small" />
                          </Tooltip>
                        )}
                      </Grid>
                    )
                  })}
                </Grid>
              </CardContent>
            </Card>
          )
        })}
      </Stack>
    </FormGroup>
  )
}
