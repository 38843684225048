import {Visibility, VisibilityOff} from "@mui/icons-material"
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material"
import {FormEventHandler, useCallback, useState} from "react"
import {completeOnboarding} from "../actionCreators"
import {useTypedDispatch} from "src/hooks/redux"
import BusyButton from "src/components/BusyButton"
import {useNavigate, useParams} from "react-router-dom"
import {useTranslation} from "react-i18next"

export const CompleteOnboardingForm = () => {
  const dispatch = useTypedDispatch()
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {token} = useParams<{token: string}>()

  const [passwordState, setPasswordState] = useState({
    value: "",
    error: false,
    blurred: false,
  })
  const [confirmPasswordState, setConfirmPasswordState] = useState({
    value: "",
    error: false,
    blurred: false,
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault()
      try {
        setIsSubmitting(true)
        await dispatch(
          completeOnboarding({
            password: passwordState.value,
            token: token ?? "",
          }),
        )
        navigate("/")
      } finally {
        setIsSubmitting(false)
      }
    },
    [dispatch, navigate, passwordState.value, token],
  )

  if (!token) {
    return (
      <Alert severity="error">
        {t("features.setup.onboarding.completion.invalidLink")}
      </Alert>
    )
  }

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <Typography>
          {t("features.setup.onboarding.completion.welcome")}
        </Typography>

        <PasswordInput
          label="Password"
          helperText={t("features.setup.onboarding.completion.passwordHelper")}
          onChange={(e) =>
            setPasswordState((s) => ({
              ...s,
              value: e.target.value,
              error: s.blurred ? !passwordIsValid(e.target.value) : false,
            }))
          }
          onBlur={() =>
            setPasswordState((s) => ({
              ...s,
              error: !passwordIsValid(s.value),
              blurred: true,
            }))
          }
          error={passwordState.error}
          disabled={isSubmitting}
        />
        <PasswordInput
          label={t("features.setup.onboarding.completion.confirmPassword")}
          onChange={(e) =>
            setConfirmPasswordState((s) => ({
              ...s,
              value: e.target.value,
              error: s.blurred ? e.target.value !== passwordState.value : false,
            }))
          }
          onBlur={() =>
            setConfirmPasswordState((s) => ({
              ...s,
              error: s.value !== passwordState.value,
              blurred: true,
            }))
          }
          error={confirmPasswordState.error}
          helperText={
            confirmPasswordState.error
              ? t("features.setup.onboarding.completion.doNotMatch")
              : undefined
          }
          disabled={isSubmitting}
        />

        <BusyButton
          variant="contained"
          disabled={
            !passwordIsValid(passwordState.value) ||
            confirmPasswordState.value !== passwordState.value
          }
          type="submit"
          busy={isSubmitting}
        >
          {t("features.setup.onboarding.completion.submit")}
        </BusyButton>
      </Stack>
    </Box>
  )
}

const passwordIsValid = (password: string) =>
  password.length >= 8 &&
  [/[a-z]/, /[A-Z]/, /[0-9]/, /[!#$%*+-/:;=@^_|~]/].every((r) =>
    r.test(password),
  )

const PasswordInput = (props: Omit<TextFieldProps, "InputProps" | "type">) => {
  const [visible, setVisible] = useState(false)
  const toggleVisibility = useCallback(() => setVisible((v) => !v), [])

  return (
    <TextField
      {...props}
      type={visible ? "text" : "password"}
      required
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={toggleVisibility}>
              {visible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
