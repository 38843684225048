import {green, grey, red, orange} from "@mui/material/colors"
import Badge from "src/components/Badge"
import {TicketStatus} from "src/types/apollo"

interface Props {
  status: TicketStatus
}

export const statusColorMap = {
  PENDING: grey[400],
  ACTIVE: green[300],
  EXPIRED: grey[700],
  INVALID: red[400],
  QUERIED: orange[400],
}

export default ({status}: Props) => (
  <Badge text={status} backgroundColor={statusColorMap[status]} />
)
