import {Link, useMediaQuery, useTheme} from "@mui/material"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import Toolbar from "@mui/material/Toolbar"
import {useLocation} from "react-router-dom"
import candideLogoWhite from "../images/icons/candide-white.svg"
import candideLogo from "../images/icons/candide.svg"
import "./Sidebar.css"
import SidebarNavList from "./SidebarNavList"

export default function Sidebar() {
  const {pathname} = useLocation()
  const theme = useTheme()
  const isCompactLayout = useMediaQuery(theme.breakpoints.down("md"))

  if (
    pathname.includes("/ticket-codes/gatehouse") ||
    pathname.includes("/check-in/self")
  ) {
    return null
  }

  if (isCompactLayout) {
    return null
  }

  return (
    <Drawer variant="permanent" classes={{paper: "sidebar__drawer-paper"}}>
      <Toolbar onClick={() => null}>
        <Link href={"/"}>
          <img
            alt=""
            src={
              theme.palette.mode === "light" ? candideLogo : candideLogoWhite
            }
            width={120}
            style={{alignSelf: "center", marginRight: 20}}
          />
        </Link>
        <Divider absolute />
      </Toolbar>

      <SidebarNavList />
    </Drawer>
  )
}
