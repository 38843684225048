export function formatPrice(currencyCode: string, amount: number): string {
  const formattedAmount = subUnitToSuperUnit(amount)
  return new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: formattedAmount % 100 === 0 ? 0 : undefined,
  }).format(formattedAmount)
}

function subUnitToSuperUnit(amount: number): number {
  return amount / 100
}
