import {InMemoryCache} from "@apollo/client"

export const createCache = () => {
  const cache = new InMemoryCache({
    addTypename: true,
    possibleTypes: {},
    typePolicies: {
      User: {
        merge: true,
      },
      AuthSuccess: {
        merge: true,
      },
      AuthError: {
        merge: (current, incoming, {cache: cacheObj}) => {
          if (incoming.type === "INVALID_TOKEN") {
            cacheObj.reset()
            return incoming
          }
          return current
        },
      },
      Query: {
        fields: {},
      },
    },
  })

  return {cache}
}
