import {lazy} from "react"
import {RouteInfo} from "src/types"
import {Root} from "./pages/Root"

export const routeInfo: RouteInfo[] = [
  {
    path: "/",
    component: Root,
  },
  {
    path: "/new",
    component: lazy(() => import("./pages/OnboardBusinessPage")),
  },
]
