import {
  PropsWithChildren,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react"
import styled from "styled-components"

import CloseIcon from "@mui/icons-material/Close"
import DeleteIcon from "@mui/icons-material/Delete"
import DragHandleIcon from "@mui/icons-material/DragHandle"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import {Box} from "@mui/material"
import {ApolloError} from "@apollo/client"
import produce from "immer"
import {DndProvider, DropTargetMonitor, useDrag, useDrop} from "react-dnd"
import {HTML5Backend} from "react-dnd-html5-backend"
import ErrorDisplay from "src/components/ErrorDisplay"
import {useTicketProductsForBusiness} from "src/features/tickets/ticketProductsForBusinessQuery"
import {
  GetTicketQuery,
  Ticket,
  TicketProductsForBusinessQuery,
  useUpdateTicketTicketTypesMutation,
} from "src/types/apollo"
import {v4} from "uuid"
import BusyButton from "../../../components/BusyButton"
import {useTranslation} from "react-i18next"

interface Props {
  modalOpen: boolean
  toggleModal: () => void
  ticket: Ticket
  onSuccess: () => void
}

type TicketProductsForBusiness_products =
  TicketProductsForBusinessQuery["products"][number]
type TicketProductsForBusiness_products_ticketTypes =
  TicketProductsForBusiness_products["ticketTypes"][number]
type TicketProductsForBusiness_products_ticketTypes_ticketType =
  TicketProductsForBusiness_products_ticketTypes["ticketType"]
type Ticket_ticketTypes = NonNullable<
  GetTicketQuery["tickets"][number]
>["ticketTypes"][number]
type Ticket_ticketTypes_ticketType = Ticket_ticketTypes["ticketType"]
type Ticket_ticketTypes_visitors = Ticket_ticketTypes["visitors"][number]
type Ticket_ticketTypes_visitors_visitorType =
  Ticket_ticketTypes_visitors["visitorType"]

type TicketTicketTypeToUpdateWithTmpId = Omit<
  Ticket_ticketTypes,
  "visitors" | "__typename" | "id" | "ticketType"
> & {
  id: string | null
  tmpId: string
} & {
  ticketType: Pick<Ticket_ticketTypes_ticketType, "id">
  visitors: Array<
    Omit<
      Ticket_ticketTypes_visitors,
      "__typename" | "id" | "isPrimary" | "shares" | "visitorType"
    > & {
      id: string | null
      tmpId: string
      visitorType: Omit<Ticket_ticketTypes_visitors_visitorType, "__typename">
    }
  >
}

export default function UpdateTicketVisitorsModal({
  modalOpen,
  onSuccess,
  ticket,
  toggleModal,
}: Props) {
  const {t} = useTranslation()
  const {
    data,
    loading: ticketProductsForBusinessLoading,
    error: ticketProductsForBusinessError,
  } = useTicketProductsForBusiness()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const errorHandler = useCallback(
    (err: ApolloError) => {
      const errorMessageHash = {
        InvalidTicketTypes: t(
          "features.tickets.ticketTypes.errors.invalidTicketTypes",
        ),
        ExtendOrCancelExistingSubscription: t(
          "features.tickets.ticketTypes.errors.extendOrCancelExistingSubscription",
        ),
        HasActiveShare: t("features.tickets.ticketTypes.errors.hasActiveShare"),
      }
      if (err && err.graphQLErrors && err.graphQLErrors.length) {
        const errorKey = err.graphQLErrors[0].message
        if (errorMessageHash[errorKey as keyof typeof errorMessageHash]) {
          setErrorMessage(
            errorMessageHash[errorKey as keyof typeof errorMessageHash],
          )
        } else {
          setErrorMessage(errorKey)
        }
      }
    },
    [setErrorMessage, t],
  )
  const [updateTicketTicketTypes, {loading}] =
    useUpdateTicketTicketTypesMutation({
      onError: errorHandler,
      onCompleted: onSuccess,
      refetchQueries: ["GetTicket"],
    })

  if (ticketProductsForBusinessLoading) {
    return <CircularProgress size={24} />
  }

  if (ticketProductsForBusinessError || !data?.products) {
    return (
      <Typography color="textSecondary" variant="body2">
        {t("features.setup.products.errors.loading")}
      </Typography>
    )
  }

  const product = data.products.find((p) => p.id === ticket.product.id)

  if (product === undefined) {
    return (
      <Typography color="textSecondary" variant="body2">
        {t("features.setup.products.errors.notFound")}
      </Typography>
    )
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Dialog onClose={toggleModal} open={modalOpen} fullWidth maxWidth="md">
        <DialogTitle>
          <Typography variant="h6">
            {t("features.tickets.ticketTypes.update")}
          </Typography>
          <div style={{position: "absolute", top: "8px", right: "8px"}}>
            <IconButton onClick={toggleModal} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent
          ticket={ticket}
          onUpdate={async (ticketTicketTypes) => {
            await updateTicketTicketTypes({
              variables: {
                ticketId: ticket.id,
                ticketTicketTypes: ticketTicketTypes.map((ttt) => ({
                  id: ttt.id,
                  ticketTypeId: ttt.ticketType.id,
                  visitors: ttt.visitors.map((v) => ({
                    id: v.id,
                    firstName: v.firstName,
                    lastName: v.lastName,
                    externalUserId: v.externalUserId,
                    visitorTypeId: v.visitorType.id,
                  })),
                })),
              },
            })
          }}
          loading={loading}
          errorMessage={errorMessage}
          product={product}
        />
      </Dialog>
    </DndProvider>
  )
}

const DialogContent = ({
  ticket,
  onUpdate,
  loading,
  errorMessage,
  product,
}: {
  ticket: Ticket
  onUpdate: (
    ticketTicketTypes: Array<TicketTicketTypeToUpdateWithTmpId>,
  ) => void
  loading: boolean
  errorMessage: string | null
  product: TicketProductsForBusiness_products
}) => {
  const {t} = useTranslation()
  const [ticketTicketTypes, setTicketTicketTypes] = useState<
    Array<TicketTicketTypeToUpdateWithTmpId>
  >(
    ticket.ticketTypes.map((tt) => ({
      __typename: "TicketTicketType",
      ticketType: {
        __typename: "TicketType",
        id: tt.ticketType.id,
        name: "",
        deletedAt: "",
      },
      id: tt.id,
      tmpId: v4(),
      visitors: tt.visitors.map((v) => ({
        __typename: "TicketVisitor",
        visitorType: {
          __typename: "VisitorType",
          id: v.visitorType.id,
          name: v.visitorType.name,
          deletedAt: v.visitorType.deletedAt,
        },
        id: v.id,
        tmpId: v4(),
        firstName: v.firstName,
        lastName: v.lastName,
        externalUserId: v.externalUserId,
      })),
    })),
  )

  const existingVisitorShares = useMemo(
    () =>
      ticket.ticketTypes.flatMap((tt) =>
        tt.visitors.flatMap((v) =>
          v.shares.map((s) => ({visitorId: v.id, ...s})),
        ),
      ),
    [ticket.ticketTypes],
  )

  return (
    <div
      style={{
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      {ticketTicketTypes.map((ttt, i) => (
        <TicketTicketTypeRow
          key={ttt.tmpId}
          ticketTicketType={ttt}
          isPrimaryTicketTicketType={i === 0}
          onUpdate={(updatedValues) =>
            setTicketTicketTypes((values) =>
              produce(values, (draft) => {
                draft[i] = {...draft[i], ...updatedValues}
              }),
            )
          }
          onDragHover={({id}) =>
            setTicketTicketTypes((values) =>
              produce(values, (draft) => {
                const index = draft.findIndex(({tmpId}) => tmpId === id)
                const item = draft[index]
                draft.splice(index, 1)
                draft.splice(i, 0, item)
              }),
            )
          }
          dragObject={{id: ttt.tmpId}}
          onDelete={
            ttt.visitors.some((v) =>
              existingVisitorShares.find(
                (s) =>
                  s.visitorId === v.id &&
                  s.claimedAt == null &&
                  s.invalidatedAt == null,
              ),
            )
              ? null
              : () =>
                  setTicketTicketTypes((values) =>
                    produce(values, (draft) => {
                      draft.splice(i, 1)
                    }),
                  )
          }
          showDragHandle={ticketTicketTypes.length > 1}
          ticketTypes={product.ticketTypes}
        />
      ))}

      <Button
        style={{}}
        onClick={() => {
          const ticketType = product.ticketTypes[0]

          if (!ticketType) {
            return
          }

          setTicketTicketTypes((values) => [
            ...values,
            {
              id: null,
              tmpId: v4(),
              ticketType: {id: ticketType.id},
              visitors: visitorsFromTicketType(ticketType.ticketType),
            },
          ])
        }}
      >
        {t("features.tickets.ticketTypes.add")}
      </Button>

      {errorMessage && (
        <ModalMessage>
          <ErrorDisplay>{errorMessage}</ErrorDisplay>
        </ModalMessage>
      )}
      <div style={{marginTop: 16}}>
        <BusyButton
          color="primary"
          variant="contained"
          disabled={ticketTicketTypes.length === 0}
          busy={loading}
          onClick={() => onUpdate(ticketTicketTypes)}
        >
          {t("actions.update")}
        </BusyButton>
      </div>
    </div>
  )
}

const ModalMessage = styled.div`
  padding-bottom: 16px;
`

const visitorsFromTicketType = (
  ticketType: TicketProductsForBusiness_products_ticketTypes_ticketType,
) =>
  ticketType.visitorTypes.flatMap((v) =>
    Array(v.quantity)
      .fill(null)
      // Use map instead of filling directly for type inference
      .map(() => ({
        visitorType: {
          id: v.visitorType.id,
          name: v.visitorType.name,
          deletedAt: v.visitorType.deletedAt,
        },
        id: null,
        isPrimary: false,
        tmpId: v4(),
        firstName: "",
        lastName: "",
        externalUserId: "",
      })),
  )

type DragObject = {
  id: string
}

const TicketTicketTypeRow = ({
  onUpdate,
  onDelete,
  onDragHover,
  dragObject,
  ticketTicketType,
  ticketTypes,
  isPrimaryTicketTicketType,
  showDragHandle,
}: {
  ticketTicketType: TicketTicketTypeToUpdateWithTmpId
  onUpdate: (
    ticketTicketType: Partial<TicketTicketTypeToUpdateWithTmpId>,
  ) => void
  onDelete: (() => void) | null
  onDragHover: (dragObject: DragObject) => void
  dragObject: DragObject
  ticketTypes: Array<TicketProductsForBusiness_products_ticketTypes>
  isPrimaryTicketTicketType: boolean
  showDragHandle: boolean
}) => {
  const {t} = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const [, dropRef] = useDrop({
    accept: "TicketType",
    hover: restrictDragHoverCallbackRegion(onDragHover, {
      regionScaleFactor: 0.5,
      dragObject,
      ref,
    }),
  })

  const [{isDragging}, dragRef, dragPreviewRef] = useDrag({
    type: "TicketType",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: dragObject,
  })

  dragPreviewRef(dropRef(ref))

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        opacity: isDragging ? 0 : 1,
      }}
      ref={ref}
    >
      <Select
        style={{height: "fit-content"}}
        value={ticketTicketType.ticketType.id}
        onChange={(e) => {
          const ticketType = ticketTypes.find(
            (tt) => tt.ticketType.id === e.target.value,
          )

          if (!ticketType) {
            return
          }

          onUpdate({
            id: null,
            ticketType: {id: e.target.value as string},
            visitors: visitorsFromTicketType(ticketType.ticketType),
          })
        }}
      >
        {ticketTypes.map((tt) => (
          <MenuItem key={tt.ticketType.id} value={tt.ticketType.id}>
            {tt.ticketType.name}
          </MenuItem>
        ))}
      </Select>
      <Table>
        {ticketTicketType.visitors.map((v, i) => (
          <VisitorRow
            key={v.tmpId}
            visitor={v}
            isPrimaryVisitor={isPrimaryTicketTicketType && i === 0}
            dragAcceptType={ticketTicketType.tmpId}
            onUpdate={(updatedValues) =>
              onUpdate({
                visitors: produce(ticketTicketType.visitors, (draft) => {
                  draft[i] = {...draft[i], ...updatedValues}
                }),
              })
            }
            dragObject={{id: v.tmpId}}
            onDragHover={({id}) =>
              onUpdate({
                visitors: produce(ticketTicketType.visitors, (draft) => {
                  const index = draft.findIndex(({tmpId}) => tmpId === id)
                  const item = draft[index]
                  draft.splice(index, 1)
                  draft.splice(i, 0, item)
                }),
              })
            }
            showDragHandle={ticketTicketType.visitors.length > 1}
          />
        ))}
      </Table>

      <Tooltip
        title={onDelete ? "" : "Cannot delete while there are active shares"}
      >
        <span>
          <IconButton
            size="small"
            aria-label="delete"
            disabled={onDelete == null}
            onClick={() => {
              onDelete?.()
            }}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>

      {showDragHandle && (
        <Tooltip
          title={t("features.tickets.update.dragToRearrange")}
          enterDelay={700}
          enterNextDelay={700}
        >
          <span>
            <IconButton ref={dragRef}>
              <DragHandleIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </div>
  )
}

const emptyStringToNull = (str: string) => (str.length === 0 ? null : str)

const VisitorRow = ({
  visitor,
  onUpdate,
  onDragHover,
  dragObject,
  dragAcceptType,
  showDragHandle,
  isPrimaryVisitor,
}: {
  visitor: TicketTicketTypeToUpdateWithTmpId["visitors"][number]
  onUpdate: (
    visitor: Partial<TicketTicketTypeToUpdateWithTmpId["visitors"][number]>,
  ) => void
  onDragHover: (dragObject: DragObject) => void
  dragObject: DragObject
  dragAcceptType: string
  showDragHandle: boolean
  isPrimaryVisitor: boolean
}) => {
  const {t} = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const [, dropRef] = useDrop({
    accept: dragAcceptType,
    hover: restrictDragHoverCallbackRegion(onDragHover, {
      dragObject,
      ref,
      regionScaleFactor: 0.2,
    }),
  })

  const [{isDragging}, dragRef, dragPreviewRef] = useDrag({
    type: dragAcceptType,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: dragObject,
  })

  dragPreviewRef(dropRef(ref))

  return (
    <PrimaryVisitorOutline isPrimary={isPrimaryVisitor}>
      <div ref={ref}>
        <TableRow
          sx={{
            "& > *": {
              borderBottom: "unset",
            },
          }}
          style={{
            opacity: isDragging ? 0 : 1,
          }}
        >
          <TableCell>
            <TextField
              value={visitor.firstName}
              onChange={(e) =>
                onUpdate({firstName: emptyStringToNull(e.target.value)})
              }
              label={t("components.labels.firstName")}
              variant="outlined"
              size="small"
            />
          </TableCell>
          <TableCell>
            <TextField
              value={visitor.lastName}
              onChange={(e) =>
                onUpdate({lastName: emptyStringToNull(e.target.value)})
              }
              label={t("components.labels.lastName")}
              variant="outlined"
              size="small"
            />
          </TableCell>
          <TableCell>
            <TextField
              value={visitor.externalUserId}
              onChange={(e) =>
                onUpdate({externalUserId: emptyStringToNull(e.target.value)})
              }
              type="number"
              label={t("components.labels.customerId")}
              variant="outlined"
              size="small"
            />
          </TableCell>
          {showDragHandle && (
            <TableCell>
              <Tooltip
                title={t("features.tickets.update.dragToRearrange")}
                enterDelay={700}
                enterNextDelay={700}
              >
                <span>
                  <IconButton ref={dragRef}>
                    <DragHandleIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </TableCell>
          )}
        </TableRow>
      </div>
    </PrimaryVisitorOutline>
  )
}

const PrimaryVisitorOutline = ({
  isPrimary,
  children,
}: PropsWithChildren<{isPrimary: boolean}>) => {
  const {t} = useTranslation()
  const theme = useTheme()
  return (
    <Box
      position="relative"
      border={isPrimary ? "red 2px dashed" : ""}
      borderRadius={2}
      p={1}
      m={1}
    >
      {isPrimary && (
        <Box
          position="absolute"
          top={-12}
          left={8}
          bgcolor={theme.palette.background.paper}
          px={0.5}
        >
          <Typography variant="caption" color="red">
            {t("features.tickets.update.primaryUser")}
          </Typography>
        </Box>
      )}
      {children}
    </Box>
  )
}

/**
 * Restricts the size of the bounding box (along the y-axis) where an item can be dropped,
 * this is important to prevent items from repeatedly switching (i.e. after switching, the
 * new positions may cause another switch, which leads to the items jumping back and forth).
 *
 * @param param1.regionScaleFactor The factor by which to scale the y-axis of the droppable
 * bounding box. Should be in (0,1).
 */
const restrictDragHoverCallbackRegion =
  (
    onDragHover: (item: DragObject) => void,
    {
      regionScaleFactor,
      ref,
      dragObject,
    }: {
      regionScaleFactor: number
      ref: RefObject<HTMLElement>
      dragObject: DragObject
    },
  ) =>
  (item: DragObject, monitor: DropTargetMonitor<DragObject, unknown>) => {
    if (ref.current == null || item.id === dragObject.id) {
      return
    }

    // getClientOffset returns null only if no drag is in progress, but one must be in progress for the hover
    // state to trigger
    const {y: currentMouseY} = monitor.getClientOffset()!
    const targetBoundingBox = ref.current.getBoundingClientRect()
    const heightScaleOffset = (targetBoundingBox.height * regionScaleFactor) / 2
    const droppableYRange = {
      bottom: targetBoundingBox.bottom - heightScaleOffset,
      top: targetBoundingBox.top + heightScaleOffset,
    }

    // Note that the origin for screen coordinates is in the top-left of the screen, so the bottom has a greater
    // y-position than the top
    if (
      currentMouseY <= droppableYRange.bottom &&
      currentMouseY >= droppableYRange.top
    ) {
      onDragHover(item)
    }
  }
