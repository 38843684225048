import {
  Box,
  FormGroup,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material"
import Icon from "@mui/material/Icon"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import React, {useContext, useState} from "react"
import {useTranslation} from "react-i18next"
import {useDispatch} from "react-redux"
import {Link} from "react-router-dom"
import {ColorModeContext} from "src/App"
import {logOut} from "src/features/auth"
import {useUserPermissions} from "src/features/auth/hooks/useUserPermissions"
import styled from "styled-components"
import T from "../textResources"
import CurrentUser from "./CurrentUser"
import sidebarStructure, {isSection, type SidebarItem} from "./sidebarStructure"
import {useSideNavBusinessConfigQuery} from "src/types"

const STORAGE_KEY = "sidebarExpandedMenuItemsRecord"

export default function SidebarNavList() {
  const colorMode = useContext(ColorModeContext)
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const permissions = useUserPermissions()
  const [expanded, setExpanded] = useState<Record<string, boolean | undefined>>(
    JSON.parse(localStorage.getItem(STORAGE_KEY) || "{}"),
  )
  const {data: businessConfigData} = useSideNavBusinessConfigQuery()

  const isEnabled = (item: SidebarItem): boolean => {
    if (item.enabled === undefined) {
      return true
    }

    return item.enabled({
      permissions,
      businessConfig: businessConfigData?.me.associatedBusiness,
    })
  }

  const toggleSection = (key: string) => {
    const newExpanded = {
      ...expanded,
      [key]: !expanded[key],
    }

    localStorage.setItem(STORAGE_KEY, JSON.stringify(newExpanded))
    setExpanded(newExpanded)
  }

  return (
    <DrawerList>
      <List style={{flexGrow: 1}}>
        {sidebarStructure.map((sidebarSectionOrItem, index) =>
          isSection(sidebarSectionOrItem) ? (
            <React.Fragment key={index}>
              {sidebarSectionOrItem.children.filter(isEnabled).length > 0 ? (
                <ListItem
                  disableGutters
                  button
                  style={{padding: "8px 16px"}}
                  onClick={() =>
                    toggleSection(sidebarSectionOrItem.localStorageExpandKey)
                  }
                >
                  <ListItemText
                    primary={
                      <Stack direction={"row"} spacing={1}>
                        {sidebarSectionOrItem.Icon ? (
                          <sidebarSectionOrItem.Icon />
                        ) : null}
                        <Box flexGrow={1}>{sidebarSectionOrItem.title}</Box>
                        <Icon>
                          {expanded[sidebarSectionOrItem.localStorageExpandKey]
                            ? "expand_less"
                            : "expand_more"}
                        </Icon>
                      </Stack>
                    }
                  />
                </ListItem>
              ) : null}
              {expanded[sidebarSectionOrItem.localStorageExpandKey]
                ? sidebarSectionOrItem.children.map((child, childIndex) => {
                    if (!isEnabled(child)) {
                      return null
                    }

                    return (
                      <ListItem
                        key={`${index}-${childIndex}`}
                        disableGutters
                        button
                        style={{padding: "0"}}
                        component={Link}
                        {...{to: child.path}}
                      >
                        <ListItemText
                          style={{paddingLeft: 32}}
                          primary={<small> {child.title} </small>}
                        />
                      </ListItem>
                    )
                  })
                : null}
            </React.Fragment>
          ) : isEnabled(sidebarSectionOrItem) ? (
            <ListItem
              key={index}
              disableGutters
              button
              style={{padding: "8px 16px"}}
              component={Link}
              {...{to: sidebarSectionOrItem.path}}
            >
              <ListItemText
                primary={
                  <div style={{display: "flex", alignItems: "center"}}>
                    {sidebarSectionOrItem.Icon ? (
                      <sidebarSectionOrItem.Icon />
                    ) : null}
                    {sidebarSectionOrItem.title}
                  </div>
                }
              />
            </ListItem>
          ) : null,
        )}
      </List>
      <FormGroup style={{marginLeft: 16}}>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="caption" color="textSecondary">
              {t("components.sidebar.darkMode")}
            </Typography>
            <Switch
              size="small"
              checked={theme.palette.mode === "dark"}
              onChange={colorMode.toggleColorMode}
            />
          </Stack>

          <Typography variant="caption" color="textSecondary">
            <CurrentUser />
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            onClick={() => dispatch(logOut())}
          >
            <FakeLink>{T.auth.logOutButtonText}</FakeLink>
          </Typography>
        </Stack>
      </FormGroup>
    </DrawerList>
  )
}

const DrawerList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding-bottom: 16px;
`

const FakeLink = styled.span`
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }
`
