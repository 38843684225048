import {useSelector} from "react-redux"
import {Permission} from "src/types/apollo"
import {AuthState} from "../types"

export function useUserPermission(permission: Permission): boolean {
  return useSelector(
    (state: {auth: AuthState}) => state.auth.permissions,
  ).includes(permission)
}

export function useUserPermissions(): string[] {
  return useSelector((state: {auth: AuthState}) => state.auth.permissions)
}

export function useAdmissionsAdminPermission(): boolean {
  return useSelector(
    (state: {auth: AuthState}) => state.auth.permissions,
  ).includes(Permission.CmsAdministerTicketing)
}
