import {
  Button,
  CircularProgress,
  IconButton,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import {Alert} from "@mui/material"
import produce from "immer"
import * as R from "ramda"
import React, {useEffect, useState} from "react"
import {TicketProductsForBusinessQuery, GetTicketQuery} from "src/types/apollo"
import {useTicketProductsForBusiness} from "src/features/tickets/ticketProductsForBusinessQuery"
import {useTranslation} from "react-i18next"

const MAX_NAME_LENGTH = 35 // this is determined by some validation on the back end where names longer than this aren't valid for gift aid reporting

type Ticket_ticketTypes = NonNullable<
  GetTicketQuery["tickets"][number]
>["ticketTypes"][number]
type Visitors = Ticket_ticketTypes["visitors"]

type TicketType = Omit<Ticket_ticketTypes, "id" | "visitors"> & {
  visitors: Visitors
}

type TicketTypeHash = Record<
  string,
  {
    ticketTypeId: string
    name: string
    deletedAt: string | null
    visitorTypeIds: Array<string>
  }
>

const TICKET_TYPE_DEFAULT = ""

export const UpdateTicketSelector = ({
  initialTicketTypes,
  setTicketTypes,
  productId,
  subscriptionData,
  setProductId,
}: {
  initialTicketTypes: Array<Ticket_ticketTypes>
  setTicketTypes: React.Dispatch<React.SetStateAction<Array<TicketType>>>
  productId: string
  subscriptionData: {
    stripeSubscriptionId: string | null
    renewalIsCancelled: boolean
  }
  setProductId?: React.Dispatch<React.SetStateAction<string>>
}) => {
  const {t} = useTranslation()
  const {
    data,
    loading: ticketProductsForBusinessLoading,
    error: ticketProductsForBusinessError,
  } = useTicketProductsForBusiness()

  if (ticketProductsForBusinessLoading) {
    return <CircularProgress size={24} />
  }

  if (ticketProductsForBusinessError || !data?.products) {
    return (
      <Typography color="textSecondary" variant="body2">
        {t("features.setup.products.error.loading")}
      </Typography>
    )
  }

  const products = data.products

  const selectedProduct = products.find((p) => p.id === productId)

  if (selectedProduct === undefined) {
    return (
      <Typography color="textSecondary" variant="body2">
        {t("features.setup.products.error.notFound")}
      </Typography>
    )
  }

  const ticketTypeHash = {
    [TICKET_TYPE_DEFAULT]: {
      ticketTypeId: "",
      name: "",
      deletedAt: null,
      visitorTypeIds: [],
    },
    ...selectedProduct.ticketTypes.reduce(
      (acc, {ticketType}) => ({
        ...acc,
        [ticketType.id]: {
          ticketTypeId: ticketType.id,
          name: ticketType.name,
          deletedAt: ticketType.deletedAt,
          visitorTypeIds: ticketType.visitorTypes.flatMap((vt) =>
            R.repeat(vt.visitorType.id, vt.quantity),
          ),
        },
      }),
      {} as TicketTypeHash,
    ),
  }

  const isSubscriptionTicket =
    subscriptionData.stripeSubscriptionId !== null &&
    subscriptionData.renewalIsCancelled === false

  return (
    <>
      {!!setProductId && (
        <ProductSelector
          products={products}
          currentProductId={productId}
          setProductId={setProductId}
        />
      )}

      <TicketTypeTable
        initialTicketTypes={initialTicketTypes.filter(
          ({ticketType}) => ticketType.deletedAt === null,
        )}
        setTicketTypes={setTicketTypes}
        ticketTypeHash={ticketTypeHash}
        productId={productId}
        isEditable={!isSubscriptionTicket}
      />
    </>
  )
}

const TicketTypeTable = ({
  initialTicketTypes,
  setTicketTypes,
  ticketTypeHash,
  productId,
  isEditable,
}: {
  initialTicketTypes: Array<TicketType>
  setTicketTypes: (ticketTypes: Array<TicketType>) => void
  ticketTypeHash: TicketTypeHash
  productId: string
  isEditable: boolean
}) => {
  const {t} = useTranslation()
  const [initialProductId] = useState(productId)
  const [visitors, setVisitors] = useState<Visitors>(
    initialTicketTypes.flatMap((tt) => tt.visitors),
  )
  const [ticketTypeIds, setTicketTypeIds] = useState(
    initialTicketTypes.map((tt) => tt.ticketType.id),
  )

  const ticketTypes = ticketTypeIds.map(
    (ticketTypeId) => ticketTypeHash[ticketTypeId],
  )

  useEffect(() => {
    // Increase visitor count if needed
    const totalVisitorsForTicketTypes = ticketTypes.flatMap(
      (tt) => tt.visitorTypeIds,
    ).length

    const updatedVisitors = R.concat(
      visitors,
      Array(Math.max(totalVisitorsForTicketTypes - visitors.length, 0)).fill({
        firstName: null,
        lastName: null,
        externalUserId: null,
      }),
    )

    setVisitors(updatedVisitors)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketTypeIds])

  useEffect(() => {
    // Update ticket type input
    setTicketTypes(
      ticketTypes.map(({ticketTypeId, name, deletedAt, visitorTypeIds}, i) => {
        const initialVisitorIndex = ticketTypes.reduce(
          (previousValue, currentValue, j) =>
            j < i
              ? previousValue + currentValue.visitorTypeIds.length
              : previousValue,
          0,
        )

        return {
          __typename: "TicketTicketType",
          id: null,
          ticketType: {
            __typename: "TicketType",
            id: ticketTypeId,
            name,
            deletedAt,
          },
          visitors: R.zipWith(
            (v, visitorTypeId) => ({
              ...v,
              visitorTypeId,
            }),
            visitors.slice(
              initialVisitorIndex,
              initialVisitorIndex + visitorTypeIds.length,
            ),
            visitorTypeIds,
          ),
        }
      }),
    )
    // There's an implicit dependency on ticketTypeIds from the useEffect above,
    // this should always run when ticketTypeIds are changed but only after the
    // visitors array has been extended.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitors])

  useEffect(() => {
    if (productId === initialProductId) {
      return
    }

    // Reset ticket types on product change
    setTicketTypeIds([])
  }, [initialProductId, productId])

  if (ticketTypeIds.some((id) => !Object.keys(ticketTypeHash).includes(id))) {
    return (
      <Typography color="textSecondary" variant="body2">
        {t("features.tickets.ticketTypes.errors.matchingTicketTypes")}
      </Typography>
    )
  }

  const updateVisitors = (startIndex: number) => (values: Visitors) => {
    setVisitors(
      visitors.map((v, i) =>
        i >= startIndex && i < startIndex + values.length
          ? values[i - startIndex]
          : v,
      ),
    )
  }

  const updateTicketTypeId = (index: number) => (ticketTypeId: string) => {
    setTicketTypeIds((ids) =>
      produce(ids, (draft) => {
        draft[index] = ticketTypeId
      }),
    )
  }

  return (
    <div style={{paddingTop: 16}}>
      {ticketTypes.length > 0 ? (
        <>
          {ticketTypes.map((tt, ticketTypeIndex) => {
            const initialVisitorIndex = ticketTypes.reduce(
              (previousValue, currentValue, j) =>
                j < ticketTypeIndex
                  ? previousValue + currentValue.visitorTypeIds.length
                  : previousValue,
              0,
            )
            return (
              <>
                <TicketTypesRow
                  key={ticketTypeIndex}
                  updateTicketTypeId={updateTicketTypeId(ticketTypeIndex)}
                  visitors={visitors.slice(
                    initialVisitorIndex,
                    initialVisitorIndex + tt.visitorTypeIds.length,
                  )}
                  updateVisitors={updateVisitors(initialVisitorIndex)}
                  onDelete={() => {
                    setTicketTypeIds((ids) =>
                      ids.filter((_, index) => ticketTypeIndex !== index),
                    )
                    setVisitors(
                      produce(visitors, (draft) => {
                        draft.splice(
                          initialVisitorIndex,
                          tt.visitorTypeIds.length,
                        )
                      }),
                    )
                  }}
                  isEditable={isEditable}
                  ticketTypeHash={ticketTypeHash}
                  ticketTypeId={tt.ticketTypeId}
                />
              </>
            )
          })}
        </>
      ) : (
        <div style={{display: "flex", justifyContent: "center"}}>
          <Alert severity="error" style={{marginBottom: 16}}>
            <Typography>
              {t("features.tickets.ticketTypes.errors.noTicketTypes")}
            </Typography>
          </Alert>
        </div>
      )}
      {isEditable && (
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Button
            onClick={() => {
              setTicketTypeIds((ids) => [...ids, TICKET_TYPE_DEFAULT])
            }}
          >
            {t("features.tickets.ticketTypes.add")}
          </Button>
        </div>
      )}
    </div>
  )
}

type TicketTypeRowProps = {
  ticketTypeHash: TicketTypeHash
  ticketTypeId: string
  updateTicketTypeId: (id: string) => void
  visitors: Visitors
  updateVisitors: (visitors: Visitors) => void
  onDelete: () => void
  isEditable: boolean
}

const TicketTypesRow = ({
  ticketTypeHash,
  ticketTypeId,
  updateTicketTypeId,
  visitors,
  updateVisitors,
  onDelete,
  isEditable,
}: TicketTypeRowProps) => {
  const {t} = useTranslation()
  const ticketType = ticketTypeHash[ticketTypeId]

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        marginBottom: "1.5rem",
      }}
    >
      <div
        style={{
          marginRight: 16,
          width: "120px",
        }}
      >
        {isEditable ? (
          <Select
            value={ticketType.ticketTypeId}
            onChange={(e) => {
              updateTicketTypeId(e.target.value as string)
            }}
            native={true}
          >
            {Object.values(ticketTypeHash).map((tt) => {
              return (
                <option key={tt.ticketTypeId} value={tt.ticketTypeId}>
                  {tt.name}
                </option>
              )
            })}
          </Select>
        ) : (
          <Typography style={{paddingTop: 8}}>{ticketType.name}</Typography>
        )}
      </div>
      <div>
        {visitors.map((vt, i) => (
          <div
            key={i}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginBottom: 16,
            }}
          >
            <div style={{marginRight: 16, width: "200px"}}>
              <TextField
                size="small"
                variant="outlined"
                label={t("components.labels.firstName")}
                value={vt.firstName}
                disabled={ticketType.deletedAt !== null}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{maxLength: MAX_NAME_LENGTH}}
                onChange={(e) => {
                  updateVisitors(
                    produce(visitors, (draft) => {
                      draft[i].firstName = e.target.value
                    }),
                  )
                }}
              />
            </div>
            <div style={{marginRight: 16, width: "200px"}}>
              <TextField
                size="small"
                variant="outlined"
                label={t("components.labels.lastName")}
                value={vt.lastName}
                disabled={ticketType.deletedAt !== null}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{maxLength: MAX_NAME_LENGTH}}
                onChange={(e) => {
                  updateVisitors(
                    produce(visitors, (draft) => {
                      draft[i].lastName = e.target.value
                    }),
                  )
                }}
              />
            </div>
            <div style={{marginRight: 16, width: "100px"}}>
              <TextField
                size="small"
                variant="outlined"
                label={t("components.labels.customerId")}
                type="number"
                value={vt.externalUserId}
                disabled={ticketType.deletedAt !== null}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  updateVisitors(
                    produce(visitors, (draft) => {
                      draft[i].externalUserId = e.target.value
                    }),
                  )
                }}
              />
            </div>
          </div>
        ))}
      </div>
      {isEditable && (
        <div>
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => {
              onDelete()
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      )}
    </div>
  )
}

const ProductSelector = ({
  products,
  currentProductId,
  setProductId,
}: {
  products: TicketProductsForBusinessQuery["products"] | undefined
  currentProductId: string
  setProductId: React.Dispatch<React.SetStateAction<string>>
}) => {
  if (!products) {
    return (
      <Select disabled>
        <option value=""></option>
      </Select>
    )
  }

  return (
    <Select
      value={currentProductId}
      onChange={(e) => {
        setProductId(e.target.value as string)
      }}
      native
    >
      {products.map(({id, name}) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  )
}
