import {Link, Typography} from "@mui/material"

function isSet(value?: string | null) {
  return value !== null && value !== undefined && value.length > 0
}

export type VisitorForDisplay = {
  firstName?: string | null
  lastName?: string | null
  externalUserId?: string | null
}

export default function getTicketTypeTicketVisitorsDisplay(
  visitors: Array<VisitorForDisplay>,
): Array<string> {
  return visitors
    .filter((v) => isSet(v.firstName) && isSet(v.lastName))
    .map((visitor) =>
      visitor.externalUserId
        ? `${visitor.firstName} ${visitor.lastName} (${visitor.externalUserId})`
        : `${visitor.firstName} ${visitor.lastName}`,
    )
}

export function getTicketTypeTicketVisitorsWithUserIdDisplay(
  visitors: Array<VisitorForDisplay>,
) {
  return visitors
    .filter((v) => isSet(v.firstName) && isSet(v.lastName))
    .map((visitor) => [
      <Typography>
        {visitor.firstName} {visitor.lastName}
      </Typography>,
      visitor.externalUserId ? (
        <Typography style={{marginLeft: "24px"}}>
          - Customer ID:{" "}
          <Link
            underline="always"
            href={`/customers/${visitor.externalUserId}`}
          >
            {visitor.externalUserId}
          </Link>{" "}
        </Typography>
      ) : null,
    ])
}
