import {ReactNode} from "react"
import Button, {ButtonProps} from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton/IconButton"

interface Props extends ButtonProps {
  busy?: boolean
}

const withBusy =
  (OriginalComponent: any, props: Props) => (child?: ReactNode) => {
    const {busy, onClick, title, ...otherProps} = props
    const orig = (
      <OriginalComponent {...otherProps} onClick={busy ? undefined : onClick}>
        {busy ? (
          <CircularProgress size={20} thickness={6} color="inherit" />
        ) : (
          child
        )}
      </OriginalComponent>
    )

    if (title && !props.disabled) {
      return (
        <Tooltip title={title} placement="top">
          <div>{orig}</div>
        </Tooltip>
      )
    }

    return orig
  }

export const BusyIconButton = (props: Props) =>
  withBusy(IconButton, props)(props.children)

const BusyButton = (props: Props) =>
  withBusy(
    Button,
    props,
  )(
    <div style={{position: "relative"}}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          opacity: props.busy ? 0 : 1,
        }}
      >
        {props.children}
      </div>
      <div
        style={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        }}
      >
        {props.busy && (
          <CircularProgress size={20} thickness={6} color="inherit" />
        )}
      </div>
    </div>,
  )
export default BusyButton
