import T from "../textResources"
import Page from "./Page"
import Typography from "@mui/material/Typography"

export default function NotFoundPage() {
  return (
    <Page title={T.notFoundPage.title}>
      <Typography>{T.notFoundPage.text}</Typography>
    </Page>
  )
}
