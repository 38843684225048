import {Card, CardContent, CardHeader, Container} from "@mui/material"
import {useState} from "react"
import {useTranslation} from "react-i18next"
import {useParams} from "react-router-dom"
import {AuditLogTable} from "src/features/auditLog/controllers/AuditLogTable"
import UserPermissionsModal from "src/features/users/components/UserPermissionsModal"
import withAuth, {authedOrFallback} from "src/features/withAuth"
import {Permission, useUserQuery} from "src/types/apollo"
import Page from "../../../components/Page"

interface State {
  permissionsModalIsOpen: boolean
}

function SuperAdminUserPage() {
  const {t} = useTranslation()
  const {userId} = useParams<{
    userId: string
  }>() as {
    userId: string
  }
  const [state, setState] = useState<State>({permissionsModalIsOpen: false})

  const buttons = [
    {
      type: "button" as const,
      text: t("features.users.permissions.edit"),
      onClick: () => setState({permissionsModalIsOpen: true}),
    },
  ]

  const AuditLogCard = authedOrFallback([Permission.CanAccessAuditLog])(() => (
    <Card>
      <CardHeader title={t("features.auditLog.label")} />
      <CardContent>
        <AuditLogTable
          showFilters
          filterFieldsToHide={["userId"]}
          columnsToHide={["userId"]}
          options={{
            entityType: null,
            entityId: null,
            afterDate: null,
            beforeDate: null,
            userId: userId,
            type: null,
            allBusinesses: true,
          }}
        />
      </CardContent>
    </Card>
  ))

  const {data, loading, error, refetch} = useUserQuery({
    variables: {
      id: [userId],
    },
  })

  if (loading) {
    return <Page loading />
  }

  if (error || !data || !data.users?.length) {
    return <Page error={error || t("features.users.add.noUserFound")} />
  }

  const user = data.users[0]
  return (
    <Page title={user.email} buttons={buttons}>
      <Container>
        <AuditLogCard />
      </Container>
      <UserPermissionsModal
        open={state.permissionsModalIsOpen}
        onClose={() => {
          setState({permissionsModalIsOpen: false})
          refetch()
        }}
        userId={userId}
        email={user.email}
        permissions={user.permissions}
      />
    </Page>
  )
}

export default withAuth([Permission.CmsAdministerTicketing])(SuperAdminUserPage)
