import {AuditLogQuery} from "src/types/apollo"
import Modal from "../../../components/Modal"
import JsonDiff from "./JsonDiff"

interface Props {
  log: AuditLogQuery["auditLog"]["results"][number]
  onClose: () => void
}

export function AuditLogModal(props: Props) {
  const {oldData, newData} = props.log

  return (
    <Modal
      title={"Audit log item"}
      open={true}
      onClose={props.onClose}
      closeButtonText={"Close"}
    >
      <JsonDiff
        oldData={oldData ? JSON.parse(oldData) : undefined}
        newData={newData ? JSON.parse(newData) : undefined}
      />
    </Modal>
  )
}
