import {Box, Typography, useMediaQuery, useTheme} from "@mui/material"

interface Props {
  background?: string
  title: string
  total: number
  FooterComponent?: React.ReactElement
  loading?: boolean
}

export function Scorecard({
  background = "gray",
  title,
  total,
  FooterComponent,
  loading,
}: Props) {
  const theme = useTheme()
  const scoreTextVariant = useMediaQuery(theme.breakpoints.down("sm"))
    ? "h4"
    : "h2"
  const width = useMediaQuery(theme.breakpoints.down("sm")) ? "100%" : "auto"

  return (
    <Box
      padding={2}
      color="white"
      minWidth="150px"
      width={width}
      borderRadius="5px"
      bgcolor={background}
      minHeight="190px"
      sx={{opacity: loading ? "0.7" : undefined}}
    >
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant={scoreTextVariant}>{total}</Typography>
      {FooterComponent}
    </Box>
  )
}
