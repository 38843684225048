import {
  Alert,
  Container,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material"
import {useState} from "react"
import {useNavigate} from "react-router-dom"
import {BrandLogo} from "src/components/BrandLogos"
import Page from "src/components/Page"
import {useBusinessExternalUserSystemBaseUrlQuery} from "src/types/apollo"

export function ExternalUserLookupPage() {
  const {
    data,
    error: businessLoadError,
    loading,
  } = useBusinessExternalUserSystemBaseUrlQuery()
  const navigate = useNavigate()
  const [customerId, setCustomerId] = useState("")
  const [error, setError] = useState<string | undefined>()

  const onCustomerIdSubmit = () => {
    if (customerId !== "") {
      navigate(`/customers/${customerId}`)
      setError(undefined)
    } else {
      setError("Enter an identifier")
    }
  }

  if (loading) {
    return <Page loading />
  }

  if (businessLoadError || !data || data.me == null) {
    return <Page error={error || "No data"} />
  }

  const externalLookupEnabled =
    data.me.associatedBusiness?.externalUserSystemBaseUrl != null

  return (
    <Page title="Customer Lookup by ID">
      <Container maxWidth="sm">
        <Alert severity={externalLookupEnabled ? "info" : "warning"}>
          {externalLookupEnabled
            ? "Lookup admissions data using your own company's customer identifier"
            : `'${data.me.associatedBusiness?.companyName}' does not have this integration enabled`}
        </Alert>
        <form
          style={{marginTop: 24}}
          onSubmit={(e) => {
            e.preventDefault()
            onCustomerIdSubmit()
          }}
        >
          <TextField
            fullWidth
            label="Customer ID"
            value={customerId}
            disabled={!externalLookupEnabled}
            placeholder={"e.g. 2202"}
            error={!!error}
            helperText={error}
            onChange={(e) => setCustomerId(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BrandLogo />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={!externalLookupEnabled}
                    onClick={onCustomerIdSubmit}
                    size="large"
                  >
                    <Icon>search</Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
      </Container>
    </Page>
  )
}
