import {useEffect} from "react"

export function useHideFreshchat() {
  useEffect(() => {
    const sheet = new CSSStyleSheet()
    sheet.replaceSync("#fc_frame { display: none !important; }")
    document.adoptedStyleSheets = [sheet]

    return () => {
      document.adoptedStyleSheets = []
    }
  }, [])
  return []
}
