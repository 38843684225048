import * as R from "ramda"
import React from "react"
import {useSelector} from "react-redux"
import Page from "../../components/Page"
import T from "../../textResources"
import {AuthState} from "../auth"
import {Permission} from "src/types/apollo"

export const authedOrFallback = (
  requiredPermissions: string[],
  FallbackComponent: JSX.Element | null = null,
) => {
  return function <T extends object>(Component: React.ComponentType<T>) {
    return (props: T) => {
      const currentUserPermissions = useSelector(
        (state: {auth: AuthState}) => state.auth.permissions,
      )

      if (
        !hasAnyRequiredPermission(currentUserPermissions, requiredPermissions)
      ) {
        return FallbackComponent
      }

      return <Component {...(props as T)} />
    }
  }
}

export default function withAuth(requiredPermissions: Permission[]) {
  return authedOrFallback(
    requiredPermissions,
    <Page error={T.errors.accessDenied} />,
  )
}

function hasAnyRequiredPermission(held: string[], required: string[]): boolean {
  return R.intersection(held, required).length > 0
}
